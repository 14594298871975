import { Commission } from './Commission';
import { CommissionMetadata } from './CommissionMetadata';

export interface CommissionWritable {
  clientUuid: string;
  label: string;
  commissionMetadata: CommissionMetadata;
}

export function commissionDiffersFrom(
  a: CommissionWritable,
  b: CommissionWritable | Commission | undefined,
): boolean {
  if (b === undefined) {
    return true;
  }

  if (a.label !== b.label) {
    return true;
  }

  return a.clientUuid !== b.clientUuid;
}
