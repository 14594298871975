import React from 'react';
import {
  Box, Fade, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  alt: string
  src: string
  imagePrimaryColor?: string
}

export default function DeferredImage({
  alt,
  src,
  imagePrimaryColor,
}:Props) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const theme = useTheme();

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
    }}
    >
      <Fade in={!isLoaded}>
        <Box sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          backgroundColor: imagePrimaryColor || theme.palette.grey[300],
        }}
        />
      </Fade>
      {isError ? (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: imagePrimaryColor || theme.palette.grey[300],
        }}
        >
          <Typography color="error">
            {`Error loading ${alt}`}
          </Typography>
        </Box>
      )
        : (
          <img
            src={`${src}`}
            alt={alt}
            onLoad={() => { setIsLoaded(true); }}
            onError={() => { setIsError(true); setIsLoaded(true); }}
            loading="lazy"
            height="100%"
            width="100%"
            style={{ objectFit: 'cover' }}
          />
        )}
    </Box>
  );
}
