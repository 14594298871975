import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import AddCommissionComponent from '../../components/myoc-components/AddCommissionComponent';
import Page from '../../components/Page';
import StyledContainer from '../../components/myoc-components/styled-containers/StyledContainer';

function AddCommission() {
  const navigate = useNavigate();

  const onCompletedAddCommission = () => {
    navigate('/app/commissions');
  };

  return (
    <Page title="New Commission">
      <Typography variant="h3">Add Commission</Typography>
      <StyledContainer>
        <AddCommissionComponent onComplete={onCompletedAddCommission} />
      </StyledContainer>
    </Page>
  );
}

export default AddCommission;
