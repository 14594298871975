import { styled } from '@mui/material/styles';
import {
  Backdrop, Box, CircularProgress, Typography,
} from '@mui/material';
import React from 'react';
import BoxFlexColumn from './styled-containers/BoxFlexColumn';

const ParentBox = styled(Box)`
  position: relative;
`;

const LocalisedBackdrop = styled(Backdrop)(({ theme }) => ({
  '& .MuiBase-root': {
    backgroundColor: theme.palette.grey[700], gradient: 'none',
  },
  position: 'absolute',
  zIndex: 150,
}));

interface Props {
  active: boolean;
  text: string;
  children: React.ReactNode;
}

// TODO add color from palatte for circular progress

export default function LoadingOverlay({
  active,
  text,
  children,
}:Props) {
  return (
    <ParentBox>
      <LocalisedBackdrop open={active}>
        <BoxFlexColumn sx={{ alignItems: 'center' }}>
          <CircularProgress />
          <Typography color="white">{text}</Typography>
        </BoxFlexColumn>
      </LocalisedBackdrop>
      {children}
    </ParentBox>
  );
}
