import React, { Fragment } from 'react';

import { Box } from '@mui/material';
import { Editor } from '@tiptap/react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import CodeIcon from '@mui/icons-material/Code';
import { styled } from '@mui/material/styles';
import MenuItem, {
  instanceOfMenuItemProps, MenuItemProps,
} from './MenuItem';
import { MuiOutlinedInputRoot } from '../../../theme/overrides/Input';

const EditorHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: MuiOutlinedInputRoot(theme).normal.border,
  display: 'flex',
  flex: '0 0 auto',
  flexWrap: 'wrap',
  padding: '0.25rem',
}));

interface Props {
  editor: Editor
}

export default function MenuBar({ editor }: Props) {
  const items: (MenuItemProps)[] = [
    {
      TheIcon: FormatBoldIcon,
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      TheIcon: FormatItalicIcon,
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      TheIcon: FormatStrikethroughIcon,
      title: 'Strikethrough',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    {
      TheIcon: CodeIcon,
      title: 'Code',
      action: () => editor.chain().focus().toggleCode().run(),
      isActive: () => editor.isActive('code'),
    },
    // {
    //   icon: 'mark-pen-line',
    //   title: 'Highlight',
    //   action: () => editor.chain().focus().toggleHighlight().run(),
    //   isActive: () => editor.isActive('highlight'),
    // },
    // { type: 'divider' },
    // {
    //   TheIcon: 'h-1',
    //   title: 'Heading 1',
    //   action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 1 }),
    // },
    // {
    //   TheIcon: 'h-2',
    //   title: 'Heading 2',
    //   action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 2 }),
    // },
    // {
    //   TheIcon: 'paragraph',
    //   title: 'Paragraph',
    //   action: () => editor.chain().focus().setParagraph().run(),
    //   isActive: () => editor.isActive('paragraph'),
    // },
    // {
    //   TheIcon: 'list-unordered',
    //   title: 'Bullet List',
    //   action: () => editor.chain().focus().toggleBulletList().run(),
    //   isActive: () => editor.isActive('bulletList'),
    // },
    // {
    //   TheIcon: 'list-ordered',
    //   title: 'Ordered List',
    //   action: () => editor.chain().focus().toggleOrderedList().run(),
    //   isActive: () => editor.isActive('orderedList'),
    // },
    // {
    //   icon: 'list-check-2',
    //   title: 'Task List',
    //   action: () => editor.chain().focus().toggleTaskList().run(),
    //   isActive: () => editor.isActive('taskList'),
    // },
    // {
    //   TheIcon: 'code-box-line',
    //   title: 'Code Block',
    //   action: () => editor.chain().focus().toggleCodeBlock().run(),
    //   isActive: () => editor.isActive('codeBlock'),
    // },
    // // { type: 'divider' },
    // {
    //   TheIcon: 'double-quotes-l',
    //   title: 'Blockquote',
    //   action: () => editor.chain().focus().toggleBlockquote().run(),
    //   isActive: () => editor.isActive('blockquote'),
    // },
    // {
    //   TheIcon: 'separator',
    //   title: 'Horizontal Rule',
    //   isActive: () => true,
    //   action: () => editor.chain().focus().setHorizontalRule().run(),
    // },
    // // { type: 'divider' },
    // {
    //   TheIcon: 'text-wrap',
    //   title: 'Hard Break',
    //   isActive: () => true,
    //   action: () => editor.chain().focus().setHardBreak().run(),
    // },
    // {
    //   TheIcon: 'format-clear',
    //   title: 'Clear Format',
    //   isActive: () => true,
    //   action: () => editor.chain().focus().clearNodes().unsetAllMarks()
    //     .run(),
    // },
    // // { type: 'divider' },
    {
      TheIcon: UndoIcon,
      isActive: () => true,
      title: 'Undo',
      action: () => editor.chain().focus().undo().run(),
    },
    {
      TheIcon: RedoIcon,
      isActive: () => true,
      title: 'Redo',
      action: () => editor.chain().focus().redo().run(),
    },
  ];

  return (
    <EditorHeader>
      {items.map((item) => (
        <Box key={item.title}>
          {instanceOfMenuItemProps(item)
            ? (
              <MenuItem
                {...item}
              />
            )
            : <Box className="divider" />}
        </Box>
      ))}
    </EditorHeader>
  );
}
