import * as FileManagementApi from '../api/FileManagementApi';
import { UploadProgress } from './myoc-components/FileUploadComponent';

export const uploadAndGetKeys = async (
  artistUserUuid: string,
  files: File[],
  type: 'request' | 'commission',
  onProgressUpdated: (fileName:string, current:number, total:number) =>void,
): Promise<string[]> => {
  const uploadableFiles = await FileManagementApi.getUploadAuthorizations(artistUserUuid, files, type);
  const uploadedFiles = await FileManagementApi.uploadFiles(uploadableFiles, artistUserUuid, onProgressUpdated);
  return uploadedFiles.map((uf) => uf.objectKey);
};

export function getUploadProgressArray(
  fileName:string,
  current:number,
  total:number,
  previousValues: UploadProgress[],
) {
  const newProgress: UploadProgress = {
    fileName,
    current,
    total,
  };
  const newProgressArray = previousValues.filter((f) => f.fileName !== fileName);
  newProgressArray.push(newProgress);
  return newProgressArray;
}
