import {
  Box, CircularProgress, FormControlLabel, FormGroup, Switch, TextField, Typography,
} from '@mui/material';
import React, {
  useCallback, useEffect,
} from 'react';
import Page from 'src/components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import LoadingButton from '@mui/lab/LoadingButton';
import { useUserContext } from '../components/myoc-components/context/user-context/UserContext';
import BoxFlexRow from '../components/myoc-components/styled-containers/BoxFlexRow';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../components/myoc-components/LeftRightColumnComponent';
import BoxFlexColumn from '../components/myoc-components/styled-containers/BoxFlexColumn';
import * as UserApi from '../api/UserApi';
import { UserSettings } from '../representations/UserSettings';
import TitlePaper from '../components/myoc-components/styled-containers/TitlePaper';

export default function SettingsPage() {
  const userContextObject = useUserContext();

  let initialDisplayName = '';
  let initialAcceptEmail = false;
  let initialDeclineEmail = false;
  let initialSendMeNewRequestsEmail = false;

  if (userContextObject.isFirstLoaded && userContextObject.settings) {
    initialDisplayName = userContextObject.settings.account.displayName;
    initialAcceptEmail = userContextObject.settings.clientEmails.isSendClientAcceptEmail;
    initialDeclineEmail = userContextObject.settings.clientEmails.isSendClientDeclineEmail;
    initialSendMeNewRequestsEmail = userContextObject.settings.emails.isSendMeNewRequestEmail;
  }

  const [displayName, setDisplayName] = React.useState(initialDisplayName);
  const [displayNameErrors, setDisplayNameErrors] = React.useState<string | undefined>(undefined);
  const [isCheckingDisplayName, setIsCheckingDisplayName] = React.useState<boolean>(false);

  const [isSendClientAcceptEmail, setIsSendClientAcceptEmail] = React.useState(initialAcceptEmail);
  const [isSendClientDeclineEmail, setIsSendClientDeclineEmail] = React.useState(initialDeclineEmail);

  const [isSendMeNewRequestEmail, setIsSendMeNewRequestEmail] = React.useState(initialSendMeNewRequestsEmail);

  const [isSavingSettings, setIsSavingSettings] = React.useState(false);
  const [newUserSettings, setNewUserSettings] = React.useState<UserSettings | undefined>(undefined);
  const [isSettingsChanged, setIsSettingsChanged] = React.useState(false);

  useEffect(() => {
    if (!userContextObject.isFirstLoaded || userContextObject.isLoading) {
      return;
    }

    if (!userContextObject.settings) {
      throw new Error('undefined user settings after loading');
    }

    setDisplayName(userContextObject.settings.account.displayName);
    setIsSendClientDeclineEmail(userContextObject.settings.clientEmails.isSendClientDeclineEmail);
    setIsSendClientAcceptEmail(userContextObject.settings.clientEmails.isSendClientAcceptEmail);
    setIsSendMeNewRequestEmail(userContextObject.settings.emails.isSendMeNewRequestEmail);
    setNewUserSettings(JSON.parse(JSON.stringify(userContextObject.settings)));
    setIsSettingsChanged(false);
  }, [userContextObject]);

  const settingsObjects = [
    displayName,
    isSendClientAcceptEmail,
    isSendClientDeclineEmail,
    isSendMeNewRequestEmail,
  ];

  useEffect(() => {
    const s: UserSettings = {
      account: { displayName },
      emails: { isSendMeNewRequestEmail },
      clientEmails: {
        isSendClientAcceptEmail,
        isSendClientDeclineEmail,
      },
    };
    setNewUserSettings(s);
    setIsSettingsChanged(JSON.stringify(userContextObject.settings) !== JSON.stringify(s));
  }, settingsObjects);

  const debouncedDisplayNameCheck = useCallback(
    debounce(async (newDisplayName: string) => {
      try {
        setIsCheckingDisplayName(true);
        const isAvailable = await UserApi.checkDisplayNameIsAvailable(newDisplayName);
        setDisplayNameErrors(
          isAvailable
            ? undefined
            : 'This username is in use',
        );
      } finally {
        setIsCheckingDisplayName(false);
      }
    }, 400),
    [],
  );

  const onSaveClicked = async () => {
    if (newUserSettings === undefined) {
      throw new Error('newUserSettings cannot be undefined here');
    }

    try {
      setIsSavingSettings(true);
      await userContextObject.updateUserSettings(newUserSettings);
      toast.info('Saved settings');
    } catch (e) {
      toast.error('Error saving settings');
    } finally {
      setIsSavingSettings(false);
    }
  };

  const checkDisplayNameForErrors = (newDisplayName: string) => {
    const nameRegex = /^([a-zA-Z\d\-_~ .]{3,})$/;
    const validName = nameRegex.test(newDisplayName);

    if (!validName) {
      setDisplayNameErrors('Name contains invalid characters (Alphanumerical, ~, . , _, space, and - only)');
      setIsCheckingDisplayName(false);
      debouncedDisplayNameCheck.cancel();
      return;
    }

    setDisplayNameErrors(undefined);
    debouncedDisplayNameCheck(newDisplayName);
  };

  const accountSettings: LeftRightColumnRow[] = [
    {
      key: '1',
      left: 'Account Email',
      right: (
        <TextField
          fullWidth
          size="small"
          disabled
          value={!userContextObject ? '' : userContextObject.user?.email}
        />
      ),
    },
    {
      key: '2',
      left: 'Display Name',
      right: (
        <BoxFlexRow>
          {isCheckingDisplayName && (
          <CircularProgress sx={{
            position: 'absolute', // TODO make this show on the right inside the textarea
            right: '0%',
          }}
          />
          )}
          <TextField
            fullWidth
            size="small"
            disabled={isSavingSettings}
            error={displayNameErrors !== undefined}
            helperText={displayNameErrors || ' '}
            value={displayName}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const newDisplayName = e.target.value;
              setDisplayName(newDisplayName);
              checkDisplayNameForErrors(newDisplayName);
            }}
          />
        </BoxFlexRow>
      ),
    },
  ];

  const myocEmails: LeftRightColumnRow[] = [
    {
      key: '3',
      left: 'Send me an email when I receive a new request',
      right: (
        <FormGroup>
          <FormControlLabel
            label=""
            control={(
              <Switch
                disabled={isSavingSettings}
                checked={isSendMeNewRequestEmail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsSendMeNewRequestEmail(event.target.checked);
                }}
              />
                )}
          />
        </FormGroup>
      ),
    },
  ];

  const clientEmailNotifications: LeftRightColumnRow[] = [
    {
      key: '4',
      left: 'Send emails to clients on accepting requests',
      right: (
        <FormGroup>
          <FormControlLabel
            label=""
            control={(
              <Switch
                disabled={isSavingSettings}
                checked={isSendClientAcceptEmail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsSendClientAcceptEmail(event.target.checked);
                }}
              />
                )}
          />
        </FormGroup>
      ),
    },
    {
      key: '5',
      left: 'Send emails to clients on declining requests',
      right: (
        <FormGroup>
          <FormControlLabel
            label=""
            control={(
              <Switch
                disabled={isSavingSettings}
                checked={isSendClientDeclineEmail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsSendClientDeclineEmail(event.target.checked);
                }}
              />
                )}
          />
        </FormGroup>
      ),
    },
  ];

  if (!userContextObject.isFirstLoaded) {
    return (
      <Page title="Settings">
        Loading
      </Page>
    );
  }

  const saveButton = (
    <LoadingButton
      startIcon={<SaveIcon />}
      disabled={!isSettingsChanged || displayNameErrors !== undefined || isCheckingDisplayName}
      loading={isSavingSettings}
      variant="contained"
      onClick={onSaveClicked}
    >
      Save
    </LoadingButton>
  );

  return (
    <Page title="Settings">
      <BoxFlexColumn>
        <BoxFlexRow>
          <Typography variant="h1">User Settings</Typography>
          <Box sx={{ marginLeft: 'auto' }}>
            {saveButton}
          </Box>
        </BoxFlexRow>

        <TitlePaper title="Account Settings">
          <LeftRightColumnComponent rows={accountSettings} />
        </TitlePaper>

        <TitlePaper title="myoc Emails">
          <LeftRightColumnComponent rows={myocEmails} />
        </TitlePaper>

        <TitlePaper title="Emails to Clients">
          <LeftRightColumnComponent rows={clientEmailNotifications} />
        </TitlePaper>

        <Box sx={{ marginLeft: 'auto' }}>
          {saveButton}
        </Box>
      </BoxFlexColumn>
    </Page>
  );
}
