import {
  Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography,
} from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getContentFromAnswerValue, RequestFormAnswerWithFiles,
} from '../../../representations/BaseRequestFormAnswer';
import ImageListAndViewerComponent from '../imageviewer/ImageListAndViewerComponent';
import FormTextField from '../formbuilder/FormTextField';
import RichTextEditor from '../rich-text-editor/RichTextEditor';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

type Props = {
  requestFormAnswers : RequestFormAnswerWithFiles[];
};

export default function ViewRequestSubmissionComponent({ requestFormAnswers }:Props) {
  const getFormQuestion = (answer: RequestFormAnswerWithFiles) => {
    switch (answer.type) {
      case 'text':
        return (
          <FormTextField
            disabled
            type="short"
            otherProps={{ defaultValue: answer.value }}
          />
        );
      case 'description':
        return (
          <RichTextEditor
            readOnly
            content={getContentFromAnswerValue(answer.value)}
          />
        );
      case 'paragraph':
        return (
          <RichTextEditor readOnly content={answer.paragraph} />
        );
      case 'checkbox':
        // eslint-disable-next-line
        const answerUuids: string[] = answer.value as string[];

        return (
          <FormGroup>
            {answer.options !== undefined && answer.options.map((option) => {
              const currentOptionUuid = option.uuid;
              const doAnswersContainCurrentOption = answerUuids.find((answerUuid) => answerUuid === currentOptionUuid) !== undefined;
              return (
                <FormControlLabel
                  disabled
                  key={option.uuid}
                  label={option.title}
                  control={<Checkbox checked={doAnswersContainCurrentOption} />}
                />
              );
            })}
          </FormGroup>
        );
      case 'option':
        return (
          <FormGroup>
            <RadioGroup
              defaultValue={answer.value}
            >
              {answer.options !== undefined && answer.options.map((option) => (
                <FormControlLabel
                  disabled
                  key={option.uuid}
                  value={option.uuid}
                  control={<Radio />}
                  label={option.title}
                />
              ))}
            </RadioGroup>
          </FormGroup>
        );
      case 'reference-images':
        return <ImageListAndViewerComponent files={answer.uploadedFiles} />;
      default:
        return null;
    }
  };

  return (
    <BoxFlexColumn>
      {requestFormAnswers.map((a) => (
        <BoxFlexColumn key={uuidv4()}>
          <Typography variant="h3">{a.title}</Typography>
          {getFormQuestion(a)}
        </BoxFlexColumn>
      ))}
    </BoxFlexColumn>
  );
}
