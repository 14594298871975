import axios from 'axios';
import ApiError, { ERROR_GET_STATUSES } from './ApiError';
import { CommissionStatus } from '../representations/CommissionStatus';
import { SERVER_URL } from './SharedApiStuff';

// eslint-disable-next-line import/prefer-default-export
export async function getUserDefinedStatuses() {
  try {
    const url = `${SERVER_URL}/api/v1/commission-status`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data as CommissionStatus[];
  } catch (e) {
    throw new ApiError('Error getting statuses', ERROR_GET_STATUSES, e);
  }
}
