import React from 'react';
import {
  Box, Drawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSObject } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import NavSection from '../../components/NavSection';
import getSidebarConfig from './SidebarConfig';
import { useUserContext } from '../../components/myoc-components/context/user-context/UserContext';
import SidebarUser from './SidebarUser';
import {
  DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN,
} from './SidebarIconSizes';
import { usePollingDataContext } from '../../components/myoc-components/context/polling-data-context/PollingDataContext';

type Props = {
  isOpenSidebar: boolean,
  onSidebarOpened: () => void,
  onSidebarClosed: () => void,
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_CLOSED,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(8)} + 1px)` },
});

const StyleDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({
    theme, open,
  }) => ({
    width: DRAWER_WIDTH_CLOSED,
    whiteSpace: 'nowrap',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        paddingLeft: 0,
        paddingRight: 0,
        overflowX: 'hidden',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        paddingLeft: 0,
        paddingRight: 0,
        overflowX: 'hidden',
      },
    }),
  }),
);

function DashboardSidebar({
  isOpenSidebar = false,
  onSidebarOpened,
  onSidebarClosed,
}:Props) {
  const userContext = useUserContext();
  const pollingDataContext = usePollingDataContext();

  if (!userContext.isFirstLoaded || !userContext.user) {
    return null;
  }

  return (
    <Box
      id="drawer-box"
      sx={{ width: DRAWER_WIDTH_CLOSED }}
    >
      <StyleDrawer
        id="drawer"
        open={isOpenSidebar}
        variant="permanent"
      >
        <Box sx={((theme) => theme.mixins.toolbar)} />
        <SidebarUser
          isSidebarOpen={isOpenSidebar}
          name={userContext.user.displayName}
          requestsOpen={userContext.user.isRequestsOpen}
        />
        <NavSection
          isSidebarOpen={isOpenSidebar}
          navConfig={getSidebarConfig({
            unreadRequestsNumber: (
              pollingDataContext.undecidedRequestCount
                ? pollingDataContext.undecidedRequestCount
                : 0
            ),
          })}
        />
      </StyleDrawer>
    </Box>
  );
}

export default DashboardSidebar;

// {/* Below is the commented out "upgrade to pro" icon -
//       might be useful for us in the future? */}
// {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}> */}
// {/*  <Stack */}
// {/*    alignItems="center" */}
// {/*    spacing={3} */}
// {/*    sx={{ */}
// {/*      p: 2.5, */}
// {/*      pt: 5, */}
// {/*      borderRadius: 2, */}
// {/*      position: 'relative', */}
// {/*      bgcolor: 'grey.200', */}
// {/*    }} */}
// {/*  > */}
// {/*    <Box */}
// {/*      component="img" */}
// {/*      src="/static/illustrations/illustration_avatar.png" */}
// {/*      sx={{ width: 100, position: 'absolute', top: -50 }} */}
// {/*    /> */}
//
// {/*    <Box sx={{ textAlign: 'center' }}> */}
// {/*      <Typography gutterBottom variant="h6"> */}
// {/*        Get more? */}
// {/*      </Typography> */}
// {/*      <Typography variant="body2" sx={{ color: 'text.secondary' }}> */}
// {/*        From only $69 */}
// {/*      </Typography> */}
// {/*    </Box> */}
//
// {/*    <Button */}
// {/*      fullWidth */}
// {/*      href="https://material-ui.com/store/items/minimal-dashboard/" */}
// {/*      target="_blank" */}
// {/*      variant="contained" */}
// {/*    > */}
// {/*      Upgrade to Pro */}
// {/*    </Button> */}
// {/*  </Stack> */}
// {/* </Box> */}
