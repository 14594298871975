import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Divider, Typography, useTheme,
} from '@mui/material';
import MyocLogo from './MyocLogo';
import StyledPaper from '../components/myoc-components/StyledPaper';
import Page from '../components/Page';

export default function WhitelistPage() {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const isNotWhitelisted = error === 'not-whitelisted';

  return (
    <Page
      title="Whitelist"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledPaper>
        <MyocLogo />
        <Typography variant="body1" fontSize="25px" sx={{ color: 'text.secondary' }}>
          Manage Your Own Commissions
        </Typography>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: theme.palette.grey[600],
          }}
          variant="middle"
        />
        {isNotWhitelisted ? (
          <Typography variant="body1" fontSize="19px" fontWeight="400" sx={{ color: 'text.secondary' }}>
            Public access coming soon! Please ask feng for beta access
          </Typography>
        ) : (
          <Typography variant="body1" fontSize="19px" fontWeight="400" sx={{ color: 'text.secondary' }}>
            Coming soon!
          </Typography>
        )}
      </StyledPaper>
    </Page>
  );
}
