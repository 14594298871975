import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  //  transform: 'scale(50%)',
}));

export default function MyocLogoHorizontal() {
  return (
    <StyledBox>
      <img
        src="/static/donut.svg"
        alt="Donut mascot logo"
        height="45px"
        style={{ transform: 'translate(0, 0)' }}
      />
      <img
        src="/static/myoc.svg"
        alt="myoc"
        height="30px"
        style={{ transform: 'translate(0, 10%)' }}
      />
    </StyledBox>
  );
}
