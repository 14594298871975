import React from 'react';
import {
  Button, Paper, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Client } from '../../../representations/Client';
import ClientBox from '../ClientBox';
import TitlePaper from '../styled-containers/TitlePaper';

interface Props {
  client: Client;
  originatingRequestUuid?: string;
}

function ClientDetails({
  client,
  originatingRequestUuid,
}:Props) {
  return (
    <TitlePaper title="Client Details" color="grey">
      <ClientBox client={client} />
      {originatingRequestUuid ? (
        <Link to={`/app/requests/inbox?submissionUuid=${originatingRequestUuid}`} style={{ textDecoration: 'none' }}>
          <Button variant="contained">
            Go to request
          </Button>
        </Link>
      ) : (
        <Typography><i>This commission was manually added</i></Typography>
      )}
    </TitlePaper>
  );
}

export default ClientDetails;
