import React, { KeyboardEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton, Paper,
  Radio,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-outline';
import { JSONContent } from '@tiptap/react';
import { RequestFormQuestion } from '../../../representations/RequestFormQuestion';
import ClickInput from '../ClickInput';
import { QuestionOption } from '../../../representations/QuestionOption';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import FormTextField from './FormTextField';
import RichTextEditor from '../rich-text-editor/RichTextEditor';

type Props = {
  question: RequestFormQuestion;
  onTitleChanged : (newTitle: string) => void;
  onParagraphChanged : (newParagraph: JSONContent) => void;
  onIsOptionalChanged: (newValue: boolean) => void;
  onOptionsChanged : (newOptionList: QuestionOption[]) => void;
  onDeleteQuestionClicked: ()=>void;
};

export default function EditableFormItem({
  question,
  onTitleChanged,
  onParagraphChanged,
  onIsOptionalChanged,
  onOptionsChanged,
  onDeleteQuestionClicked,
}: Props) {
  const [newOptionValue, setNewOptionValue] = React.useState<string>('');
  const [isOptional, setIsOptional] = React.useState<boolean>(question.isOptional);

  const addOption = (newOption: string) => {
    // Do this because directly pushing to the object works, but
    // we want to ensure we only mutate that via proper 1-way binding
    const optionsCopy: QuestionOption[] = question.options === undefined
      ? []
      : JSON.parse(JSON.stringify(question.options));
    optionsCopy.push({
      uuid: uuidv4(),
      title: newOption,
    });
    onOptionsChanged(optionsCopy);
  };

  const deleteOption = (uuid: string) => {
    const optionsCopy: QuestionOption[] = JSON.parse(JSON.stringify(question.options));
    onOptionsChanged(optionsCopy.filter((o) => o.uuid !== uuid));
  };

  const editOption = (uuid: string, newValue: string) => {
    if (question.options === undefined) {
      return;
    }

    const optionsCopy: QuestionOption[] = question.options.map((option) => {
      const optionCopy = option;
      if (optionCopy.uuid === uuid) {
        optionCopy.title = newValue;
      }
      return option;
    });

    onOptionsChanged(optionsCopy);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (newOptionValue !== '') {
        addOption(newOptionValue);
        setNewOptionValue('');
      }
    }
  };

  const getOptionComponent = (type:'option' | 'checkbox', options: QuestionOption[]) => {
    let inputIcon : React.ReactNode;
    let lessVisibleInputIcon : React.ReactNode;
    const transparent = { opacity: 0.4 };
    switch (type) {
      case 'option':
        inputIcon = (<Radio disabled />);
        lessVisibleInputIcon = (<Radio disabled sx={transparent} />);
        break;
      case 'checkbox':
        inputIcon = (<Checkbox disabled />);
        lessVisibleInputIcon = (<Checkbox disabled sx={transparent} />);
        break;
      default:
        break;
    }

    return (
      <BoxFlexColumn sx={{ gap: '10px' }}>
        {options.map((option: QuestionOption) => (
          <BoxFlexRow key={option.uuid}>
            {inputIcon}
            <TextField
              size="small"
              variant="outlined"
              id={option.uuid}
              value={option.title}
              onChange={(event) => {
                editOption(option.uuid, event.target.value);
              }}
            />
            <IconButton
              onClick={(() => {
                deleteOption(option.uuid);
              })}
            >
              <Icon
                icon={trash2Outline}
                width={22}
                height={22}
              />
            </IconButton>
          </BoxFlexRow>
        ))}
        <BoxFlexRow>
          {lessVisibleInputIcon}
          <TextField
            size="small"
            variant="outlined"
            id={`${question.uuid}`}
            placeholder="Add new"
            onKeyDown={(e) => { onKeyDown(e); }}
            value={newOptionValue}
            onChange={(event) => {
              setNewOptionValue(event.target.value);
            }}
          />
        </BoxFlexRow>
      </BoxFlexColumn>
    );
  };

  let editingComponent;
  switch (question.type) {
    case 'checkbox':
    case 'option':
      editingComponent = getOptionComponent(question.type, question.options !== undefined ? question.options : []);
      break;
    case 'text':
      editingComponent = (
        <FormTextField
          disabled
          type="short"
          otherProps={{
            value: question.paragraph,
            placeholder: 'Requester will write short answer here',
          }}
        />
      );
      break;
    case 'description':
      editingComponent = (
        <FormTextField
          disabled
          type="long"
          otherProps={{
            value: question.paragraph,
            placeholder: 'Requester will write request description here',
          }}
        />
      );
      break;
    case 'paragraph':
      editingComponent = (
        <RichTextEditor
          readOnly={false}
          onSave={(content: JSONContent) => {
            onParagraphChanged(content);
          }}
          alwaysShowControls
          controlledMode
          content={question.paragraph}
        />
      );
      break;
    case 'reference-images':
      editingComponent = (
        <Paper sx={{
          borderStyle: 'dashed',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <Typography variant="subtitle1">Clients will upload files here</Typography>
        </Paper>
      );
      break;
    default:
      return (<Box>Need to implement</Box>);
  }
  return (
    <BoxFlexColumn>
      <BoxFlexRow>
        <Box sx={{ width: '100%' }}>
          <ClickInput
            initialValue={question.title}
            variant="h3"
            onChange={(newTitle) => {
              onTitleChanged(newTitle);
            }}
          />
        </Box>
        <IconButton
          onClick={onDeleteQuestionClicked}
        >
          <Icon
            icon={trash2Outline}
            width={22}
            height={22}
          />
        </IconButton>
      </BoxFlexRow>
      {editingComponent}
      {question.type !== 'paragraph' && (
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                checked={isOptional}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onIsOptionalChanged(event.target.checked);
                  setIsOptional(event.target.checked);
                }}
              />
                    )}
            label="Optional"
          />
        </FormGroup>
      )}
      <Typography variant="body2">
        <i>
          {`${question.type} type`}
        </i>
      </Typography>
    </BoxFlexColumn>
  );
}
