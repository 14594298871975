import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableOptions,
} from 'mui-datatables';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { RequestFormClientSubmissionNoAnswers } from '../../../representations/RequestFormClientSubmission';

interface TableData {
  clientDetails: string;
  dateCreated: Date;
  dateDecided: Date;
  submissionUuid: string;
  decision: 'accepted' | 'rejected'; // This is like that so it is searchable
}

const getIconForDecision = (tableData: TableData[], d:number) => {
  const requestTableData = tableData[d];
  if (requestTableData.decision === 'accepted') {
    return (<CheckIcon color="primary" />);
  }
  return (<CloseIcon color="disabled" />);
};

interface Props {
  requestSubmissions: RequestFormClientSubmissionNoAnswers[];
  onSubmissionSelected: (submissionUuid: string) => void;
  dateDisplayFormatter: (date: Date) => string;
}

export default function DecidedRequestsTableComponent({
  requestSubmissions,
  onSubmissionSelected,
  dateDisplayFormatter,
} : Props) {
  const [tableData, setTableData] = React.useState<TableData[]>([]);

  useEffect(() => {
    const loadRequests = async () => {
      const td = requestSubmissions.map((d):TableData => {
        if (d.decisionDate === null || d.decision === null) {
          throw new Error('Data became invalid somehow');
        }
        return {
          clientDetails: `${d.clientName} <${d.clientEmail}>`,
          dateCreated: d.dateCreated,
          dateDecided: d.decisionDate,
          submissionUuid: d.submissionUuid,
          decision: d.decision ? 'accepted' : 'rejected',
        };
      });
      setTableData(td);
    };
    loadRequests().then(() => {});
  }, [requestSubmissions]);

  const onRowClick = (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
    if (tableData) {
      onSubmissionSelected(tableData[rowMeta.dataIndex].submissionUuid);
    } else {
      toast.error('Error on getting submission from list');
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'clientDetails',
      label: 'Requester',
    },
    {
      name: 'dateCreated',
      label: 'Date Received',
      options: {
        customBodyRenderLite: (d) => {
          const requestTableData = tableData[d];
          return dateDisplayFormatter(requestTableData.dateCreated);
        },
      },
    },
    {
      name: 'dateDecided',
      label: 'Date Approved/Rejected',
      options: {
        customBodyRenderLite: (d) => {
          const requestTableData = tableData[d];
          return dateDisplayFormatter(requestTableData.dateDecided);
        },
      },
    },
    {
      name: 'decision',
      label: ' ',
      options: { customBodyRenderLite: (d) => getIconForDecision(tableData, d) },
    },

    // {
    //   name: 'batchId',
    //   label: 'Batch',
    // },
  ];

  const options: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRows: 'none',
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    onRowClick,
    textLabels: { body: { noMatch: requestSubmissions.length === 0 ? 'No requests accepted or rejected yet' : 'No search results' } },
  };

  return (
    <MUIDataTable
      title="Approved / Rejected"
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
