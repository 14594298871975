import React from 'react';
import {
  Paper, Typography,
} from '@mui/material';
import TitlePaper from '../styled-containers/TitlePaper';

function DashboardCommissionRequestsComponent() {
  return (
    <TitlePaper title="New Requests">
      Accept or Decline Box
    </TitlePaper>
  );
}

export default DashboardCommissionRequestsComponent;
