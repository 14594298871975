export enum TaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum TimeUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export function getDaysFromTimeUnit(estimatedNumber: number, timeUnit: TimeUnit) {
  switch (timeUnit) {
    case TimeUnit.DAY:
      return estimatedNumber;
    case TimeUnit.HOUR:
      return Math.floor(estimatedNumber / 24);
    case TimeUnit.WEEK:
      return estimatedNumber * 7;
    default:
      throw new Error(`Unimplemented type ${timeUnit}`);
  }
}

/**
 * A defined task that contains a value of timeUnits before the date
 */
export interface Task {
  uuid: string
  label: string
  commissionUuid: string | null
  userUuid: string
  status: TaskStatus
  date: Date | null
  unit: TimeUnit | null
  requiredTimeUnits: number | null
}

export type NewTask = Omit<Task, 'uuid' | 'status' | 'userUuid'>;
export type ModifiedTask = Omit<Task, 'uuid' | 'status' | 'userUuid' | 'commissionUuid'>;

/**
 * Represents custom events where work is to be avoided (to schedule around)
 */
export interface ExcludedDateBlock {
  uuid: string
  label: string
  startDate: Date
  endDate: Date
}
