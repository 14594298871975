import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const InputItem = styled(Box)(({ theme }) => ({
  display: 'block',
  alignItems: 'left',
  padding: theme.spacing(1, 0),
}));

export default InputItem;
