function getDifferenceInDays(startDate: Date, endDate: Date) {
  const diffInMs = endDate.getTime() - startDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays;
}

export default function DateDifferenceHumanLanguage(startDate: Date, endDate: Date): string {
  const diffInDays = getDifferenceInDays(startDate, endDate);

  if (diffInDays < 0) {
    return 'overdue';
  }
  if (diffInDays < 1) {
    return 'less than a day';
  }
  if (diffInDays === 1) {
    return '1 day';
  } if (diffInDays < 7) {
    return `${diffInDays} days`;
  }
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInDaysRemainder = diffInDays % 7;
  if (diffInDaysRemainder === 0) {
    return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}`;
  }
  return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}, ${diffInDaysRemainder} day${diffInDaysRemainder > 1 ? 's' : ''}`;
}
