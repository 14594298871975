/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
// material
import {
  alpha, styled,
} from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('span')`
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(0, 1)};
    color: ${({ theme }) => theme.palette.grey[800]};
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    background-color: ${({ theme }) => theme.palette.grey[300]};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};

    ${({
    theme, styleprops,
  }) => {
    const {
      color, variant,
    } = styleprops;

    const styleFilled = (theColor) => ({
      color: theme.palette[theColor].contrastText,
      backgroundColor: theme.palette[theColor].main,
    });

    const styleOutlined = (theColor) => ({
      color: theme.palette[theColor].main,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette[theColor].main}`,
    });

    const styleGhost = (theColor) => ({
      color: theme.palette[theColor].dark,
      backgroundColor: alpha(theme.palette[theColor].main, 0.16),
    });

    if (color !== 'default') {
      return (
        {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      );
    }

    return (
      {
        ...(variant === 'outlined' && {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.grey[500_32]}`,
        }),
        ...(variant === 'ghost' && {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey[500_16],
        }),
      }
    );
  }};
`;

// ----------------------------------------------------------------------

function Label({
  color = 'default',
  variant = 'ghost',
  children,
  ...other
}) {
  return (
    <RootStyle
      styleprops={{
        color, variant,
      }}
      {...other}
    >
      {children}
    </RootStyle>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost']),
};

export default Label;
