import React from 'react';
import {
  Box, Button, IconButton, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions,
} from 'mui-datatables';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../../components/Page';
import AddClientDialogComponent from '../../components/myoc-components/AddClientDialogComponent';
import { useClientContext } from '../../components/myoc-components/context/client-context/ClientContext';
import { Client } from '../../representations/Client';
import BoxFlexColumn from '../../components/myoc-components/styled-containers/BoxFlexColumn';
import LoadingOverlay from '../../components/myoc-components/LoadingOverlay';

export default function ClientList() {
  const clientContext = useClientContext();
  const { isLoading } = clientContext;

  const [selectedClient, setSelectedClient] = React.useState<Client | undefined>(undefined);
  const [selectedMode, setSelectedMode] = React.useState<'active' | 'archived'>('active');
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

  const currentListClients = selectedMode === 'active'
    ? clientContext.getActiveClients()
    : clientContext.getArchivedClients();

  // Add Client Modal State
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const onAddClientComponentCompleted = () => {
    handleModalClose();
    clientContext.reloadClients();
  };

  const onAddClientClicked = () => {
    setSelectedClient(undefined);
    handleModalOpen();
  };

  const onEditClientClicked = (client: Client) => {
    setSelectedClient(client);
    handleModalOpen();
  };

  const getCustomToolbar = (
    selectedRowsz: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } },
  ) => {
    const dataIndexesSelected = selectedRowsz.data.map((r) => r.dataIndex);
    const clientUuidsSelected = dataIndexesSelected.map((r) => currentListClients[r].clientUuid);

    return (
      <IconButton
        onClick={() => {
          // Archive all currently selected commissions and reload
          if (selectedMode === 'active') {
            clientContext.archiveClients(clientUuidsSelected);
          } else {
            clientContext.unarchiveClients(clientUuidsSelected);
          }
        }}
      >
        {selectedMode === 'active'
          ? <DeleteIcon />
          : <RestoreFromTrashIcon />}
      </IconButton>
    );
  };

  const options: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRows: 'multiple',
    selectableRowsOnClick: false, // For checkbox selectable only
    selectToolbarPlacement: 'above', // This can be changed to never
    customToolbarSelect: getCustomToolbar,
    // customToolbar: () => 'lmao',
    rowsSelected: selectedRows,
    download: false,
    print: false,
    filter: false,
    search: true,
    searchPlaceholder: 'Search...',
    searchOpen: true,
    searchAlwaysOpen: true,
    viewColumns: false,
    customFooter: () => null,
    elevation: 0,
    // onRowClick,
    textLabels: { body: { noMatch: <Typography>No clients</Typography> } },
  };

  const getRenderedLabel = (value:string, tableMeta: MUIDataTableMeta) => {
    const uuid = tableMeta.rowData[0];
    // const linkToDetails = `/app/clients/details/${uuid}`;
    const client = currentListClients.find((c) => c.clientUuid === uuid);

    if (client) {
      return (
        <RouterLink to="#" onClick={() => { onEditClientClicked(client); }}>{value}</RouterLink>
      );
    }
    throw new Error('Client not found');
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'clientUuid',
      label: 'clientUuid',
      options: { display: 'excluded' },
    },
    {
      name: 'clientName',
      label: 'Client Name',
      options: {
        customBodyRender: (
          value: string,
          tableMeta: MUIDataTableMeta,
        ) => getRenderedLabel(value, tableMeta),
      },
    },
    {
      name: 'clientEmail',
      label: 'Client Email',
    },
  ];

  interface MappedListClient {
    clientUuid: string
    clientName: string
    clientEmail: string
  }

  const mappedClients: MappedListClient[] = currentListClients.map((client) => {
    const m: MappedListClient = {
      clientUuid: client.clientUuid,
      clientName: client.name,
      clientEmail: client.email || '(no email)',
    };
    return m;
  });

  return (
    <Page title="Client Management">
      <AddClientDialogComponent
        isOpen={modalOpen}
        existingClient={selectedClient}
        onComplete={onAddClientComponentCompleted}
        onCancel={handleModalClose}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h1">
          Clients
        </Typography>
        <Box>
          {selectedMode === 'active'
              && (
              <Button
                variant="contained"
                onClick={onAddClientClicked}
                startIcon={<Icon icon={plusFill} />}
              >
                Track New Client
              </Button>
              )}
        </Box>
      </Stack>

      <Paper>
        <BoxFlexColumn>
          <ToggleButtonGroup
            color="primary"
            value={selectedMode}
            exclusive
            onChange={(event, value) => {
              setSelectedMode(value);
              setSelectedRows([]); // Maybe not necessary
            }}
          >
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="archived">Archived</ToggleButton>
          </ToggleButtonGroup>
          <LoadingOverlay active={isLoading} text="loading clients...">
            <MUIDataTable
              title=""
              columns={columns}
              data={mappedClients}
              options={options}
            />
          </LoadingOverlay>
        </BoxFlexColumn>
      </Paper>

    </Page>
  );
}
