import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import {
  styled, useTheme,
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import BoxFlexColumn from './styled-containers/BoxFlexColumn';
import BoxFlexRow from './styled-containers/BoxFlexRow';

const LeftRightBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'breakpoint' })(({
  theme, breakpoint,
}:{
  theme: Theme,
  breakpoint: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexDirection: 'column',
  [theme.breakpoints.up(breakpoint)]: { flexDirection: 'row' },
}));

const LeftBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'leftWidth'
    && prop !== 'breakpoint'
    && prop !== 'justifyContent'
    && prop !== 'alignItems',
})(({
  theme,
  leftWidth,
  breakpoint,
  alignItems,
  justifyContent,
}:{
  theme: Theme,
  leftWidth: string,
  breakpoint: 'xl' | 'lg' | 'md' | 'sm' | 'xs',
  alignItems: 'center' | 'flex-start' | 'flex-end' | 'initial'
  justifyContent: 'center' | 'flex-start' | 'flex-end' | 'initial'
}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: justifyContent, // Flip these since we go into a different direction
  justifyContent: alignItems, // Flip these since we go into a different direction
  [theme.breakpoints.up(breakpoint)]: {
    flexDirection: 'row',
    width: leftWidth,
    alignItems,
    justifyContent,
  },
}));

const RightBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'rightWidth' && prop !== 'breakpoint' })(({
  theme,
  rightWidth,
  breakpoint,
}: {
  theme: Theme,
  rightWidth: string,
  breakpoint: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.up(breakpoint)]: {
    flexDirection: 'row',
    width: rightWidth,
  },
}));

export interface LeftRightColumnRow {
  key: string
  left: string | React.ReactNode
  right: React.ReactNode
}

interface Props {
  rows: LeftRightColumnRow[]
  gap?: number
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'initial'
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'initial'
  leftWidthPercentage?: number
  breakpoint?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

export function LeftRightColumnComponent({
  rows,
  alignItems = 'initial',
  justifyContent = 'initial',
  gap = 2,
  leftWidthPercentage = 40,
  breakpoint = 'sm',
}:Props) {
  const theme = useTheme();

  const leftWidth = `${leftWidthPercentage}%`;
  const rightWidth = `${100 - leftWidthPercentage}%`;

  return (
    <BoxFlexColumn
      sx={(themeL) => ({ gap: themeL.spacing(gap) })}
    >
      {rows.map((row) => {
        const leftSide = typeof row.left === 'string'
          ? (<Typography variant="boldBody1">{row.left}</Typography>)
          : row.left;

        return (
          <LeftRightBox
            key={row.key}
            theme={theme}
            breakpoint={breakpoint}
          >
            <LeftBox
              theme={theme}
              leftWidth={leftWidth}
              alignItems={alignItems}
              justifyContent={justifyContent}
              breakpoint={breakpoint}
            >
              {leftSide}
            </LeftBox>
            <RightBox
              theme={theme}
              rightWidth={rightWidth}
              breakpoint={breakpoint}
            >
              {row.right}
            </RightBox>
          </LeftRightBox>
        );
      })}
    </BoxFlexColumn>
  );
}
