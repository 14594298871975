import { Avatar } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(' ')[0][0]}`,
  };
}

type Props = {
  clientName: string,
  sx?: SxProps<Theme>,
};

export default function ClientAvatar({
  clientName,
  sx = {},
}:Props) {
  return (
    <Avatar {...stringAvatar(clientName)} sx={{ ...sx }} />
  );
}
