export enum IssueType {
  Cosmetic = 'Cosmetic',
  Bug = 'Bug',
  Suggestion = 'Suggestion',
  Unsure = 'Unsure',
}

export enum IssueImportance {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Unsure = 'Unsure',
}

export interface FeedbackReport {
  page: string
  type: IssueType
  description: string
  importance: IssueImportance
}
