import { Theme } from '@mui/material/styles/createTheme';
import { FONT_HEADERS } from '../typography';

export default function Select(theme:Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: FONT_HEADERS,
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(16),
          padding: theme.spacing(0, 1.5),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: FONT_HEADERS,
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
    MuiMenu: { styleOverrides: { root: { '& .MuiPaper-root': { padding: theme.spacing(0.5, 0.5) } } } },
  };
}
