import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  SvgIconTypeMap,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import StarIcon from '@mui/icons-material/Star';
import ShortTextIcon from '@mui/icons-material/ShortText';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import NotesIcon from '@mui/icons-material/Notes';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { JSONContent } from '@tiptap/react';
import { RequestForm } from '../../../representations/RequestForm';
import ClickInput from '../ClickInput';
import ConfirmationDialog from '../ConfirmationDialog';
import * as RequestsApi from '../../../api/RequestsApi';
import EditableFormItem from './EditableFormItem';
import { QuestionOption } from '../../../representations/QuestionOption';
import { RequestFormQuestion } from '../../../representations/RequestFormQuestion';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import { UserContextObject } from '../context/user-context/UserContextObject';
import EditRequestUrlDialog from '../EditRequestUrlDialog';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import TitlePaper from '../styled-containers/TitlePaper';

const getIcon = (
  TheIcon: OverridableComponent<SvgIconTypeMap> & { muiName: string },
): JSX.Element => (<TheIcon color="primary" />);

interface BaseItem {
  name: string
  icon: JSX.Element
  getNewFormItem?: () => RequestFormQuestion
}

const BASE_ITEMS: BaseItem[] = [
  {
    name: 'Text',
    icon: getIcon(ShortTextIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Text Entry Question',
      type: 'text',
      isOptional: false,
    }),
  },
  {
    name: 'Description',
    icon: getIcon(NotesIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Request Description',
      type: 'description',
      isOptional: false,
    }),
  },
  {
    name: 'Checkbox',
    icon: getIcon(CheckBoxIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Checkbox Question',
      type: 'checkbox',
      isOptional: false,
    }),
  },
  {
    name: 'Option',
    icon: getIcon(RadioButtonCheckedIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Option Question',
      type: 'option',
      isOptional: false,
    }),
  },
  {
    name: 'Reference Images Upload',
    icon: getIcon(PermMediaIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Upload Reference Images',
      type: 'reference-images',
      isOptional: false,
    }),

  },
  {
    name: 'Paragraph (Read Only)',
    icon: getIcon(DescriptionIcon),
    getNewFormItem: () => ({
      uuid: uuidv4(),
      title: 'Paragraph',
      type: 'paragraph',
      isOptional: false,
    }),
  },
];

function getTemplateFormQuestions() {
  const templateItems: RequestFormQuestion[] = [];
  templateItems.push({
    uuid: uuidv4(),
    title: 'Crop',
    type: 'option',
    isOptional: false,
    options: [
      {
        uuid: uuidv4(),
        title: 'Full Body',
      },
      {
        uuid: uuidv4(),
        title: 'Half Body',
      },
      {
        uuid: uuidv4(),
        title: 'Bust Up',
      },
    ],
  });
  templateItems.push({
    uuid: uuidv4(),
    title: 'Color',
    type: 'option',
    isOptional: true,
    options: [
      {
        uuid: uuidv4(),
        title: 'Full Color',
      },
      {
        uuid: uuidv4(),
        title: 'Greyscale',
      },
      {
        uuid: uuidv4(),
        title: 'Sketch',
      },
    ],
  });
  templateItems.push({
    uuid: uuidv4(),
    title: 'Terms and Conditions',
    isOptional: true,
    paragraph: 'Fill in your terms and conditions here',
    type: 'paragraph',
  });

  return templateItems;
}

type Props = {
  requestForm : RequestForm | undefined,
  userContext: UserContextObject,
};

function CreateRequestFormComponent({
  requestForm,
  userContext,
}:Props) {
  if (!userContext.user) {
    throw new Error('User cannot be undefined here');
  }

  const initialFormTitle = requestForm ? requestForm.formTitle : `${userContext.user.displayName}'s Commissions`;
  const initialFormQuestions = requestForm ? requestForm.formQuestions : getTemplateFormQuestions();
  const initialFormOpenState = requestForm ? requestForm.isOpen : false;

  // Original Information
  const [lastSavedFormTitle, setLastSavedFormTitle] = React.useState<string>(initialFormTitle);
  const [
    lastSavedFormItems,
    setLastSavedFormItems,
  ] = React.useState<RequestFormQuestion[]>(JSON.parse(JSON.stringify(initialFormQuestions)));
  const [lastSavedFormOpen, setLastSavedFormOpen] = React.useState<boolean>(initialFormOpenState);

  // Edited Information
  const [formTitle, setFormTitle] = React.useState<string>(lastSavedFormTitle);
  const [
    formItems,
    setFormItems,
  ] = React.useState<RequestFormQuestion[]>(JSON.parse(JSON.stringify(lastSavedFormItems)));
  const [isCurrentFormOpen, setIsCurrentFormOpen] = React.useState<boolean>(lastSavedFormOpen);
  const [requestUrl, setRequestUrl] = React.useState(userContext.user.requestUrl);

  // States
  const [isFormChangesUnsaved, setIsFormChangesUnsaved] = React.useState<boolean>(false);
  const [isDiscardConfirmationDialogOpen, setIsDiscardConfirmationDialogOpen] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isEditRequestUrlDialogOpen, setIsEditRequestUrlDialogOpen] = React.useState(false);
  const [isRequestFormWarningsDialogOpen, setIsRequestFormWarningsDialogOpen] = React.useState(false);
  const [formWarningMessage, setFormWarningMessage] = React.useState<JSX.Element | undefined>(undefined);
  const [isResetFormDialogOpen, setIsResetFormDialogOpen] = React.useState(false);

  const reorder = (list : RequestFormQuestion[], startIndex: number, endIndex: number) => {
    const result = JSON.parse(JSON.stringify((list)));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const getArrayWithNewItem = (
    newItem : RequestFormQuestion,
    destination : RequestFormQuestion[],
    droppableDestination : DraggableLocation,
  ) => {
    const destClone = JSON.parse(JSON.stringify((destination)));
    destClone.splice(droppableDestination.index, 0, newItem);
    return destClone;
  };

  const deleteFormItem = (itemUuid: string) => {
    setFormItems((oldFormItems) => {
      const formItemsClone: RequestFormQuestion[] = Array.from(oldFormItems);
      return formItemsClone.filter((fi) => fi.uuid !== itemUuid);
    });
  };

  const validateNewFormItem = (newFormItem: RequestFormQuestion, currentFormItems: RequestFormQuestion[]) => {
    const newFormItemIsDescription = newFormItem.type === 'description';
    const currentFormHasDescriptionAlready = currentFormItems.find((i) => i.type === 'description');
    if (newFormItemIsDescription && currentFormHasDescriptionAlready) {
      throw new Error('You can only have one description item in a form');
    }

    const newFormItemIsReferenceImage = newFormItem.type === 'reference-images';
    const currentFormHasReferenceImageAlready = currentFormItems.find((i) => i.type === 'reference-images');
    if (newFormItemIsReferenceImage && currentFormHasReferenceImageAlready) {
      toast.warn('You can have multiple image upload questions, however when accepting requests '
          + 'all the images will be treated the same and added as simple commission reference images');
    }
  };

  const onDragEnd = (result: DropResult) => {
    const {
      source, destination,
    } = result;

    if (!destination) {
      toast.info('no destination');
      return;
    }

    switch (destination.droppableId) {
      case source.droppableId:
        setFormItems((oldFormItems) => reorder(oldFormItems, source.index, destination.index));
        break;
      case 'formDefDroppable':
        // eslint-disable-next-line no-case-declarations
        const newFormItem = BASE_ITEMS[source.index];
        if (newFormItem.getNewFormItem) {
          const newFormItemX = newFormItem.getNewFormItem();
          try {
            validateNewFormItem(newFormItemX, formItems);
          } catch (e) {
            if (e instanceof Error) {
              toast.error(e.message);
              return;
            }
            toast.error('Unknown error');
            console.error(e, 'Unknown error thrown when dropping item into request form');
          }
          setFormItems((oldFormItems) => getArrayWithNewItem(newFormItemX, oldFormItems, destination));
        } else {
          toast.warn('Dropped item has no defined RequestFormQuestion');
        }
        break;
      default:
        toast.info('ignored drag');
        break;
    }
  };

  const handleFormItemChange = ({
    itemDefUuid,
    newTitle,
    newParagraph,
    newOptions,
    newIsOptional,
  }: {
    itemDefUuid: string,
    newTitle?: string,
    newParagraph?: JSONContent,
    newOptions?: QuestionOption[],
    newIsOptional?: boolean
  }) => {
    setFormItems((previousFormItems) => {
      let newFormItems = Array.from(previousFormItems);
      const editedItem = newFormItems.find((rfi) => itemDefUuid === rfi.uuid);
      if (editedItem !== undefined) {
        editedItem.title = newTitle !== undefined ? newTitle : editedItem.title;
        editedItem.paragraph = newParagraph !== undefined ? newParagraph : editedItem.paragraph;
        editedItem.options = newOptions !== undefined ? newOptions : editedItem.options;
        editedItem.isOptional = newIsOptional !== undefined ? newIsOptional : editedItem.isOptional;
        newFormItems = newFormItems.map((fi) => (fi.uuid !== editedItem.uuid ? fi : editedItem));
      }

      return newFormItems;
    });
  };

  const saveRequestFormChanges = async () => {
    setIsSubmitting(true);
    try {
      const requestFormWritable = ({
        isOpen: isCurrentFormOpen,
        formTitle,
        formQuestions: formItems,
      });
      await RequestsApi.saveForm(requestFormWritable);
      const deepCloneFormItems = JSON.parse(JSON.stringify(formItems));
      setLastSavedFormTitle(formTitle);
      setLastSavedFormItems(deepCloneFormItems);
      setLastSavedFormOpen(isCurrentFormOpen);

      toast.info('Saved form');
      await userContext.reloadUser();
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAllFormWarningMessages = () => {
    const warningMessages: string[] = [];

    const missingDescription = !formItems.find((i) => i.type === 'description');
    if (missingDescription) {
      warningMessages.push('Missing request description question');
    }

    const missingReferenceImages = !formItems.find((i) => i.type === 'reference-images');
    if (missingReferenceImages) {
      warningMessages.push('Missing reference image upload');
    }

    return warningMessages;
  };

  const onSaveClicked = () => {
    const warningMessages: string[] = getAllFormWarningMessages();

    if (warningMessages.length > 0) {
      const warningMessage = (
        <Box>
          <Typography>There are potential problems with the request form:</Typography>
          <ul>
            {warningMessages.map((m) => (<li key={m}>{m}</li>))}
          </ul>
          <Typography>
            You may still create the form but it may result in missing information from clients.
            Continue?
          </Typography>
        </Box>
      );

      setFormWarningMessage(warningMessage);
      setIsRequestFormWarningsDialogOpen(true);
      return;
    }

    // If no errors, simply save immediately
    saveRequestFormChanges();
  };

  const onDiscardClicked = () => {
    setIsDiscardConfirmationDialogOpen(true);
  };

  const resetForm = () => {
    setFormTitle(lastSavedFormTitle);
    setFormItems(JSON.parse(JSON.stringify(lastSavedFormItems)));
    setIsCurrentFormOpen(lastSavedFormOpen);
  };

  /**
   * Sets the current form back to the template (without saving)
   */
  const resetToTemplate = () => {
    setFormItems(getTemplateFormQuestions());
  };

  useEffect(() => {
    const formTitleChanged = lastSavedFormTitle !== formTitle;
    const formItemsChanged = JSON.stringify(lastSavedFormItems) !== JSON.stringify(formItems);
    const openChanged = lastSavedFormOpen !== isCurrentFormOpen;

    setIsFormChangesUnsaved(formTitleChanged || formItemsChanged || openChanged);
  }, [formTitle, formItems, isCurrentFormOpen, lastSavedFormTitle, lastSavedFormItems, lastSavedFormOpen]);

  const requestsSettingRows: LeftRightColumnRow[] = [
    {
      key: '1',
      left: 'Open for Requests',
      right: (
        <FormGroup>
          <FormControlLabel
            label="Active"
            control={(
              <Switch
                checked={isCurrentFormOpen}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsCurrentFormOpen(event.target.checked);
                }}
              />
            )}
          />
        </FormGroup>
      ),
    },
    {
      key: '2',
      left: 'Request Url',
      right: (
        <Stack
          direction={{ sm: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <TextField
            fullWidth
            disabled
            size="small"
            value={`${window.location.origin}/requests/${requestUrl}`}
          />
          <Box>
            <Button
              sx={{
                width: '100%', height: '100%',
              }}
              variant="contained"
              startIcon={<StarIcon />}
              disabled={userContext.user.plan !== 'paid'}
              onClick={() => { setIsEditRequestUrlDialogOpen(true); }}
            >
              Edit
            </Button>
          </Box>
        </Stack>
      ),
    },
    {
      key: '3',
      left: 'Reset Form to Template Default',
      right: (
        <Button variant="contained" onClick={() => { setIsResetFormDialogOpen(true); }}>
          Reset Form
        </Button>
      ),
    },

  ];

  return (
    <Box>
      <ConfirmationDialog
        id="confirm-reset-form-template-dialog"
        title="Reset Form to Template"
        open={isResetFormDialogOpen}
        confirmationMessage="Reset form to the template? Your form will not be overwritten by the template until you save it."
        confirmButtonText="Confirm"
        onConfirmedClicked={() => {
          resetToTemplate();
          setIsResetFormDialogOpen(false);
        }}
        onClosedOrCancelClicked={() => {
          setIsResetFormDialogOpen(false);
        }}
      />

      <ConfirmationDialog
        id="confirm-request-form-warnings-dialog"
        title="Request Form Warnings"
        open={isRequestFormWarningsDialogOpen}
        confirmationMessage={formWarningMessage}
        confirmButtonText="Confirm"
        onConfirmedClicked={() => {
          saveRequestFormChanges().then(() => {});
          setIsRequestFormWarningsDialogOpen(false);
        }}
        onClosedOrCancelClicked={() => {
          setIsRequestFormWarningsDialogOpen(false);
        }}
      />
      <BoxFlexColumn>
        <TitlePaper title="Request Form">
          <EditRequestUrlDialog
            isOpen={isEditRequestUrlDialogOpen}
            existingUrl={requestUrl}
            onEditedRequestUrl={(newRequestUrl: string) => {
              setRequestUrl(newRequestUrl);
            }}
            onClose={() => {
              setIsEditRequestUrlDialogOpen(false);
            }}
          />
          <LeftRightColumnComponent rows={requestsSettingRows} />
        </TitlePaper>

        <BoxFlexColumn>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={3}>
              <Grid item id="left-col" sm={3}>
                <TitlePaper title="Items">
                  <BoxFlexColumn>
                    <Droppable droppableId="baseItemDroppable" isDropDisabled>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Grid container spacing={3}>
                            {BASE_ITEMS.map((baseItem, index) => (
                              <Draggable key={baseItem.name} draggableId={`base-draggable-item-${index}`} index={index}>
                                {(draggableProvided, snapshot1) => (
                                  <Grid item sm={12}>
                                    <Paper
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      variant="outlined"
                                      sx={{
                                        display: 'flex', flexDirection: 'row', columnGap: '20px',
                                      }}
                                    >
                                      {baseItem.icon}
                                      <Typography variant="body1">{baseItem.name}</Typography>
                                    </Paper>
                                    {/* Clone item */}
                                    {snapshot1.isDragging && (
                                    <Paper>
                                      {baseItem.name}
                                    </Paper>
                                    )}
                                  </Grid>
                                )}
                              </Draggable>
                            ))}
                          </Grid>
                          {/* Prevent the dev console message from showing up */}
                          <Box hidden>{provided.placeholder}</Box>
                        </Box>
                      )}
                    </Droppable>
                  </BoxFlexColumn>
                </TitlePaper>
              </Grid>
              <Grid item id="right-col" sm={9}>
                <TitlePaper title="Request Form">
                  <BoxFlexColumn>
                    <ClickInput
                      initialValue={formTitle}
                      onChange={(newValue) => { setFormTitle(newValue); }}
                      variant="h3"
                    />
                    <Droppable droppableId="formDefDroppable">
                      {(droppableProvided:DroppableProvided, snapshot: DroppableStateSnapshot) => (
                        <Paper
                          ref={droppableProvided.innerRef}
                          style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                          {...droppableProvided.droppableProps}
                          sx={{ minHeight: '200px' }}
                        >
                          <Grid container spacing={3}>
                            {formItems.length > 0 ? formItems.map((formItem, index) => (
                              <Draggable
                                key={formItem.uuid}
                                draggableId={`form-item-draggable-${formItem.uuid}`}
                                index={index}
                              >
                                {(draggableProvided) => (
                                  <Grid item sm={12}>
                                    <Paper
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      key={formItem.uuid}
                                    >
                                      <EditableFormItem
                                        key={formItem.uuid}
                                        question={formItem}
                                        onDeleteQuestionClicked={() => { deleteFormItem(formItem.uuid); }}
                                        onIsOptionalChanged={(newIsOptional) => {
                                          handleFormItemChange({
                                            itemDefUuid: formItem.uuid,
                                            newIsOptional,
                                          });
                                        }}
                                        onTitleChanged={(newTitle) => {
                                          handleFormItemChange({
                                            itemDefUuid: formItem.uuid,
                                            newTitle,
                                          });
                                        }}
                                        onParagraphChanged={(newParagraph) => {
                                          handleFormItemChange({
                                            itemDefUuid: formItem.uuid,
                                            newParagraph,
                                          });
                                        }}
                                        onOptionsChanged={(newOptions) => {
                                          handleFormItemChange({
                                            itemDefUuid: formItem.uuid,
                                            newOptions,
                                          });
                                        }}
                                      />
                                    </Paper>
                                  </Grid>
                                )}
                              </Draggable>
                            ))
                              : (
                                <Grid item sm={12}>
                                  <Paper>
                                    Drop items here
                                  </Paper>
                                </Grid>
                              )}
                          </Grid>
                          {droppableProvided.placeholder}
                        </Paper>
                      )}
                    </Droppable>
                  </BoxFlexColumn>
                </TitlePaper>
              </Grid>
            </Grid>
          </DragDropContext>
          <BoxFlexRow>
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              disabled={!isFormChangesUnsaved}
              onClick={onSaveClicked}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              disabled={isSubmitting || !isFormChangesUnsaved}
              onClick={onDiscardClicked}
            >
              Discard
            </Button>
          </BoxFlexRow>
        </BoxFlexColumn>
        <ConfirmationDialog
          id="discard-confirmation-dialog"
          open={isDiscardConfirmationDialogOpen}
          confirmButtonText="Discard"
          onConfirmedClicked={() => {
            resetForm();
            setIsDiscardConfirmationDialogOpen(false);
          }}
          onClosedOrCancelClicked={() => {
            setIsDiscardConfirmationDialogOpen(false);
          }}
        />
      </BoxFlexColumn>
    </Box>
  );
}

export default CreateRequestFormComponent;
