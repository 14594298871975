import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ViewRequestSubmissionWithClientComponent
  from 'src/components/myoc-components/requests/ViewRequestSubmissionWithClientComponent';
import { Typography } from '@mui/material';
import Page from '../../../components/Page';
import * as PublicRequestFormApi from '../../../api/PublicRequestFormApi';
import { RequestFormClientSubmissionWithFiles } from '../../../representations/RequestFormClientSubmission';
import { useArtistUserContext } from '../../../components/myoc-components/context/request-background-context/ArtistUserProvider';
import PublicUserProfileComponent from '../../../components/myoc-components/PublicUserProfileComponent';
import { DarkTransparentPaper } from '../../../components/myoc-components/styled-containers/DarkTransparentPaper';
import LeftAndRightGridScrollableRightComponent
  from '../../../components/myoc-components/LeftAndRightGridScrollableRightComponent';

const PAGE_TITLE = 'Request Details';

export default function ViewRequestDetailsPage() {
  const { submissionUuid } = useParams();
  const artistUserContext = useArtistUserContext();

  const [
    requestFormSubmission,
    setRequestFormSubmission,
  ] = React.useState<RequestFormClientSubmissionWithFiles | undefined | null>(undefined);

  useEffect(() => {
    const load = async (uuid: string) => {
      const requestFormSubmissionL = await PublicRequestFormApi.getFormSubmission(uuid);
      setRequestFormSubmission(!requestFormSubmissionL ? null : requestFormSubmissionL);

      // Check if the artist is loaded already, if not then call a load for it. Or if the artist is different
      if (!artistUserContext.artistUser) {
        console.log('first load artist');
        artistUserContext.setArtistUserUuidToLoad(requestFormSubmissionL.artistUserUuid);
      } else if (artistUserContext.artistUser.userUuid !== requestFormSubmissionL.artistUserUuid) {
        artistUserContext.setArtistUserUuidToLoad(requestFormSubmissionL.artistUserUuid);
        console.log('reloaded artist for some reason');
      } else {
        console.log('already loaded artist');
      }
    };

    if (submissionUuid) {
      load(submissionUuid).then(() => {});
    }
  }, [submissionUuid]);

  if (!artistUserContext.isLoadingComplete) {
    return (
      <Page title={PAGE_TITLE}>
        Loading
      </Page>
    );
  }

  if (!artistUserContext.artistUser) {
    // TODO 404
    return (
      <Page title={PAGE_TITLE}>
        {'Couldn\'t find an artist for this link!'}
      </Page>
    );
  }

  const leftSide = (
    <PublicUserProfileComponent
      displayName={artistUserContext.artistUser.displayName}
      userProfilePictureUrl={artistUserContext.artistUser.profilePicture?.url}
    />
  );

  let rightSide: React.ReactNode;
  if (requestFormSubmission === null) {
    // TODO 404
    rightSide = (
      <DarkTransparentPaper
        id="request-form-paper"
      >
        <Typography>{'Couldn\'t find a submission with this id'}</Typography>
      </DarkTransparentPaper>
    );
  } else if (requestFormSubmission === undefined) {
    rightSide = (
      <DarkTransparentPaper
        id="request-form-paper"
      >
        <Typography>Loading...</Typography>
      </DarkTransparentPaper>
    );
  } else {
    rightSide = (
      <DarkTransparentPaper
        id="request-form-paper"
      >
        <ViewRequestSubmissionWithClientComponent requestFormSubmission={requestFormSubmission} />
      </DarkTransparentPaper>
    );
  }

  return (
    <Page
      id="page"
      title={PAGE_TITLE}
      paddingTop={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LeftAndRightGridScrollableRightComponent
        left={leftSide}
        right={rightSide}
      />
    </Page>
  );
}
