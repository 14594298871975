import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import LoadingOverlay from '../LoadingOverlay';
import * as CommissionApi from '../../../api/CommissionApi';
import { ViewableCommissionAttachment } from '../../../representations/CommissionAttachment';
import ImageListAndViewerComponent from '../imageviewer/ImageListAndViewerComponent';
import TitlePaper from '../styled-containers/TitlePaper';
import { useCommissionContext } from '../context/commission-context/CommissionContext';

interface Props {
  commissionUuid: string
  previewImageObjectKey?: string
  artistUserUuid: string
  maxFileSizeBytes: number
  parentFiles?: File[]
}

export default function CommissionReferencesImages({
  commissionUuid,
  previewImageObjectKey,
  artistUserUuid,
  maxFileSizeBytes,
  parentFiles,
}:Props) {
  const commissionContext = useCommissionContext();

  const [isLoadingImages, setIsLoadingImages] = React.useState(false);
  const [images, setImages] = React.useState<ViewableCommissionAttachment[]>([]);

  const loadImages = async () => {
    try {
      setIsLoadingImages(true);
      const i = await CommissionApi.getCommissionReferenceImages(commissionUuid);
      setImages(i);
      await commissionContext.reloadCommission(commissionUuid); // Also need to reload status of preview image
    } finally {
      setIsLoadingImages(false);
    }
  };

  const setPreviewImage = async (attachment: ViewableCommissionAttachment) => {
    try {
      await commissionContext.setPreviewImage(attachment);
      toast.info('Updated preview image');
      loadImages().then(() => {});
    } catch (e) {
      toast.error('Failed to updated preview image');
      console.error(e);
    }
  };

  useEffect(() => {
    // First load
    loadImages().then(() => {});
  }, []);

  return (
    <TitlePaper title="References">
      <LoadingOverlay active={isLoadingImages} text="Loading References">
        <ImageListAndViewerComponent
          uploadableOptions={{
            maxFileSizeBytes,
            commissionUuid,
            previewImageObjectKey,
            artistUserUuid,
            onFilesUpdated: () => {
              loadImages().then(() => {
              });
            },
            onPreviewImageUpdated: setPreviewImage,
          }}
          files={images}
          parentFiles={parentFiles}
        />
      </LoadingOverlay>
    </TitlePaper>
  );
}
