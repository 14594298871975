import React from 'react';
import Page from 'src/components/Page';

import DashboardComponent from '../components/myoc-components/dashboard/DashboardComponent';

export default function DashboardPage() {
  return (
    <Page title="Dashboard">
      <DashboardComponent />
    </Page>
  );
}
