import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography } from '@mui/material';
import React from 'react';
import BoxFlexColumn from './styled-containers/BoxFlexColumn';

interface Props {
  text?: string
}

export default function CenteredPlusIconAndAddImagesComponent({ text = 'Add Images' }:Props) {
  return (
    <BoxFlexColumn sx={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 150,
      minWidth: 150,
    }}
    >
      <AddCircleIcon sx={{
        color: (theme) => theme.palette.grey[500],
        minHeight: 75,
        minWidth: 75,
      }}
      />
      <Typography>{text}</Typography>
    </BoxFlexColumn>

  );
}
