import React from 'react';
import { ToastContainer } from 'react-toastify';
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
      <ToastContainer />
    </ThemeConfig>
  );
}
