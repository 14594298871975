import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Client } from '../../representations/Client';
import ClientAvatar from './ClientAvatar';
import {
  FONT_HEADERS, pxToRem,
} from '../../theme/typography';
import BoxFlexRow from './styled-containers/BoxFlexRow';
import BoxFlexColumn from './styled-containers/BoxFlexColumn';
import AddClientDialogComponent from './AddClientDialogComponent';

type Props = {
  client : Client,
};

const AVATAR_SIZE = 80;

export default function ClientBox({ client }:Props) {
  const [isEditClientDialogOpen, setIsEditClientDialogOpen] = React.useState(false);

  const AccountStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 3, 0),
    borderRadius: theme.shape.borderRadiusSm,
  }));

  const openEditClientDialog = () => {
    setIsEditClientDialogOpen(true);
  };

  return (
    <AccountStyle>
      <AddClientDialogComponent
        isOpen={isEditClientDialogOpen}
        onCancel={() => { setIsEditClientDialogOpen(false); }}
        onComplete={() => { setIsEditClientDialogOpen(false); }}
        existingClient={client}
      />
      <ClientAvatar
        clientName={client.name}
        sx={{
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
          fontSize: AVATAR_SIZE * 0.75,
          verticalAlign: 'center',
        }}
      />
      <BoxFlexColumn sx={{
        marginLeft: 2,
        gap: (theme) => theme.spacing(1),
      }}
      >
        <BoxFlexRow sx={{ gap: (theme) => theme.spacing(1) }}>
          <Typography
            variant="body1"
            sx={{
              color: 'text.primary',
              fontWeight: 600,
              lineHeight: 1.5,
              fontSize: pxToRem(20),
              textTransform: 'capitalize',
            }}
          >
            {client.name}
          </Typography>
          <IconButton
            onClick={openEditClientDialog}
            color="primary"
            sx={{
              opacity: 0.4,
              '&:hover': { opacity: 1 },
              padding: 0,
            }}
          >
            <EditIcon />
          </IconButton>
        </BoxFlexRow>
        {client.email && (
          <Typography
            variant="body1"
            sx={{
              color: 'text.secondary',
              fontWeight: 400,
              lineHeight: 1.5,
              fontSize: pxToRem(18),
              textTransform: 'capitalize',
            }}
          >
            <a href={`mailto:${client.email}?`}>
              {client.email}
            </a>
          </Typography>
        )}
      </BoxFlexColumn>
    </AccountStyle>
  );
}
