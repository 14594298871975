import React, { useState } from 'react';
import {
  matchPath, useLocation, NavLink, useNavigate,
} from 'react-router-dom';
import {
  alpha, styled, useTheme,
} from '@mui/material/styles';
import {
  List, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SidebarItem } from '../layouts/dashboard/SidebarConfig';
import {
  FONT_HEADERS, pxToRem,
} from '../theme/typography';
import {
  DRAWER_OPEN_PADDING,
  ICON_SIZE, SIDEBAR_ICON_PADDING_SIZE,
} from '../layouts/dashboard/SidebarIconSizes';
import ConfirmationDialog from './myoc-components/ConfirmationDialog';

const ListItemButtonStyle = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'isSidebarOpen' })(({
  theme,
  isSidebarOpen,
}: {
  theme: Theme,
  isSidebarOpen: boolean,
}) => ({
  ...theme.typography.body2,
  position: 'relative',
  color: theme.palette.text.secondary,

  ...(isSidebarOpen && ({
    paddingLeft: DRAWER_OPEN_PADDING,
    marginRight: ((DRAWER_OPEN_PADDING / 3) * 1),
    paddingRight: ((DRAWER_OPEN_PADDING / 3) * 3),
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  })),
  ...(!isSidebarOpen && ({
    paddingLeft: SIDEBAR_ICON_PADDING_SIZE,
    marginRight: 0,
    paddingRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  })),
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON_SIZE,
  height: ICON_SIZE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface NavItemProps {
  item: SidebarItem,
  active: (path:string) => boolean,
  isSidebarOpen: boolean
}

function NavItem({
  item,
  active,
  isSidebarOpen,
}: NavItemProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const isActiveRoot = active(item.path);
  const {
    title, path, icon, info, children, confirmationDialog,
  } = item;
  // const [open, setOpen] = useState(isActiveRoot);
  // const activeSubStyle = {
  //   color: 'text.primary',
  //   fontWeight: 'fontWeightMedium',
  // };

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const activeRootStyle = {
    color: 'primary.main',
    bgcolor: alpha(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': { display: 'block' },
  };

  const primary = (
    <Typography sx={{
      fontFamily: FONT_HEADERS,
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: pxToRem(15),
      textTransform: 'capitalize',
    }}
    >
      {title}
    </Typography>
  );

  return (
    <>
      {confirmationDialog && (
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onConfirmedClicked={() => { navigate(path); }}
        onClosedOrCancelClicked={() => { setIsConfirmDialogOpen(false); }}
        title={confirmationDialog.title}
        confirmationMessage={confirmationDialog.confirmationMessage}
        confirmButtonText={confirmationDialog.confirmButtonText}
      />
      )}
      <ListItemButtonStyle
        theme={theme}
        disableGutters
        sx={{ ...(isActiveRoot && activeRootStyle) }}
        isSidebarOpen={isSidebarOpen}
        onClick={(() => {
          if (confirmationDialog) {
            setIsConfirmDialogOpen(true);
          } else {
            navigate(path);
          }
        })}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText primary={primary} />
        {/* {info && info} */}
      </ListItemButtonStyle>
    </>
  );
}

interface NavSectionProps {
  navConfig: SidebarItem[]
  isSidebarOpen: boolean
}

function NavSection({
  navConfig,
  isSidebarOpen,
}: NavSectionProps) {
  const { pathname } = useLocation();

  const match = (path: string) => (path ? !!matchPath({
    path, end: false,
  }, pathname) : false);

  return (
    <List
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
      })}
    >
      {navConfig.map((item) => (
        <NavItem
          key={item.title}
          item={item}
          active={match}
          isSidebarOpen={isSidebarOpen}
        />
      ))}
    </List>
  );
}

export default NavSection;

// if (children) {
//   return (
//     <>
//       <ListItemStyle
//         onClick={handleOpen}
//         sx={{ ...(isActiveRoot && activeRootStyle) }}
//       >
//         <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
//         <ListItemText disableTypography primary={title} />
//         {info && info}
//         <Box
//           component={Icon}
//           icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
//           sx={{
//             width: 16, height: 16, ml: 1,
//           }}
//         />
//       </ListItemStyle>
//
//        <Collapse in={open} timeout="auto" unmountOnExit>
//         <List component="div" disablePadding>
//           {children.map((theItem) => {
//             const {
//               title: theTitle, path: thePath,
//             } = theItem;
//             const isActiveSub = active(thePath);
//
//             return (
//               <ListItemStyle
//                 key={theTitle}
//                 component={RouterLink}
//                 to={thePath}
//                 sx={{ ...(isActiveSub && activeSubStyle) }}
//               >
//                 <ListItemIconStyle>
//                   <Box
//                     component="span"
//                     sx={{
//                       width: 4,
//                       height: 4,
//                       display: 'flex',
//                       borderRadius: '50%',
//                       alignItems: 'center',
//                       justifyContent: 'center',
//                       bgcolor: 'text.disabled',
//                       transition: (theTheme) => theTheme.transitions.create('transform'),
//                       ...(isActiveSub && {
//                         transform: 'scale(2)',
//                         bgcolor: 'primary.main',
//                       }),
//                     }}
//                   />
//                 </ListItemIconStyle>
//                 <ListItemText disableTypography primary={theTitle} />
//               </ListItemStyle>
//             );
//           })}
//         </List>
//        </Collapse>
//     </>
//   );
// }
