import axios, { AxiosError } from 'axios';
import DuplicateItemError from './DuplicateItemError';
import { SERVER_URL } from './SharedApiStuff';
import ApiError, { ERROR_EDIT_REQUEST_URL } from './ApiError';
import { UserSettings } from '../representations/UserSettings';

interface PutRequestUrlRequest {
  updatedRequestUrl: string;
}

export async function editRequestUrl(updatedRequestUrl: string): Promise<void> {
  try {
    const url = `${SERVER_URL}/api/v1/user/request-url`;
    const data: PutRequestUrlRequest = { updatedRequestUrl };

    await axios.put(url, data, { withCredentials: true });

    return;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      if (error.response !== undefined && error.response.status === 409) {
        throw new DuplicateItemError('Request URL already exists', 'Request URL');
      }
    }

    throw new ApiError('Error editing Request URL', ERROR_EDIT_REQUEST_URL, e);
  }
}

interface GetUserSettingsResponse {
  userSettings: UserSettings
}

export async function getUserSettings(): Promise<UserSettings> {
  try {
    const url = `${SERVER_URL}/api/v1/user/settings`;

    const response: GetUserSettingsResponse = (await axios.get(url, { withCredentials: true })).data;

    return response.userSettings;
  } catch (e) {
    throw new ApiError('Error getting user settings', 'ERROR_GET_USER_SETTINGS', e);
  }
}

interface PutUserSettingsRequest {
  userSettings: UserSettings
}

export async function updateUserSettings(newSettings: UserSettings) {
  try {
    const url = `${SERVER_URL}/api/v1/user/settings`;
    const data: PutUserSettingsRequest = { userSettings: newSettings };

    await axios.put(url, data, { withCredentials: true });

    return;
  } catch (e) {
    throw new ApiError('Error updating user settings', 'ERROR_UPDATE_USER_SETTINGS', e);
  }
}

interface GetDisplayNameAvailabilityCheckResponse {
  isAvailable: boolean
}

export async function checkDisplayNameIsAvailable(newDisplayName: string): Promise<boolean> {
  try {
    const url = `${SERVER_URL}/api/v1/user/settings/display-name-check/${newDisplayName}`;

    const response: GetDisplayNameAvailabilityCheckResponse = (await axios.get(url, { withCredentials: true })).data;

    return response.isAvailable;
  } catch (e) {
    throw new ApiError('Error checking display name availability', 'ERROR_CHECK_DISPLAY_NAME_AVAILABILITY', e);
  }
}
