import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const DarkTransparentStyle = {
  color: 'white',
  backgroundColor: 'rgba(0,0,0,0.55)',
  backgroundColorLighter: 'rgba(0,0,0,0.35)',
  boxShadow: 'none',
  backdropFilter: 'blur(10px)',
};
export const DarkTransparentHoverStyle = { backgroundColor: 'rgba(0,0,0,0.65)' };

export const DarkTransparentPaper = styled(Paper)(({ theme }) => ({ ...DarkTransparentStyle }));
