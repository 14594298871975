import { TextField } from '@mui/material';
import React from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { TextFieldElement } from 'react-hook-form-mui';
import { RequestFormQuestion } from '../../../representations/RequestFormQuestion';

interface Props {
  disabled?: boolean
  type: 'short' | 'long'
  otherProps?: TextFieldProps
  question?: RequestFormQuestion
}

export default function FormTextField({
  disabled = false,
  type,
  otherProps = {},
  question,
}:Props): JSX.Element {
  const minimumRows = type === 'short' ? 0 : 4;

  if (disabled) {
    return (
      <TextField
        {...otherProps}
        disabled
        fullWidth
        multiline={type === 'long'}
        minRows={minimumRows}
        variant="outlined"
      />
    );
  }

  if (!question) {
    throw new Error('must provide question if using for write purposes');
  }

  return (
    <TextFieldElement
      fullWidth
      variant="outlined"
      multiline={type === 'long'}
      minRows={minimumRows}
      required={!question.isOptional}
      name={question.uuid}
    />
  );
}
