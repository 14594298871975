import { Outlet } from 'react-router-dom';
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeferredImage from '../components/DeferredImage';
import PublicTopBar from '../components/myoc-components/PublicTopBar';

export const BackgroundImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1000,
}));

const LeftMdCenterHandSideBox = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('md')]: {
    width: '40%',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(12),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'center',
    marginLeft: 0,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: theme.spacing(12),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // backgroundColor: theme.palette.primary.main,
  // border: '2px dashed #eaeaea',
}));

const color = '#41a3d7';

export default function ArtBackgroundLayout() {
  return (
    <>
      <BackgroundImageBox>
        <DeferredImage
          src="/static/background-images/background.png"
          alt="Background Image"
          imagePrimaryColor={color}
        />
      </BackgroundImageBox>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
      >
        <PublicTopBar />
        <LeftMdCenterHandSideBox id="left-hand-side">
          <Outlet />
        </LeftMdCenterHandSideBox>
      </Box>
    </>
  );
}
