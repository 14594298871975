import { Payment } from './commission-metadata/Payment';
import { Note } from './commission-metadata/Note';

export interface StringPropertySet {
  [key: string]: string
}

/**
 * Represents the json object within commission-metadata table
 */
export interface CommissionMetadata {
  payment?: Payment
  notes?: Note[]
  stringProperties?: StringPropertySet
  description?: string | object
  commissionThumbnailObjectKey?: string
}

export function getMapFromStringProperties(stringProperties?: StringPropertySet): Map<string, string> {
  if (!stringProperties) {
    return new Map<string, string>(Object.entries([]));
  }
  return new Map<string, string>(Object.entries(stringProperties));
}

export function getStringPropertiesFromMap(map: Map<string, string>): StringPropertySet {
  return Object.fromEntries(map);
}
