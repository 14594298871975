import {
  Box, TextField, Typography,
} from '@mui/material';
import React from 'react';
import ViewRequestSubmissionComponent from './ViewRequestSubmissionComponent';
import { RequestFormClientSubmissionWithFiles } from '../../../representations/RequestFormClientSubmission';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

interface Props {
  requestFormSubmission: RequestFormClientSubmissionWithFiles;
}

export default function ViewRequestSubmissionWithClientComponent({ requestFormSubmission }: Props) {
  return (
    <BoxFlexColumn>
      <Typography variant="h2">Request Form</Typography>
      <BoxFlexRow>
        <Box>
          <Typography variant="h3">Name</Typography>
          <TextField
            disabled
            value={requestFormSubmission.clientName}
          />
        </Box>
        <Box>
          <Typography variant="h3">Email</Typography>
          <TextField
            disabled
            value={requestFormSubmission.clientEmail}
          />
        </Box>
      </BoxFlexRow>
      <ViewRequestSubmissionComponent requestFormAnswers={requestFormSubmission.answers} />
    </BoxFlexColumn>
  );
}
