import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Card, Container, Stack, Typography,
} from '@mui/material';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({ [theme.breakpoints.up('md')]: { display: 'flex' } }));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LogoutSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    // Cookie cleared, redirect to home after a wait
    setTimeout(() => { navigate('/login'); }, 2000);
  }, []);

  return (
    <RootStyle title="Logout Success">

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h3"
            sx={{
              px: 5, mt: 10, mb: 5,
            }}
          >
            Goodbye
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Success
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              You have been logged out
            </Typography>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
