import React from 'react';
import {
  Navigate, useRoutes,
} from 'react-router-dom';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import Login from './pages/Login';
import LoginSuccess from './pages/LoginSuccess';
import Logout from './pages/Logout';
import LogoutSuccess from './pages/LogoutSuccess';
import NotFound from './pages/Page404';
import CommissionList from './pages/commission/CommissionList';
import AddCommission from './pages/commission/AddCommission';
import ClientList from './pages/client/ClientList';
import EditCommission from './pages/commission/EditCommission';
import DashboardPage from './pages/DashboardPage';
import CommissionDetailsPage from './pages/commission/details/CommissionDetailsPage';
import RequestFormEditPage from './pages/commission/RequestFormEditPage';
import ArtistPublicLayout from './layouts/ArtistPublicLayout';
import CreateRequestPage from './pages/public/requests/CreateRequestPage';
import ViewRequestDetailsPage from './pages/public/requests/ViewRequestDetailsPage';
import RequestInboxPage from './pages/commission/RequestInboxPage';
import SettingsPage from './pages/SettingsPage';
import ArtBackgroundLayout from './layouts/ArtBackgroundLayout';
import WhitelistPage from './pages/WhitelistPage';
import SchedulePage from './pages/SchedulePage';

export default function Router() {
  return useRoutes([
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardPage />,
        },
        {
          path: 'commissions',
          children: [
            {
              path: '',
              element: <Navigate to="/app/commissions/list" replace />,
            },
            {
              path: 'list',
              element: <CommissionList />,
            },
            {
              path: 'add',
              element: <AddCommission />,
            },
            {
              path: 'edit',
              element: <EditCommission />,
            },
            {
              path: 'details/:commissionUuid',
              element: <CommissionDetailsPage />,
            },
          ],
        },
        {
          path: 'requests',
          children: [
            {
              path: 'form/edit',
              element: <RequestFormEditPage />,
            },
            {
              path: 'inbox',
              element: <RequestInboxPage />,
            },
          ],
        },
        {
          path: 'clients',
          children: [
            {
              path: '',
              element: <Navigate to="/app/clients/list" replace />,
            },
            {
              path: 'list',
              element: <ClientList />,
            },
          ],
        },
        {
          path: 'schedule',
          element: <SchedulePage />,
        },
        {
          path: 'settings',
          element: <SettingsPage />,
        },
      ],
    },
    {
      path: 'requests',
      element: <ArtistPublicLayout />,
      children: [
        {
          // TODO i don't like this, think of a better url?
          path: 'details/:submissionUuid',
          element: <ViewRequestDetailsPage />,
        },
        {
          path: ':artistCustomLink',
          element: <CreateRequestPage />,
        },
      ],
    },
    {
      path: '',
      element: <ArtBackgroundLayout />,
      children: [
        {
          path: 'login',
          children: [
            {
              path: '',
              element: <Login />,
            },
            {
              path: 'success',
              element: <LoginSuccess />,
            },
          ],
        },
        {
          path: 'logout',
          children: [
            {
              path: '',
              element: <Logout />,
            },
            {
              path: 'success',
              element: <LogoutSuccess />,
            },
          ],
        },
        {
          path: 'whitelist',
          children: [
            {
              path: '',
              element: <WhitelistPage />,
            },
          ],
        },
        {
          path: '',
          element: <Navigate to="/app/commissions" />,
        },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
}
