import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const StyledContainer = styled(Container)`
  display: block;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2.5)};
  border-radius: ${({ theme }) => theme.shape.borderRadiusSm};
  background-color: ${({ theme }) => theme.palette.background.neutral};
`;

export default StyledContainer;
