import axios from 'axios';
import { SERVER_URL } from './SharedApiStuff';
import ApiError from './ApiError';
import { FeedbackReport } from '../representations/FeedbackReport';

export type PostFeedbackReportRequest = {
  feedbackReport: FeedbackReport
};

export async function submitReport(feedbackReport: FeedbackReport): Promise<void> {
  try {
    const url = `${SERVER_URL}/api/v1/feedback`;
    const data: PostFeedbackReportRequest = { feedbackReport };
    await axios.post(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error creating feedback report', 'ERROR_CREATE_FEEDBACK_REPORT', e);
  }
}
