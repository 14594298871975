import {
  Avatar, Box, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { DarkTransparentPaper } from './styled-containers/DarkTransparentPaper';

interface Props {
  userProfilePictureUrl?: string
  displayName: string
}

export default function PublicUserProfileComponent({
  userProfilePictureUrl,
  displayName,
}:Props) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: (theme) => theme.spacing(3),
    }}
    >
      <Avatar
        sx={{
          height: 200,
          width: 200,
        }}
        src={userProfilePictureUrl || ''}
        alt={displayName}
      />
      <Typography
        variant="h1"
        sx={{
          fontSize: 45,
          color: (theme) => theme.palette.common.white,
        }}
      >
        {displayName}
      </Typography>
      <Stack direction="row">
        TODO ADD MY SOCIAL MEDIA ICONS
      </Stack>
      <DarkTransparentPaper sx={{
        width: 450, minHeight: 150,
      }}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>Todo: Add a bio here</Typography>
      </DarkTransparentPaper>
    </Box>
  );
}
