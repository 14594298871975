import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import BoxFlexRow from './styled-containers/BoxFlexRow';

const DEFAULT_TITLE = 'Discard Changes?';
const DEFAULT_UNSAVED_ITEMS_MESSAGE = 'Changes you have made may be unsaved. Discard?';
const DEFAULT_CONFIRM_BUTTON_TEXT = 'Confirm';
const DEFAULT_CANCEL_BUTTON_TEXT = 'Cancel';

interface Props {
  open: boolean,
  title?: string;
  confirmationMessage?: string | JSX.Element;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirmedClicked: () => void;
  onClosedOrCancelClicked: () => void;
  id?: string;
  isSubmittingConfirmation?: boolean;
}

function ConfirmationDialog({
  open,
  title = DEFAULT_TITLE,
  confirmationMessage = DEFAULT_UNSAVED_ITEMS_MESSAGE,
  confirmButtonText = DEFAULT_CONFIRM_BUTTON_TEXT,
  cancelButtonText = DEFAULT_CANCEL_BUTTON_TEXT,
  onConfirmedClicked,
  onClosedOrCancelClicked,
  isSubmittingConfirmation = false,
  id = '',
}:Props) {
  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClosedOrCancelClicked}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmationMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BoxFlexRow>
          <LoadingButton
            loading={isSubmittingConfirmation}
            onClick={onConfirmedClicked}
            variant="contained"
          >
            {confirmButtonText}
          </LoadingButton>
          <Button
            disabled={isSubmittingConfirmation}
            onClick={onClosedOrCancelClicked}
            variant="outlined"
          >
            {cancelButtonText}
          </Button>
        </BoxFlexRow>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
