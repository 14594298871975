import React, {
  createContext, useEffect,
} from 'react';
import * as ClientApi from '../../../../api/ClientApi';
import ClientContextObject from './ClientContextObject';
import { Client } from '../../../../representations/Client';
import { ClientWritable } from '../../../../representations/ClientWritable';

type Props = {
  children: React.ReactNode
};

export const ClientContext = createContext<ClientContextObject | undefined>(undefined);

function ClientProvider({ children }: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [shouldReloadData, setShouldReloadData] = React.useState<boolean>(true);
  const [clients, setClients] = React.useState<Client[]>([]);

  useEffect(() => {
    if (!shouldReloadData) {
      return;
    }
    setShouldReloadData(false);

    const getClients = async () => {
      const loaded = await ClientApi.getAllUserClients();
      setClients(loaded);
      setIsLoading(false);

      console.log(`Loaded [${loaded.length}] clients`);
    };

    getClients().then(() => {
    });
  }, [shouldReloadData]);

  const reloadClients = () => {
    setIsLoading(true);
    setShouldReloadData(true);
  };

  const archiveClients = async (clientUuids: string[]) => {
    setIsLoading(true);
    await ClientApi.archiveClient(clientUuids);
    setShouldReloadData(true);
  };

  const unarchiveClients = async (clientUuids: string[]) => {
    setIsLoading(true);
    await ClientApi.unarchiveClient(clientUuids);
    setShouldReloadData(true);
  };

  const getClientByUuid = (uuid: string) => {
    const filteredClients = clients.filter((c) => c.clientUuid === uuid);

    if (filteredClients.length === 0) {
      throw new Error(`No clients found with uuid [${uuid}]`);
    }

    return filteredClients[0];
  };

  const getAllClients = () => clients;

  const getArchivedClients = () => clients.filter((c) => c.archived);

  const getActiveClients = () => clients.filter((c) => !c.archived);

  const modifyClient = async (clientUuid: string, clientToSend: ClientWritable) => {
    setIsLoading(true);
    const client = await ClientApi.modifyClient(clientUuid, clientToSend);
    setShouldReloadData(true);
    return client;
  };

  const addClient = async (clientToSend: ClientWritable) => {
    setIsLoading(true);
    const client = await ClientApi.addClient(clientToSend);
    setShouldReloadData(true);
    return client;
  };

  // Change only on isLoading status changes - This is when we know that the data changes are all completed
  const memoClient = React.useMemo(
    ():ClientContextObject => ({
      isLoading,
      clients,
      reloadClients,
      archiveClients,
      unarchiveClients,
      getClientByUuid,
      getAllClients,
      getArchivedClients,
      getActiveClients,
      modifyClient,
      addClient,
    }),
    [isLoading],
  );

  return (
    <ClientContext.Provider value={memoClient}>
      {children}
    </ClientContext.Provider>
  );
}

const useClientContext = () => {
  const context = React.useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};

export {
  ClientProvider, useClientContext,
};
