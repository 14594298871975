import { UploadedFile } from './UploadedFile';
import { MyocFileObject } from './MyocFileObject';

export interface CommissionAttachment {
  objectKey: string; // FK to MyocFileObject
  commissionUuid: string; // FK to Commission
  isDeletable: boolean;
}

export interface NotYetViewableCommissionAttachment extends CommissionAttachment, MyocFileObject {
}

export interface ViewableCommissionAttachment extends NotYetViewableCommissionAttachment {
  uploadedFile: UploadedFile
}

export function instanceOfViewableCommissionAttachment(object: any): object is ViewableCommissionAttachment {
  return 'uploadedFile' in object && 'objectKey' in object && 'commissionUuid' in object;
}
