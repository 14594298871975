import './MenuItem.css';

import React from 'react';
import {
  Box,
  Button, SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

export interface MenuItemProps {
  TheIcon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
  title: string
  action: any
  isActive: null | (() => boolean)
}

export function instanceOfMenuItemProps(object: any): object is MenuItemProps {
  return 'action' in object && 'title' in object && 'isActive' in object;
}

const StyledIconContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.grey[600],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[600],
    color: '#fff',
  },
}));

export default function MenuItem({
  TheIcon,
  title,
  action,
  isActive = null,
}: MenuItemProps) {
  return (
    <StyledIconContainer>
      <TheIcon
        onClick={action}
      // title={title}
      />
    </StyledIconContainer>
  );
}
