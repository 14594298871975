import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  FormContainer, TextFieldElement,
} from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Client } from '../../representations/Client';
import { ClientWritable } from '../../representations/ClientWritable';
import { useClientContext } from './context/client-context/ClientContext';
import DuplicateItemError from '../../api/DuplicateItemError';
import BoxFlexRow from './styled-containers/BoxFlexRow';

interface Props {
  isOpen: boolean;
  existingClient?: Client;
  onComplete: (resultClient: Client) => void;
  onCancel: ()=>void;
}

interface FormInputs { name: string, email:string }

function AddClientDialogComponent({
  isOpen,
  existingClient = undefined,
  onComplete,
  onCancel,
}: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const isModifying = existingClient !== undefined;
  const title = isModifying ? 'Edit Client' : 'Add New Client';

  const formContext = useForm<FormInputs>();
  const clientContext = useClientContext();

  useEffect(() => {
    formContext.reset({
      name: existingClient?.name,
      email: existingClient?.email || '',
    });
  }, [existingClient]);

  const onSubmit = async (formData:FormInputs) => {
    const nameSame = formData.name === existingClient?.name;
    const emailSame = formData.email === existingClient?.email;
    const formChanged = !(nameSame && emailSame);

    if (!formChanged) {
      toast.info('Nothing changed');
    } else {
      setIsSubmitting(true);
      try {
        const clientToSend: ClientWritable = {
          clientUuid: null,
          name: formData.name,
          email: formData.email.trim() === '' ? null : formData.email,
        };

        let returnedClientObject;
        if (isModifying) {
          returnedClientObject = await clientContext.modifyClient(existingClient.clientUuid, clientToSend);
          toast.info(`Modified Client: ${clientToSend.name}`);
        } else {
          returnedClientObject = await clientContext.addClient(clientToSend);
          toast.info(`Created Client: ${returnedClientObject.name}`);
        }

        onComplete(returnedClientObject);
        setTimeout(() => {
          formContext.reset();
        }, 100);
      } catch (e) {
        if (e instanceof DuplicateItemError) {
          toast.error('Email already exists for another client');
        } else {
          toast.error(`Error ${isModifying ? 'modifying' : 'adding new'} client`);
          console.error('Failed modifying client', e);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
    >
      <FormContainer
        onSuccess={onSubmit}
        formContext={formContext}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <Typography variant="h5">Email</Typography>
              <TextFieldElement
                autoFocus
                autoComplete="email"
                name="email"
                type="email"
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h5">Name</Typography>
            <TextFieldElement
              required
              autoComplete="name"
              name="name"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <BoxFlexRow>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained" color="primary">Submit</LoadingButton>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                onCancel();
                setTimeout(() => {
                // Set this later to allow the box to close completely before toggling the switch
                  formContext.reset();
                }, 100);
              }}
            >
              Cancel
            </Button>
          </BoxFlexRow>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

export default AddClientDialogComponent;
