import React, { useEffect } from 'react';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  Button, Paper,
} from '@mui/material';
import Page from '../../../components/Page';
import { RequestForm } from '../../../representations/RequestForm';
import * as PublicRequestFormApi from '../../../api/PublicRequestFormApi';
import CreateNewRequestComponent from '../../../components/myoc-components/requests/CreateNewRequestComponent';
import SubmittedRequestPageComponent from '../../../components/myoc-components/requests/SubmittedRequestPageComponent';
import { useArtistUserContext } from '../../../components/myoc-components/context/request-background-context/ArtistUserProvider';
import BoxFlexColumn from '../../../components/myoc-components/styled-containers/BoxFlexColumn';
import { DarkTransparentPaper } from '../../../components/myoc-components/styled-containers/DarkTransparentPaper';
import PublicUserProfileComponent from '../../../components/myoc-components/PublicUserProfileComponent';
import LeftAndRightGridScrollableRightComponent
  from '../../../components/myoc-components/LeftAndRightGridScrollableRightComponent';

const PAGE_TITLE = 'Commissions';

const WhiteTransparentStyle = {
  color: 'white',
  backgroundColor: 'rgba(255,255,255,0.15)',
  boxShadow: 'none',
  backdropFilter: 'blur(10px)',
};
export const WhiteTransparentHoverStyle = { backgroundColor: 'rgba(255,255,255,0.35)' };

export default function CreateRequestPage() {
  const { artistCustomLink } = useParams();
  const navigate = useNavigate();
  const artistUserContext = useArtistUserContext();

  const [currentCommissionForm, setCurrentCommissionForm] = React.useState<RequestForm | undefined | null>(undefined);
  const [submissionUuid, setSubmissionUuid] = React.useState<string | undefined>(undefined);
  const [isShowRequestForm, setIsShowRequestForm] = React.useState<boolean>(false);

  const loadCommissionFormForCurrent = async (link: string) => {
    const requestForm = await PublicRequestFormApi.getPublicForm(link);
    setCurrentCommissionForm(requestForm);
  };

  useEffect(() => {
    if (artistCustomLink) {
      artistUserContext.setArtistUrlToLoad(artistCustomLink);
      loadCommissionFormForCurrent(artistCustomLink).then(() => {});
    } else {
      navigate('/404', { replace: true });
    }
  }, [artistCustomLink]);

  const onRequestSubmitted = (newSubmissionUuid: string) => {
    setSubmissionUuid(newSubmissionUuid);
  };

  if (!artistUserContext.isLoadingComplete) {
    return (
      <Page title={PAGE_TITLE}>
        Loading
      </Page>
    );
  }

  if (!artistUserContext.artistUser) {
    return (
      <Page title={PAGE_TITLE}>
        {'Couldn\'t find an artist for this link!'}
      </Page>
    );
  }

  const getCommissionButton = (commissionForm: RequestForm | undefined | null) => {
    let isDisabled;
    let buttonText: string;
    if (commissionForm === undefined) {
      isDisabled = true;
      buttonText = 'Loading...';
    } else if (commissionForm === null || !commissionForm.isOpen) {
      isDisabled = true;
      buttonText = 'Commissions are Closed';
    } else {
      isDisabled = false;
      buttonText = 'Make a Request';
    }

    return (
      <Button
        fullWidth
        variant="contained"
        sx={{
          ...WhiteTransparentStyle,
          height: 45,
          '&:hover': { ...WhiteTransparentHoverStyle },
        }}
        disabled={isDisabled}
        onClick={() => {
          setIsShowRequestForm(true);
        }}
      >
        {buttonText}
      </Button>
    );
  };

  const leftSide = (
    <PublicUserProfileComponent
      displayName={artistUserContext.artistUser.displayName}
      userProfilePictureUrl={artistUserContext.artistUser.profilePicture?.url}
    />
  );

  const rightSide = !isShowRequestForm ? (
    <BoxFlexColumn sx={{
      justifyContent: 'center',
      height: '100%',
    }}
    >
      <Paper>TODO Artwork carousel or placeholder if none</Paper>
      {getCommissionButton(currentCommissionForm)}
    </BoxFlexColumn>
  ) : (() => {
    if (!currentCommissionForm) {
      throw new Error('currentCommissionForm should be ready here.');
    } else if (submissionUuid) {
      return <SubmittedRequestPageComponent submissionUuid={submissionUuid} />;
    } else {
      return (
        <DarkTransparentPaper
          id="request-form-paper"
        >
          <CreateNewRequestComponent
            artistUserUuid={artistUserContext.artistUser.userUuid}
            requestForm={currentCommissionForm}
            onSubmitComplete={onRequestSubmitted}
            maximumFileBytes={artistUserContext.artistUser.maxUploadFileSize}
          />
        </DarkTransparentPaper>
      );
    }
  })();

  return (
    <Page
      id="page"
      title={PAGE_TITLE}
      paddingTop={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LeftAndRightGridScrollableRightComponent
        left={leftSide}
        right={rightSide}
      />
    </Page>
  );
}
