import { Typography } from '@mui/material';
import React from 'react';
import { CommissionSchedule } from '../../../representations/CommissionSchedule';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import TitlePaper from '../styled-containers/TitlePaper';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import SchedulerCalendarDialogEditorButton from '../calendar/SchedulerCalendarDialogEditorButton';
import DateDifferenceHumanLanguage from '../../../utils/DateDifferenceHumanLanguage';
import { Commission } from '../../../representations/Commission';

interface Props {
  dateFormatter: (date: Date) => string;
  commission: Commission
}

export default function CommissionScheduleComponent({
  dateFormatter,
  commission,
}:Props) {
  const { commissionSchedule } = commission;

  const rows: LeftRightColumnRow[] = [];
  if (commissionSchedule && commissionSchedule.startDate) {
    rows.push({
      key: 'start',
      left: 'Scheduled Start',
      right: (
        <BoxFlexRow>
          <Typography>{commissionSchedule && commissionSchedule.startDate ? dateFormatter(commissionSchedule.startDate) : 'None Set'}</Typography>
        </BoxFlexRow>
      ),
    });
  }
  rows.push({
    key: 'due',
    left: 'Date due',
    right: (
      <BoxFlexRow>
        <Typography>{commissionSchedule ? dateFormatter(commissionSchedule.endDate) : 'None Set'}</Typography>
        <SchedulerCalendarDialogEditorButton
          commission={commission}
        />
      </BoxFlexRow>
    ),
  });
  if (commissionSchedule) {
    rows.push({
      key: 'remaining',
      left: 'Remaining',
      right: (
        <Typography>{DateDifferenceHumanLanguage(new Date(), commissionSchedule.endDate)}</Typography>
      ),
    });
  }

  return (
    <TitlePaper title="Schedule">
      <BoxFlexColumn>
        <LeftRightColumnComponent
          rows={rows}
          breakpoint="xl"
          leftWidthPercentage={25}
        />
      </BoxFlexColumn>
    </TitlePaper>
  );
}
