import React, {
  createContext, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import * as AuthApi from '../../../../api/auth/AuthApi';
import * as UserApi from '../../../../api/UserApi';
import ApiError, {
  ERROR_GET_USER, ERROR_NETWORK_ERROR, ERROR_UNAUTHORISED,
} from '../../../../api/ApiError';
import { User } from '../../../../representations/User';
import { UserContextObject } from './UserContextObject';
import { UserSettings } from '../../../../representations/UserSettings';

const DEFAULT_DISPLAY_DATE_FORMAT = 'LLL';

type Props = {
  children: React.ReactNode
};

export const UserContext = createContext<UserContextObject | undefined>(undefined);

function UserProvider({ children }: Props) {
  const [isFirstLoaded, setIsFirstLoaded] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [userObject, setUserObject] = React.useState<User | undefined>(undefined);
  const [settings, setSettings] = React.useState<UserSettings | undefined>(undefined);
  const [dateDisplayFormat, setDateDisplayFormat] = React.useState<string>(DEFAULT_DISPLAY_DATE_FORMAT);
  const navigate = useNavigate();

  const loadUser = async () => {
    try {
      const user = await AuthApi.getCookieUser();
      setUserObject(user);

      const userSettings = await UserApi.getUserSettings();
      setSettings(userSettings);

      if (!user.dateDisplayFormat) {
        setDateDisplayFormat(user.dateDisplayFormat);
      }

      // The user is now loaded.
      setIsFirstLoaded(true);
    } catch (e) {
      if (e instanceof ApiError) {
        switch (e.code) {
          case ERROR_GET_USER:
            console.error('Error getting user that should exist');
            navigate('/login');
            break;
          case ERROR_UNAUTHORISED:
            navigate('/login');
            break;
          case ERROR_NETWORK_ERROR:
            navigate('/server-offline');
            toast.error('Not connected to server');
            break;
          default:
            toast.error('Unexpected error');
            console.error('Unhandled ApiError', e);
        }
      } else {
        toast.error('Unexpected error occurred when getting user object');
        console.error('Unexpected error occurred when getting user object', e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUser().then(() => {});
  }, []);

  const updateUserSettings = async (newSettings: UserSettings) => {
    try {
      setIsLoading(true);
      await UserApi.updateUserSettings(newSettings);
      await loadUser(); // Reload user and user settings
    } finally {
      setIsLoading(false);
    }
  };

  const reloadUser = async () => {
    try {
      setIsLoading(true);
      await loadUser(); // Reload user and user settings
    } finally {
      setIsLoading(false);
    }
  };

  const editRequestUrl = async (url: string) => {
    try {
      setIsLoading(true);
      await UserApi.editRequestUrl(url);
      await loadUser(); // Reload user and user settings
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date: Date) => Moment(date).format(dateDisplayFormat);

  // Change only on isLoading status changes
  const memoUser = React.useMemo(
    ():UserContextObject => ({
      isFirstLoaded,
      isLoading,
      user: userObject,
      settings,
      updateUserSettings,
      editRequestUrl,
      reloadUser,
      formatDate,
    }),
    [isLoading],
  );

  return (
    <UserContext.Provider value={memoUser}>
      {children}
    </UserContext.Provider>
  );
}

const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('userContext must be used within a UserProvider');
  }
  return context;
};

export {
  UserProvider, useUserContext,
};
