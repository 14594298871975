import { Theme } from '@mui/material/styles/createTheme';

export const PAPER_PADDING_SPACING = 3;

export default function Paper(theme:Theme) {
  return {
    MuiPaper: {
      defaultProps: { elevation: 4 },

      styleOverrides: {
        root: {
          backgroundImage: 'none', padding: theme.spacing(PAPER_PADDING_SPACING),
        },
      },
    },
  };
}
