import React, {
  createContext, useEffect,
} from 'react';
import * as StatusApi from '../../../../api/CommissionStatusApi';
import StatusContextObject from './CommissionStatusContextObject';
import { CommissionStatus } from '../../../../representations/CommissionStatus';

type Props = {
  children: React.ReactNode
};

export const CommissionStatusContext = createContext<StatusContextObject | undefined>(undefined);

function CommissionStatusProvider({ children }: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [shouldReloadData, setShouldReloadData] = React.useState<boolean>(true);
  const [statuses, setStatuses] = React.useState<CommissionStatus[]>([]);

  useEffect(() => {
    if (!shouldReloadData) {
      return;
    }
    setShouldReloadData(false);

    const getCommissionStatuses = async () => {
      const loaded = await StatusApi.getUserDefinedStatuses();
      setStatuses(loaded);
      setIsLoading(false);

      console.log(`Loaded [${loaded.length}] statuses`);
    };

    getCommissionStatuses().then(() => {
    });
  }, [shouldReloadData]);

  // Change only on isLoading status changes
  const memoStatus = React.useMemo(
    () => new StatusContextObject(
      isLoading,
      statuses,
    ),
    [isLoading],
  );

  return (
    <CommissionStatusContext.Provider value={memoStatus}>
      {children}
    </CommissionStatusContext.Provider>
  );
}

const useCommissionStatusContext = () => {
  const context = React.useContext(CommissionStatusContext);
  if (context === undefined) {
    throw new Error('useStatus must be used within a StatusProvider');
  }
  return context;
};

export {
  CommissionStatusProvider,
  useCommissionStatusContext,
};
