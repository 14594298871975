import { QuestionOption } from './QuestionOption';
import { UploadedFile } from './UploadedFile';

interface BaseRequestFormAnswer {
  title: string;
  type: 'number' | 'text' | 'thumbnail' | 'checkbox' | 'option' | 'paragraph' | 'reference-images' | 'description';
  paragraph?: string | PossiblyJSONContent;
  options? : QuestionOption[];
  isOptional: boolean;
  value: string | string [] | PossiblyJSONContent | null;
}

export interface RequestFormAnswer extends BaseRequestFormAnswer {
  uploadedFileKeys: string[];
}

/**
 * With object and instructions on how to get them (i.e. UploadedFile)
 */
export interface RequestFormAnswerWithFiles extends BaseRequestFormAnswer {
  uploadedFiles: UploadedFile[];
}

export interface PossiblyJSONContent {
  type?: string,
  content?: PossiblyJSONContent[],
}

/**
 * Gets whether an object *MIGHT* be a JSONContent object (since it only checks for `type` and `content`)
 */
export const instanceOfJSONContent = (object: any): object is PossiblyJSONContent => 'type' in object && 'content' in object;

export const getContentFromAnswerValue = (value: string | string [] | object | null) => {
  if (value !== null) {
    if (typeof value === 'string' || instanceOfJSONContent(value)) {
      return value;
    }
  }
  throw new Error('Answer was null or undefined somehow');
};
