import React from 'react';

type IntervalFunction = () => (unknown | void);

export default function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = React.useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
