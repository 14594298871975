import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleIcon from '@mui/icons-material/People';
import InboxIcon from '@mui/icons-material/Inbox';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LogoutIcon from '@mui/icons-material/Logout';
import { Badge } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { ICON_SIZE } from './SidebarIconSizes';

export interface SidebarItem {
  title: string
  path: string
  icon: React.ReactNode
  info?: string
  children?: any
  confirmationDialog?: SidebarItemConfirmationDialog
}

export interface SidebarItemConfirmationDialog {
  title: string
  confirmationMessage: string
  confirmButtonText: string
}

const iconSx = {
  width: ICON_SIZE,
  height: ICON_SIZE,
};

export interface SidebarArgs {
  unreadRequestsNumber: number
}

export default function getSidebarConfig({ unreadRequestsNumber }:SidebarArgs): SidebarItem[] {
  return [
    {
      title: 'dashboard',
      path: '/app/dashboard',
      icon: <DashboardIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'schedule',
      path: '/app/schedule',
      icon: <EventIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'commission list',
      path: '/app/commissions',
      icon: <FormatListBulletedIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'client management',
      path: '/app/clients',
      icon: <PeopleIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'requests',
      path: '/app/requests/form/edit',
      icon: <LibraryBooksIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'request inbox',
      path: '/app/requests/inbox',
      icon: (
        <Badge badgeContent={unreadRequestsNumber < 1 ? null : unreadRequestsNumber} color="notification">
          <InboxIcon sx={iconSx} color="primary" />
        </Badge>),
    },
    {
      title: 'settings',
      path: '/app/settings',
      icon: <SettingsIcon sx={iconSx} color="primary" />,
    },
    {
      title: 'logout',
      path: '/logout',
      confirmationDialog: {
        title: 'Log out?',
        confirmationMessage: 'Are you sure you want to log out?',
        confirmButtonText: 'Log Out',
      },
      icon: <LogoutIcon sx={iconSx} color="error" />,
    },
    // {
    //   title: 'user',
    //   path: '/dashboard/user',
    //   icon: getIcon(peopleFill),
    // },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: getIcon(shoppingBagFill),
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: getIcon(fileTextFill),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill),
    // },
  ];
}
