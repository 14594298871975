import axios, { AxiosError } from 'axios';
import { ViewableArtistBackgroundImage } from 'src/representations/ArtistBackgroundImage';
import { RequestForm } from '../representations/RequestForm';
import ApiError, {
  ERROR_CREATE_FORM, ERROR_GET_FORM, ERROR_GET_REQUEST_FORM_SUBMISSION,
} from './ApiError';
import { RequestFormClientSubmissionWritable } from '../representations/RequestFormClientSubmissionWritable';
import { RequestFormClientSubmissionWithFiles } from '../representations/RequestFormClientSubmission';
import { UserPublic } from '../representations/User';
import { SERVER_URL } from './SharedApiStuff';

export type GetPublicFormResponse = {
  requestForm: RequestForm,
};

export async function getPublicForm(artistCustomLink: string): Promise<RequestForm | null> {
  try {
    const url = `${SERVER_URL}/api/v1/public/request/form/${artistCustomLink}`;
    const response = await axios.get(url, { });

    return response.data.requestForm;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      if (error.response?.status === 404) {
        return null;
      }
    }

    throw new ApiError('Error getting form', ERROR_GET_FORM, e);
  }
}

export type PostPublicFormSubmissionRequest = {
  artistUserUuid: string,
  requestFormClientSubmission: RequestFormClientSubmissionWritable,
};

export type PostPublicFormSubmissionResponse = {
  submissionUuid: string,
};

export async function submitNewRequest(
  artistUserUuid: string,
  requestFormClientSubmission: RequestFormClientSubmissionWritable,
) {
  try {
    const url = `${SERVER_URL}/api/v1/public/request/form/`;
    const data = {
      artistUserUuid,
      requestFormClientSubmission,
    } as PostPublicFormSubmissionRequest;
    const response = await axios.post(url, data, { });
    const lol = response.data as PostPublicFormSubmissionResponse;
    return lol.submissionUuid;
  } catch (e) {
    throw new ApiError('Error creating form', ERROR_CREATE_FORM, e);
  }
}

export type GetRequestFormSubmissionResponse = {
  requestFormSubmission : RequestFormClientSubmissionWithFiles,
};

export async function getFormSubmission(submissionUuid: string, userContextUuid?: string): Promise<RequestFormClientSubmissionWithFiles> {
  try {
    const url = `${SERVER_URL}/api/v1/public/request/form/submission/${submissionUuid}`;
    const response = await axios.get(url, { params: { userContextUuid } });
    return (response.data as GetRequestFormSubmissionResponse).requestFormSubmission;
  } catch (e) {
    throw new ApiError('Error getting request submission', ERROR_GET_REQUEST_FORM_SUBMISSION, e);
  }
}

export interface GetPublicArtistPageItemsResponse {
  artistUser: UserPublic
  backgroundImages: ViewableArtistBackgroundImage[]
}

export async function getPublicArtistPageItems(artistUserUrl:string):Promise<GetPublicArtistPageItemsResponse> {
  try {
    const url = `${SERVER_URL}/api/v1/public/artist/url/${artistUserUrl}`;

    return (await axios.get(url, { withCredentials: true })).data;
  } catch (e) {
    throw new ApiError('Error getting artist page items', 'ERROR_GET_ARTIST_PAGE_ITEMS', e);
  }
}

export async function getPublicArtistPageItemsByUserUuid(artistUserUuid:string):Promise<GetPublicArtistPageItemsResponse> {
  try {
    const url = `${SERVER_URL}/api/v1/public/artist/user-uuid/${artistUserUuid}`;

    return (await axios.get(url, { withCredentials: true })).data;
  } catch (e) {
    throw new ApiError('Error getting artist page items', 'ERROR_GET_ARTIST_PAGE_ITEMS', e);
  }
}
