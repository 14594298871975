import {
  Backdrop, Box, IconButton, ImageListItem, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { UploadedFile } from '../../../representations/UploadedFile';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import 'react-image-gallery/styles/css/image-gallery.css';
import FileUploadComponent, {
  FileWithPreview, UploadProgress,
} from '../FileUploadComponent';
import CenteredPlusIconAndAddImagesComponent from '../CenteredPlusIconAndAddImagesComponent';
import {
  getUploadProgressArray, uploadAndGetKeys,
} from '../../UploadFunctionComponents';
import * as CommissionApi from '../../../api/CommissionApi';
import ConfirmationDialog from '../ConfirmationDialog';
import {
  instanceOfViewableCommissionAttachment,
  ViewableCommissionAttachment,
} from '../../../representations/CommissionAttachment';
import DeferredImage from '../../DeferredImage';

const CloseViewerIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  margin:15px;
  height: 7%;
  width: 7%;
  z-index: 10000;
`;

const IMAGE_ICON_HEIGHT = 150;

export interface ImageListViewerUploadOptions {
  maxFileSizeBytes: number
  artistUserUuid: string
  commissionUuid: string
  previewImageObjectKey?: string
  onFilesUpdated: () => void
  onPreviewImageUpdated?: (attachment: ViewableCommissionAttachment)=>void
}

interface Props {
  files: UploadedFile[] | ViewableCommissionAttachment[]
  uploadableOptions?: ImageListViewerUploadOptions
  parentFiles?: File[]
}

export default function ImageListAndViewerComponent({
  files,
  uploadableOptions,
  parentFiles,
}:Props) {
  const [isViewerOpen, setIsViewerOpen] = React.useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgress[]>([]);
  const [reloadUploadBoxFilesCounter, setReloadUploadBoxFilesCounter] = React.useState<number>(0);
  const [isConfirmDeleteReferenceImageOpen, setIsConfirmDeleteReferenceImageOpen] = React.useState<boolean>(false);
  const [isDeletingReferenceImage, setIsDeletingReferenceImage] = React.useState<boolean>(false);
  const [currentlyDeletingReferenceImage, setCurrentlyDeletingReferenceImage] = React.useState<ViewableCommissionAttachment | undefined>(undefined);

  const openImageViewer = (index: number) => {
    setCurrentImageIndex(index);
    setIsViewerOpen(true);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsViewerOpen(false);
    }
  };

  const onProgressUpdated = (fileName:string, current:number, total:number) => {
    setUploadProgress((previousValues) => getUploadProgressArray(fileName, current, total, previousValues));
  };

  const uploadFiles = async (newFiles: FileWithPreview[]) => {
    if (!uploadableOptions) {
      throw new Error('Cannot access uploadFiles function if uploadableOptions is not provided');
    }

    try {
      // Set 0% for all progress immediately for nicer UX
      const newUploadProgress: UploadProgress[] = newFiles.map((nf) => ({
        fileName: nf.name, current: 0, total: 100,
      }));
      setUploadProgress(newUploadProgress);

      const keys = await uploadAndGetKeys(uploadableOptions.artistUserUuid, newFiles, 'commission', onProgressUpdated);
      await CommissionApi.addCommissionReferenceImages(uploadableOptions.commissionUuid, keys);
      uploadableOptions.onFilesUpdated();
      setReloadUploadBoxFilesCounter(reloadUploadBoxFilesCounter + 1);
    } catch (e) {
      toast.error('Failed to upload additional files');
      setUploadProgress([]);
      throw e;
    }
  };

  const deleteReferenceImage = async () => {
    if (!uploadableOptions) {
      throw new Error('Cannot access deleteReferenceImage function if uploadableOptions is not provided');
    }

    if (!currentlyDeletingReferenceImage) {
      throw new Error('currentlyDeletingReferenceImage is undefined');
    }

    try {
      setIsDeletingReferenceImage(true);

      await CommissionApi.deleteCommissionReferenceImages(currentlyDeletingReferenceImage);
      toast.info('Deleted reference image');
      setIsConfirmDeleteReferenceImageOpen(false);
      setCurrentlyDeletingReferenceImage(undefined);
      uploadableOptions.onFilesUpdated();
    } catch (e) {
      toast.error('Failed to delete reference image');
      console.error(e);
    } finally {
      setIsDeletingReferenceImage(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box>
      <ConfirmationDialog
        open={isConfirmDeleteReferenceImageOpen}
        onConfirmedClicked={deleteReferenceImage}
        title="Delete file"
        confirmationMessage="Are you sure you want to delete this file?"
        onClosedOrCancelClicked={() => {
          setCurrentlyDeletingReferenceImage(undefined);
          setIsConfirmDeleteReferenceImageOpen(false);
        }}
        isSubmittingConfirmation={isDeletingReferenceImage}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isViewerOpen}
      >
        <CloseViewerIcon
          onClick={() => {
            setIsViewerOpen(false);
          }}
        />
        <ImageGallery
          items={files.map((uf) => {
            if (instanceOfViewableCommissionAttachment(uf)) {
              return {
                original: uf.uploadedFile.url,
                thumbnail: uf.uploadedFile.url,
              };
            }

            return {
              original: uf.url,
              thumbnail: uf.url,
            };
          })}
          startIndex={currentImageIndex}
        />
      </Backdrop>
      <BoxFlexRow>
        <BoxFlexRow
          sx={{ height: IMAGE_ICON_HEIGHT }}
        >
          {files.map((f, index) => {
            const theFile = instanceOfViewableCommissionAttachment(f) ? f.uploadedFile : f;

            const isActivePreviewImage = uploadableOptions
                && instanceOfViewableCommissionAttachment(f)
                && uploadableOptions.previewImageObjectKey === f.objectKey;

            return (
              <ImageListItem
                sx={{
                  justifyContent: 'center',
                  position: 'relative',
                  width: IMAGE_ICON_HEIGHT,
                  '&:hover': { cursor: 'pointer' },
                }}
                key={theFile.originalFileName}
                onClick={() => {
                  openImageViewer(index);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    zIndex: 150,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {isActivePreviewImage && (
                    <IconButton>
                      <StarIcon
                        color="warning"
                        width={22}
                        height={22}
                      />
                    </IconButton>
                  )}
                  {(!isActivePreviewImage && uploadableOptions && instanceOfViewableCommissionAttachment(f)) && (
                    <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (uploadableOptions?.onPreviewImageUpdated) {
                        uploadableOptions.onPreviewImageUpdated(f);
                      }
                      event.stopPropagation();
                    }}
                    >
                      <StarBorderIcon
                        width={22}
                        height={22}
                      />
                    </IconButton>
                  )}
                  {(uploadableOptions && instanceOfViewableCommissionAttachment(f)) && f.isDeletable && (
                    <IconButton
                      onClick={((event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        setCurrentlyDeletingReferenceImage(f);
                        setIsConfirmDeleteReferenceImageOpen(true);
                      })}
                    >
                      <DeleteOutlineIcon
                        width={22}
                        height={22}
                      />
                    </IconButton>
                  )}
                </Box>
                <DeferredImage
                  src={`${theFile.url}`}
                  alt={f.originalFileName}
                />
              </ImageListItem>
            );
          })}
          {(!uploadableOptions && files.length === 0) && (
            <Typography>No Images (TODO make me look nicer)</Typography>
          )}
          {(uploadableOptions && uploadableOptions.maxFileSizeBytes) && (
          <FileUploadComponent
            maxFileBytes={uploadableOptions.maxFileSizeBytes}
            uploadProgress={uploadProgress}
            onFilesChanged={uploadFiles}
            shouldDeleteFileCounter={reloadUploadBoxFilesCounter}
            parentFiles={parentFiles}
          >
            <CenteredPlusIconAndAddImagesComponent />
          </FileUploadComponent>
          )}
        </BoxFlexRow>
      </BoxFlexRow>
    </Box>
  );
}
