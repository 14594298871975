import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Link as RouterLink, useNavigate,
} from 'react-router-dom';
import {
  Box, Button, IconButton, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions,
} from 'mui-datatables';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Page from '../../components/Page';
import { useCommissionStatusContext } from '../../components/myoc-components/context/status-context/CommissionStatusContext';
import { useClientContext } from '../../components/myoc-components/context/client-context/ClientContext';
import { useCommissionContext } from '../../components/myoc-components/context/commission-context/CommissionContext';
import { useUserContext } from '../../components/myoc-components/context/user-context/UserContext';
import LoadingOverlay from '../../components/myoc-components/LoadingOverlay';
import BoxFlexColumn from '../../components/myoc-components/styled-containers/BoxFlexColumn';

export default function CommissionList() {
  const navigate = useNavigate();
  const commissionStatusContext = useCommissionStatusContext();
  const clientContext = useClientContext();
  const commissionContext = useCommissionContext();
  const userContext = useUserContext();

  const [selectedMode, setSelectedMode] = React.useState<'active' | 'archived'>('active');
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const currentListCommissions = selectedMode === 'active'
    ? commissionContext.commissions
    : commissionContext.archivedCommissions;
  const isLoading = commissionContext.isLoading
      || clientContext.isLoading
      || commissionStatusContext.isLoading
      || userContext.isLoading;

  const onAddClicked = () => {
    navigate('/app/commissions/add');
  };

  const getRenderedLabel = (value:string, tableMeta: MUIDataTableMeta) => {
    const uuid = tableMeta.rowData[0];
    const linkToDetails = `/app/commissions/details/${uuid}`;

    return (
      <RouterLink to={linkToDetails}>
        {value}
      </RouterLink>
    );
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'uuid',
      label: 'uuid',
      options: { display: 'excluded' },
    },
    {
      name: 'label',
      label: 'Label',
      options: {
        customBodyRender: (
          value: string,
          tableMeta: MUIDataTableMeta,
        ) => getRenderedLabel(value, tableMeta),
      },
    },
    {
      name: 'clientName',
      label: 'Client Name',
    },
    {
      name: 'clientUuid',
      label: 'clientUuid',
      options: { display: 'excluded' },
    },
    {
      name: 'dateCreated',
      label: 'dateCreated',
      options: { display: 'excluded' },
    },
    {
      name: 'dateUpdated',
      label: 'Date Updated',
      options: { customBodyRender: (value) => userContext.formatDate(value) },
    },
  ];

  const getCustomToolbar = (
    selectedRowsz: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } },
  ) => {
    const dataIndexesSelected = selectedRowsz.data.map((r) => r.dataIndex);
    const commissionUuidsSelected = dataIndexesSelected.map((r) => currentListCommissions[r].commissionUuid);

    return (
      <IconButton
        onClick={() => {
          // Archive all currently selected commissions and reload
          if (selectedMode === 'active') {
            commissionContext.archiveCommissions(commissionUuidsSelected);
          } else {
            commissionContext.unarchiveCommissions(commissionUuidsSelected);
          }
        }}
      >
        {selectedMode === 'active'
          ? <DeleteIcon />
          : <RestoreFromTrashIcon />}
      </IconButton>
    );
  };

  const options: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRows: 'multiple',
    selectableRowsOnClick: false, // For checkbox selectable only
    selectToolbarPlacement: 'above', // This can be changed to never
    customToolbarSelect: getCustomToolbar,
    // customToolbar: () => 'lmao',
    rowsSelected: selectedRows,
    download: false,
    print: false,
    filter: false,
    search: true,
    searchPlaceholder: 'Search...',
    searchOpen: true,
    searchAlwaysOpen: true,
    viewColumns: false,
    customFooter: () => null,
    elevation: 0,
    // onRowClick,
    textLabels: { body: { noMatch: <Typography>No commissions</Typography> } },
  };

  interface MappedListCommission {
    uuid: string
    label: string
    clientUuid: string
    clientName: string
    dateCreated: Date
    dateUpdated: Date
  }

  const mappedCommissions: MappedListCommission[] = currentListCommissions.map((commission) => {
    const m: MappedListCommission = {
      uuid: commission.commissionUuid,
      label: commission.label,
      clientUuid: commission.clientUuid,
      clientName: clientContext.getClientByUuid(commission.clientUuid).name,
      dateCreated: commission.dateCreated,
      dateUpdated: commission.dateUpdated,
    };
    return m;
  });

  return (
    <Page title="Commissions">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h1">
          Commissions
        </Typography>
        <Box>
          {selectedMode === 'active'
            && (
              <Button
                variant="contained"
                onClick={onAddClicked}
                startIcon={<Icon icon={plusFill} />}
              >
                Track New Commission
              </Button>
            )}
        </Box>
      </Stack>

      <Paper>
        <BoxFlexColumn>
          <ToggleButtonGroup
            color="primary"
            value={selectedMode}
            exclusive
            onChange={(event, value) => {
              setSelectedMode(value);
              setSelectedRows([]); // Maybe not necessary
            }}
          >
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="archived">Archived</ToggleButton>
          </ToggleButtonGroup>
          <LoadingOverlay active={isLoading} text="loading commissions...">
            <MUIDataTable
              title=""
              columns={columns}
              data={mappedCommissions}
              options={options}
            />
          </LoadingOverlay>
        </BoxFlexColumn>
      </Paper>
    </Page>
  );
}
