import {
  MenuItem, Paper, Select, SelectChangeEvent, Typography,
} from '@mui/material';
import React from 'react';
import { Commission } from '../../../representations/Commission';
import CommissionStatusContextObject from '../context/status-context/CommissionStatusContextObject';
import { useCommissionContext } from '../context/commission-context/CommissionContext';
import { CommissionStatusHistory } from '../../../representations/CommissionStatusHistory';
import TitlePaper from '../styled-containers/TitlePaper';

type Props = {
  commission: Commission,
  statusContext: CommissionStatusContextObject,
  dateFormatter: (date: Date)=>string;
};

function CommissionStatusDetails({
  commission,
  statusContext,
  dateFormatter,
}:Props) {
  const commissionContext = useCommissionContext();

  const currentStatus = statusContext.getCommissionStatusByUuid(commission.statusUuid);
  const [statusUuid, setStatusUuid] = React.useState(currentStatus.statusUuid);
  const [statusHistory, setStatusHistory] = React.useState<CommissionStatusHistory[] | undefined>(undefined);

  const loadStatusHistoriesIntoState = async () => {
    setStatusHistory(await commissionContext.getCommissionStatusHistory(commission.commissionUuid));
  };

  React.useEffect(() => {
    loadStatusHistoriesIntoState().then(() => {});
  }, []);

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedStatusUuid = event.target.value as string;
    setStatusUuid(selectedStatusUuid);
    await commissionContext.modifyCommissionStatus(commission.commissionUuid, selectedStatusUuid);
    await loadStatusHistoriesIntoState();
  };

  return (
    <TitlePaper title="Status" color="grey">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={statusUuid}
        label="Age"
        onChange={handleChange}
      >
        {statusContext.commissionStatuses.map((s) => (
          <MenuItem
            key={s.statusUuid}
            value={s.statusUuid}
          >
            {s.label}
          </MenuItem>
        ))}
      </Select>
      {
          statusHistory !== undefined && statusHistory.map((s) => {
            const previousStatusName = statusContext.getCommissionStatusByUuid(s.previousStatusUuid).label;
            const newStatusName = statusContext.getCommissionStatusByUuid(s.newStatusUuid).label;
            return (
              <Typography
                key={s.statusHistoryUuid}
                variant="body1"
              >
                {`${previousStatusName} to ${newStatusName} at ${dateFormatter(s.date)}`}
              </Typography>
            );
          })
      }
    </TitlePaper>
  );
}

export default CommissionStatusDetails;
