import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import {
  DatesSetArg, EventApi, EventClickArg, EventDropArg,
} from '@fullcalendar/core';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import {
  Box, Button, IconButton, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FullcalendarEventObject } from './FullcalendarEventObject';
import { Commission } from '../../../representations/Commission';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import BoxFlexRow from '../styled-containers/BoxFlexRow';

interface Props {
  commissions: Commission[]
  onEventClicked: (element: HTMLElement, eventId: string) => void
  onDateChanged: (startDate: Date, endDate: Date) => void
  onCommissionDateChanged: (commissionUuid: string, commissionDueDate: Date, commissionStartDate?: Date) => void
  onHoveredCommissionChanged: (commissionUuid: string | null) => void
  externallyHoveredCommissionUuid: string | null
}

export default function CalendarComponent({
  commissions,
  onEventClicked,
  onDateChanged,
  onCommissionDateChanged,
  onHoveredCommissionChanged,
  externallyHoveredCommissionUuid, // TODO use this
}:Props) {
  const calendarRef = React.useRef<FullCalendar>(null);
  const [currentView, setCurrentView] = React.useState<'dayGridMonth' | 'multiMonthTwoMonth'>('dayGridMonth');
  const [calendarTitle, setCalendarTitle] = React.useState<string>('Calendar');

  const onDateChange = async (dateInfo: DatesSetArg) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalendarTitle(calendarApi.view.title);
    }

    onDateChanged(dateInfo.start, dateInfo.end);
  };

  const onMouseClick = (info: EventClickArg) => {
    onEventClicked(info.el, info.event.id);
  };

  /**
   * Handles when a commission is dropped onto the calendar from the CalendarList
   */
  const onEventReceive = (arg: EventReceiveArg) => {
    arg.revert(); // We will modify the event ourselves

    const commissionUuid = arg.event.id;
    const dueDate = arg.event.start;
    const commissionStartDate = arg.event.start;
    const commissionEndDate = arg.event.end;

    if (!dueDate) {
      throw new Error('Start date for calendar drop event was null');
    }

    if (!commissionEndDate || !commissionStartDate) {
      // console.log('dropped non range from list');
      onCommissionDateChanged(commissionUuid, dueDate);
    } else {
      // console.log('dropped range from list');
      onCommissionDateChanged(commissionUuid, commissionEndDate, commissionStartDate);
    }
  };

  /**
   * Handles when a commission is dropped from the same calendar
   */
  const onEventDrop = (arg: EventDropArg) => {
    arg.revert(); // We will modify the event ourselves

    const commissionUuid = arg.event.id;
    const dueDate = arg.event.start;
    const commissionStartDate = arg.event.start;
    const commissionEndDate = arg.event.end;

    if (!dueDate) {
      throw new Error('Start date for calendar move event was null');
    }
    if (!commissionEndDate || !commissionStartDate) {
      // console.log('dropped non range');
      onCommissionDateChanged(commissionUuid, dueDate);
    } else {
      // console.log('dropped range');
      onCommissionDateChanged(commissionUuid, commissionEndDate, commissionStartDate);
    }
  };

  const events: FullcalendarEventObject[] = commissions
    .filter((t) => t.commissionSchedule)
    .map((t) => {
      const {
        label,
        commissionUuid,
        commissionSchedule,
      } = t;

      if (!commissionSchedule) {
        throw new Error("Filter didn't work");
      }

      // Note: Do not use colors here! We need them set via css above.
      if (commissionSchedule.startDate) {
        return {
          id: commissionUuid,
          title: label,
          start: commissionSchedule.startDate,
          end: commissionSchedule.endDate,
          display: 'block',
          classNames: ['date-range'],
        };
      }

      return {
        id: commissionUuid,
        title: label,
        start: commissionSchedule.endDate,
        display: 'list-item',
        classNames: ['date-point'],
      };
    });

  React.useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(currentView);
    }
  }, [calendarRef.current, currentView]);

  return (
    <Paper
      sx={{ height: '100%' }}
    >
      <BoxFlexColumn sx={{ height: '100%' }}>
        <BoxFlexRow
          id="toolbar"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <BoxFlexRow
            id="toolbar-left-side"
            sx={{ alignItems: 'center' }}
          >
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                if (calendarRef.current) {
                  calendarRef.current.getApi().today();
                }
              }}
            >
              Today
            </Button>
            <Stack id="navigate-calendar-left-right" direction="row">
              <IconButton
                title="Previous month"
                aria-label="navigate-calendar-previous-date-range"
                onClick={() => {
                  if (calendarRef.current) {
                    calendarRef.current.getApi().prev();
                  }
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                title="Next month"
                aria-label="navigate-calendar-next-date-range"
                onClick={() => {
                  if (calendarRef.current) {
                    calendarRef.current.getApi().next();
                  }
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            </Stack>
            <Typography
              variant="h3"
              id="calendar-current-date-range"
            >
              {calendarTitle}
            </Typography>
          </BoxFlexRow>
          <Box id="toolbar-right-side">
            <ToggleButtonGroup
              sx={{ height: '100%' }}
              exclusive
              size="small"
              onChange={(event, value) => {
                if (value !== null) {
                  setCurrentView(value);
                }
              }}
              value={currentView}
              color="primary"
            >
              <ToggleButton value="dayGridMonth">Month</ToggleButton>
              <ToggleButton value="multiMonthTwoMonth">Multi-Month</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </BoxFlexRow>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            // Point event (selecting .fc-daygrid-event-dot which has parents date-point)
            '.date-point > .fc-daygrid-event-dot': {
              // borderColor colors the dot
              borderColor: (theme) => theme.palette.primary.main,
            },
            // Hovering of single dot event
            '.date-point.is-hovered': {
              backgroundColor: (theme) => theme.palette.action.hover,
              color: (theme) => theme.palette.getContrastText(theme.palette.action.hover),
            },
            // Hovering of block event (selecting .fc-event-main which has parents is-hovered and date-range)
            '.date-range.is-hovered > .fc-event-main': {
              borderColor: (theme) => theme.palette.primary.dark,
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: (theme) => theme.palette.getContrastText(theme.palette.primary.dark),
            },
            // Block event (selecting .fc-event-main which has parents date-range)
            '.date-range > .fc-event-main': {
              borderColor: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.getContrastText(theme.palette.primary.main),
            },
          }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, multiMonthPlugin]}
            initialView="multiMonthTwoMonth"
            weekends
            events={events}
            views={{
              dayGridMonth: { type: 'dayGridMonth' },
              multiMonthTwoMonth: {
                type: 'multiMonth',
                duration: { months: 2 },
                dateIncrement: { months: 1 },
              },
            }}
            displayEventTime={false}
            datesSet={onDateChange}
            eventClick={onMouseClick}
            ref={calendarRef}
            droppable
            editable
            eventReceive={onEventReceive}
            eventDrop={onEventDrop}
            height="100%"
            headerToolbar={false}
            eventMouseEnter={(info: { event: EventApi }) => {
              onHoveredCommissionChanged(info.event.id);
            }}
            eventMouseLeave={() => {
              onHoveredCommissionChanged(null);
            }}
            eventClassNames={(arg: { event: EventApi }) => (
              arg.event.id === externallyHoveredCommissionUuid
                ? 'is-hovered'
                : '')}
          />
        </Box>
      </BoxFlexColumn>
    </Paper>
  );
}
