import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  ArtistUserProvider,
  useArtistUserContext,
} from '../components/myoc-components/context/request-background-context/ArtistUserProvider';
import DeferredImage from '../components/DeferredImage';
import { BackgroundImageBox } from './ArtBackgroundLayout';
import PublicTopBar from '../components/myoc-components/PublicTopBar';
import { TOP_APP_BAR_HEIGHT } from '../components/myoc-components/TopAppBar';

function BackgroundImageComponent() {
  const artistUserContext = useArtistUserContext();

  if (!artistUserContext.isLoadingComplete) {
    return null;
  }

  if (artistUserContext.backgroundImages.length === 0) {
    return (
      <Box sx={{
        filter: 'brightness(65%)',
        width: '100%',
        height: '100%',
      }}
      >
        <DeferredImage src="/static/background-images/background.png" alt="background" />
      </Box>
    );
  }

  // TODO carousel / slideshow stuff
  return (<DeferredImage src="/static/background-images/background.png" alt="background" />);
}

export default function ArtistPublicLayout() {
  return (
    <ArtistUserProvider>
      <BackgroundImageBox>
        <BackgroundImageComponent />
      </BackgroundImageBox>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
      >
        <PublicTopBar />
        <Box id="outlet-box" sx={{ height: `calc(100vh - ${TOP_APP_BAR_HEIGHT}px)` }}>
          <Outlet />
        </Box>
      </Box>
    </ArtistUserProvider>
  );
}
