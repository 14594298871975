import axios from 'axios';
import { SERVER_URL } from './SharedApiStuff';
import {
  ModifiedTask, NewTask, Task, TaskStatus,
} from '../representations/Task';
import ApiError from './ApiError';

interface GetCommissionTasksResponse {
  tasks: Task[]
}

export async function getCommissionTasks(commissionUuid: string): Promise<Task[]> {
  try {
    const response: GetCommissionTasksResponse = (await axios.get(
      `${SERVER_URL}/api/v1/commission/tasks/${commissionUuid}`,
      { withCredentials: true },
    )).data;

    return response.tasks;
  } catch (e) {
    throw new ApiError('Error getting commission tasks', 'ERROR_GET_COMMISSION_TASKS', e);
  }
}

interface PostTaskRequest {
  newTask: NewTask
}

export async function addTask(newTask: NewTask): Promise<void> {
  try {
    const data: PostTaskRequest = { newTask };

    await axios.post(
      `${SERVER_URL}/api/v1/task`,
      data,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error adding new task', 'ERROR_ADD_TASK', e);
  }
}

interface PutTaskRequest {
  task: ModifiedTask
}

export async function modifyTask(taskUuid: string, task: ModifiedTask): Promise<void> {
  try {
    const data: PutTaskRequest = { task };

    await axios.put(
      `${SERVER_URL}/api/v1/task/${taskUuid}`,
      data,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error modifying task', 'ERROR_MODIFY_TASK', e);
  }
}

export async function deleteTask(uuid: string) {
  try {
    await axios.delete(
      `${SERVER_URL}/api/v1/task/${uuid}`,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error deleting task', 'ERROR_DELETE_TASK', e);
  }
}

interface PutTaskStatusRequest {
  status: TaskStatus
}

export async function setTaskStatus(uuid: string, status: TaskStatus): Promise<void> {
  try {
    const data: PutTaskStatusRequest = { status };

    await axios.put(
      `${SERVER_URL}/api/v1/task/${uuid}/status`,
      data,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error setting task status', 'ERROR_SET_TASK_STATUS', e);
  }
}
