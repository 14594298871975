import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const BoxFlexColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(2),
}));

export default BoxFlexColumn;
