import {
  Box,
  Paper, Typography,
} from '@mui/material';
import React from 'react';
import {
  styled, useTheme,
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { PAPER_PADDING_SPACING } from '../../../theme/overrides/Paper';

const TitleBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'color' })(({
  theme, color,
} : { theme: Theme, color: 'primary' | 'secondary' | 'grey' }) => {
  let paletteColor : PaletteColor;

  switch (color) {
    case 'primary':
      paletteColor = theme.palette.primary;
      break;
    case 'secondary':
      paletteColor = theme.palette.secondary;
      break;
    case 'grey':
      paletteColor = {
        light: theme.palette.grey[500],
        main: theme.palette.grey[600],
        dark: theme.palette.grey[700],
        contrastText: 'white',
      };
      break;
    default:
      throw new Error('missing case');
  }
  return ({
    color: paletteColor.contrastText,
    backgroundColor: paletteColor.main,
    padding: theme.spacing(1.5, 3),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  });
});

interface Props {
  title: string
  variant?: 'h1' | 'h2' | 'h3'
  color?: 'primary' | 'secondary' | 'grey'
  children: React.ReactNode
}

export default function TitlePaper({
  title,
  variant = 'h2',
  color = 'primary',
  children,
}:Props) {
  const theme = useTheme();

  return (
    <Paper sx={{ padding: 0 }}>
      <TitleBox theme={theme} color={color}>
        <Typography variant={variant}>
          {title}
        </Typography>
      </TitleBox>
      <Box sx={{ padding: (themeL) => themeL.spacing(PAPER_PADDING_SPACING) }}>
        {children}
      </Box>
    </Paper>
  );
}
