import React, { useEffect } from 'react';
import CreateRequestFormComponent from 'src/components/myoc-components/formbuilder/CreateRequestFormComponent';
import { Typography } from '@mui/material';
import Page from '../../components/Page';
import { RequestForm } from '../../representations/RequestForm';
import { useUserContext } from '../../components/myoc-components/context/user-context/UserContext';
import * as RequestsApi from '../../api/RequestsApi';
import BoxFlexColumn from '../../components/myoc-components/styled-containers/BoxFlexColumn';

function RequestFormEditPage() {
  const [requestForm, setRequestForm] = React.useState<RequestForm | undefined>(undefined);
  const [isLoadingRequestForm, setIsLoadingRequestForm] = React.useState<boolean>(true);

  const userContextObject = useUserContext();

  useEffect(() => {
    const loadRequestForm = async () => {
      const loadedForm = await RequestsApi.getForm();
      setRequestForm(loadedForm);
      setIsLoadingRequestForm(false);
    };

    loadRequestForm().then(() => {});
  }, []);

  if (!userContextObject.isFirstLoaded) {
    return (
      <Page title="Manage Requests">
        <Typography variant="h3">Loading...</Typography>
      </Page>
    );
  }

  if (!userContextObject.user) {
    throw new Error('User is undefined');
  }

  return (
    <Page title="Manage Requests">
      <BoxFlexColumn>
        <Typography variant="h1">Manage Requests</Typography>
        {isLoadingRequestForm
          ? (
            <Typography variant="h3">Loading...</Typography>
          )
          : (
            <CreateRequestFormComponent requestForm={requestForm} userContext={userContextObject} />
          )}
      </BoxFlexColumn>
    </Page>
  );
}
export default RequestFormEditPage;
