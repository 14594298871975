import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button, Divider, Stack, Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import Page from '../components/Page';
import { SERVER_URL } from '../api/SharedApiStuff';
import MyocLogo from './MyocLogo';
import StyledPaper from '../components/myoc-components/StyledPaper';

export default function Login() {
  const onGoogleButtonClicked = async () => {
    window.open(`${SERVER_URL}/auth/google`, '_self');
  };

  const theme = useTheme();

  return (
    <Page
      title="Login"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledPaper>
        <MyocLogo />
        <Typography variant="body1" fontSize="25px" sx={{ color: 'text.secondary' }}>
          Manage Your Own Commissions
        </Typography>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: theme.palette.grey[600],
          }}
          variant="middle"
        />
        <Typography variant="body1" fontSize="19px" fontWeight="400" sx={{ color: 'text.secondary' }}>
          Login or register with account
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="text"
            onClick={onGoogleButtonClicked}
          >
            <Icon icon={googleFill} color="#DF3E30" height={42} />
          </Button>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="text"
          >
            <Icon icon={twitterFill} color="#1C9CEA" height={42} />
          </Button>
        </Stack>
      </StyledPaper>
    </Page>
  );
}
