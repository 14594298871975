import React, {
  ForwardedRef, forwardRef,
} from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box, Container,
} from '@mui/material';
import { SxProps } from '@mui/system';
import {
  styled, Theme,
} from '@mui/material/styles';

type Props = {
  children: React.ReactNode,
  title: string,
  other?: any,
  sx?: SxProps<Theme>,
  paddingTop?: number,
  id?: string
};

const PageBox = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const Page = forwardRef(
  (
    {
      children,
      title,
      sx = {},
      id,
      paddingTop = 100,
      ...other
    } : Props,
    ref: ForwardedRef<unknown>,
  ) => (
    <PageBox
      id={id}
      sx={sx}
      ref={ref}
      {...other}
    >
      <Helmet>
        <title>{`${title} | myoc`}</title>
      </Helmet>
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: `${paddingTop}px`,
          height: '100%',
        }}
        className="page-container"
      >
        {children}
      </Container>
    </PageBox>
  ),
);

export default Page;

/*
 *   flexGrow: 1,
 *   // overflow: 'auto',
 *   minHeight: '100%',
 *   paddingTop: TOP_APP_BAR_HEIGHT,
 *   paddingBottom: theme.spacing(10),
 *   [theme.breakpoints.up('lg')]: {
 *     paddingTop: TOP_APP_BAR_HEIGHT,
 *     paddingLeft: theme.spacing(2),
 *     paddingRight: theme.spacing(2),
 *   },
 */
