import {
  Avatar, Box, Chip, Typography,
} from '@mui/material';
import React from 'react';
import {
  alpha,
  styled, useTheme,
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import CircleIcon from '@mui/icons-material/Circle';
import {
  ACCOUNT_ICON_PADDING,
  ACCOUNT_ICON_SIZE, DRAWER_OPEN_PADDING,
} from './SidebarIconSizes';
import {
  FONT_HEADERS, pxToRem,
} from '../../theme/typography';

const AccountStyle = styled(Box, { shouldForwardProp: (prop) => prop !== 'isSidebarOpen' && prop !== 'openSidebarPadding' })(({
  theme,
  isSidebarOpen,
}: {
  theme: Theme,
  isSidebarOpen: boolean,
}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  borderRadius: theme.shape.borderRadiusSm,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,

  ...(isSidebarOpen && ({
    paddingLeft: DRAWER_OPEN_PADDING,
    marginRight: ((DRAWER_OPEN_PADDING / 3) * 1),
    paddingRight: ((DRAWER_OPEN_PADDING / 3) * 3),

    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    backgroundColor: alpha(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  })),
  ...(!isSidebarOpen && ({ paddingLeft: ACCOUNT_ICON_PADDING })),

  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface Props {
  avatarUrl?: string
  email?: string
  name: string
  requestsOpen: boolean
  isSidebarOpen: boolean
}

export default function SidebarUser({
  avatarUrl,
  email,
  name,
  requestsOpen,
  isSidebarOpen,
} : Props) {
  const theme = useTheme();

  return (
    <AccountStyle isSidebarOpen={isSidebarOpen} theme={theme}>
      <Avatar
        src={avatarUrl}
        alt="account picture"
        sx={{
          width: ACCOUNT_ICON_SIZE, height: ACCOUNT_ICON_SIZE,
        }}
      />
      <Box sx={{ marginLeft: 2 }}>
        <Typography sx={{
          color: 'text.primary',
          fontFamily: FONT_HEADERS,
          fontWeight: 500,
          lineHeight: 1.5,
          fontSize: pxToRem(16),
        }}
        >
          {name}
        </Typography>
        <Chip
          label={requestsOpen ? 'Requests Open' : 'Requests Closed'}
          variant="filled"
          size="small"
          sx={{
            height: 16,
            fontSize: 11,
            backgroundColor: 'white',
            '& .MuiChip-icon': {
              color: (themeL) => (requestsOpen ? themeL.palette.active : themeL.palette.inactive),
              fontSize: 11,
            },
          }}
          icon={<CircleIcon />}
        />
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {email}
        </Typography>
      </Box>
    </AccountStyle>
  );
}
