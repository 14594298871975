import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import {
  createTheme, StyledEngineProvider, ThemeProvider,
} from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

type Props = {
  children: React.ReactNode,
};

function ThemeConfig({ children }: Props) {
  // @ts-expect-error: Cbf changing ThemeOptions stuff
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeConfig.propTypes = { children: PropTypes.node };

export default ThemeConfig;
