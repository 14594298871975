import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import EventIcon from '@mui/icons-material/Event';
import { toast } from 'react-toastify';
import { Commission } from 'src/representations/Commission';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import { useUserContext } from '../context/user-context/UserContext';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import DateDifferenceHumanLanguage from '../../../utils/DateDifferenceHumanLanguage';
import {
  CommissionSchedule, ScheduleWritable,
} from '../../../representations/CommissionSchedule';
import { useCommissionContext } from '../context/commission-context/CommissionContext';

const INITIAL_IS_SINGLE_DATE_SELECT = false;

interface DateRange {
  startDate?: Date
  endDate: Date
}

interface Props {
  // TODO existing Events for user
  commission: Commission
  customButton?: JSX.Element
  onDialogClose?: ()=>void
}

export default function SchedulerCalendarDialogEditorButton({
  commission,
  customButton = (
    <IconButton
      color="primary"
      sx={{
        opacity: 0.4,
        '&:hover': { opacity: 1 },
        padding: 0,
      }}
    >
      <EventIcon />
    </IconButton>
  ),
  onDialogClose = () => {},
}:Props) {
  const commissionContext = useCommissionContext();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>(undefined);
  const [isSingleDateSelect, setIsSingleDateSelect] = React.useState(INITIAL_IS_SINGLE_DATE_SELECT);
  const [initialDateRange, setInitialDateRange] = React.useState<DateRange | undefined>(undefined);

  const calendarRef = React.useRef<FullCalendar>(null);

  const userContext = useUserContext();

  React.useEffect(() => {
    if (calendarRef && isOpen) {
      if (!commission.commissionSchedule) {
        // Completely new
        setIsSingleDateSelect(INITIAL_IS_SINGLE_DATE_SELECT);
        setInitialDateRange(undefined);
        setDateRange(undefined);
        return;
      }

      const commissionInitialDateRange: DateRange = {
        startDate: commission.commissionSchedule.startDate || undefined,
        endDate: commission.commissionSchedule.endDate,
      };

      setInitialDateRange(commissionInitialDateRange);
      setDateRange(commissionInitialDateRange);

      if (!commissionInitialDateRange.startDate) {
        setIsSingleDateSelect(true);
        calendarRef.current?.getApi().select(commissionInitialDateRange.endDate);
      } else {
        setIsSingleDateSelect(false);
        calendarRef.current?.getApi().select(commissionInitialDateRange.startDate, commissionInitialDateRange.endDate);
      }
    }
  }, [calendarRef, commission, isOpen]);

  const saveSchedule = async ({
    startDate, endDate,
  }:{ startDate?: Date, endDate: Date }) => {
    const newSchedule: ScheduleWritable = {
      commissionUuid: commission.commissionUuid,
      startDate: startDate || null,
      endDate,
      existingUuid: commission.commissionSchedule?.uuid,
    };
    try {
      await commissionContext.modifyCommissionSchedule(commission.commissionUuid, newSchedule);
      toast.info('Saved commission schedule');
    } catch (e) {
      toast.error("Couldn't save date");
      throw e;
    }
  };

  const onSaveClicked = async () => {
    if (!dateRange) {
      throw new Error('DateRange shouldnt be undefined');
    }

    try {
      setIsSaving(true);
      await saveSchedule({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
      setIsOpen(false);
      onDialogClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const onCloseOrDiscard = () => {
    setIsOpen(false);
    onDialogClose();
  };

  const rows: LeftRightColumnRow[] = [
    {
      key: 'selectOrSingle',
      left: 'Select Date Range',
      right: (
        <Switch
          checked={!isSingleDateSelect}
          onChange={((event: React.ChangeEvent<HTMLInputElement>) => {
            setIsSingleDateSelect(!event.target.checked);
          })}
        />
      ),
    },
  ];

  if (!isSingleDateSelect) {
    rows.push({
      key: 'start',
      left: 'Start Date',
      right: (
        <TextField
          fullWidth
          size="small"
          type="text"
          value={dateRange?.startDate ? userContext.formatDate(dateRange.startDate) : ''}
          onClick={(e) => {
            e.preventDefault(); // Prevent HTML5 datepicker
          }}
          disabled
        />
      ),
    });
  }

  rows.push({
    key: 'end',
    left: 'End Date',
    right: (
      <TextField
        fullWidth
        size="small"
        type="text"
        value={dateRange?.endDate ? userContext.formatDate(dateRange.endDate) : ''}
        disabled
        onClick={(e) => {
          e.preventDefault(); // Prevent HTML5 datepicker
        }}
      />
    ),
  });

  if (!isSingleDateSelect) {
    const value = (dateRange && dateRange.startDate && dateRange.endDate)
      ? DateDifferenceHumanLanguage(dateRange.startDate, dateRange.endDate)
      : '';

    rows.push({
      key: 'estimate',
      left: 'Duration',
      right: (
        <TextField
          fullWidth
          size="small"
          type="text"
          value={value}
          disabled
        />
      ),
    });
  }

  return (
    <>
      <Box onClick={() => { setIsOpen(true); }}>
        {customButton}
      </Box>
      <Dialog
        open={isOpen}
        onClose={onCloseOrDiscard}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Schedule</DialogTitle>
        <DialogContent>
          <BoxFlexColumn>
            <FullCalendar
              plugins={[interactionPlugin, dayGridPlugin]}
              initialView="dayGridMonth"
              selectable
              unselectAuto={false}
              select={(selectionInfo) => {
                if (!isSingleDateSelect) {
                  setDateRange({
                    startDate: selectionInfo.start,
                    endDate: selectionInfo.end,
                  });
                }
              }}
              dateClick={(dateClickInfo) => {
                if (isSingleDateSelect) {
                  calendarRef.current?.getApi().unselect();
                  setDateRange({ endDate: dateClickInfo.date });
                }
              }}
              ref={calendarRef}
            />
            <LeftRightColumnComponent rows={rows} />
          </BoxFlexColumn>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            onClick={onSaveClicked}
            disabled={dateRange === undefined || dateRange === initialDateRange}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            disabled={isSaving}
            onClick={onCloseOrDiscard}
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
