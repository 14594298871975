import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import BoxFlexRow from './styled-containers/BoxFlexRow';
import DuplicateItemError from '../../api/DuplicateItemError';
import { useUserContext } from './context/user-context/UserContext';

interface Props {
  isOpen: boolean
  existingUrl: string
  onEditedRequestUrl: (newRequestUrl: string) => void
  onClose: ()=>void
}

export default function EditRequestUrlDialog({
  isOpen,
  existingUrl,
  onEditedRequestUrl,
  onClose,
}: Props) {
  const userContext = useUserContext();

  const [isDialogOpen, setIsDialogOpen] = React.useState(isOpen);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [url, setUrl] = React.useState(existingUrl);

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  const onSubmitClicked = async () => {
    try {
      setIsSubmitting(true);
      await userContext.editRequestUrl(url);
      toast.info('Updated request URL');
      setIsDialogOpen(false);
      onClose();
      onEditedRequestUrl(url);
      // TODO make this in user context and then update the user context object when the url is updated
    } catch (e) {
      if (e instanceof DuplicateItemError) {
        toast.error('URL is already in use');
      } else {
        toast.error('Error editing URL');
        console.error(e);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const urlRegex = /^([a-zA-Z\d\-_~.]{3,})$/;
  const validUrl = urlRegex.test(url);

  return (
    <Dialog
      open={isDialogOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Edit Request URL</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Customise your form request link.
          <br />
          <br />
          <ul>
            <li>Not Case-Sensitive</li>
            <li>Minimum of 3 characters</li>
            <li>
              Can only contain alphanumeric characters or some special
              characters (&quot;-&quot;, &quot;.&quot;, &quot;_&quot;, &quot;~&quot;)
            </li>
          </ul>
          <br />
        </DialogContentText>
        <TextField
          autoFocus
          error={!validUrl}
          helperText={!validUrl ? 'URL does not meet the requirements' : ''}
          disabled={isSubmitting}
          margin="dense"
          label="Request URL ending"
          type="text"
          fullWidth
          variant="standard"
          value={url}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUrl(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <BoxFlexRow>
          <LoadingButton
            disabled={url === existingUrl || !validUrl}
            loading={isSubmitting}
            variant="contained"
            onClick={onSubmitClicked}
          >
            Confirm Changes
          </LoadingButton>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              setIsDialogOpen(false);
              onClose();
              setTimeout(() => {
                // Set this later to allow the box to close completely before toggling the switch
                setUrl(existingUrl);
              }, 100);
            }}
          >
            Cancel
          </Button>
        </BoxFlexRow>
      </DialogActions>
    </Dialog>
  );
}
