import React, { createContext } from 'react';
import * as RequestsApi from '../../../../api/RequestsApi';
import useInterval from './UseInterval';

const POLLING_INTERVAL = Number(process.env.REACT_APP_POLLING_INTERVAL) || 10000;

export interface PollingDataContextObject {
  undecidedRequestCount: number | undefined
  pollDataNow: () => void
}

export const PollingDataContext = createContext<PollingDataContextObject | undefined>(undefined);

type Props = {
  children: React.ReactNode
};

function PollingDataProvider({ children }:Props) {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [lastPollTime, setLastPollTime] = React.useState<Date | undefined>(undefined);
  const [undecidedRequestCount, setUndecidedRequestCount] = React.useState<number | undefined>(undefined);

  const pollData = async () => {
    if (!lastPollTime || isFocused) {
      setUndecidedRequestCount(await RequestsApi.getUndecidedRequestCount());
      setLastPollTime(new Date());
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  React.useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    pollData().then(() => {});
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useInterval(() => { pollData().then(() => {}); }, POLLING_INTERVAL);

  const pollDataNow = () => {
    pollData().then(() => {});
  };

  // Change only on isLoading status changes
  const memoPollingData = React.useMemo(
    ():PollingDataContextObject => ({
      undecidedRequestCount, pollDataNow,
    }),
    [undecidedRequestCount],
  );

  return (
    <PollingDataContext.Provider value={memoPollingData}>
      {children}
    </PollingDataContext.Provider>
  );
}

const usePollingDataContext = () => {
  const context = React.useContext(PollingDataContext);
  if (context === undefined) {
    throw new Error('usePollingDataContext must be used within a PollingDataProvider');
  }
  return context;
};

export {
  PollingDataProvider,
  usePollingDataContext,
};
