import axios, { AxiosError } from 'axios';
import ApiError, {
  ERROR_GET_USER, ERROR_NETWORK_ERROR, ERROR_UNAUTHORISED,
} from '../ApiError';
import { User } from '../../representations/User';
import { SERVER_URL } from '../SharedApiStuff';

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response !== undefined && error.response.status === 401) {
    throw new ApiError('Unauthorised', ERROR_UNAUTHORISED, error);
  } else if (error.message === 'Network Error') {
    throw new ApiError('Network Error', ERROR_NETWORK_ERROR, error);
  }
  throw error;
});

interface GetUserResponse {
  user: User;
}

// eslint-disable-next-line import/prefer-default-export
export async function getCookieUser(): Promise<User> {
  try {
    const response: GetUserResponse = (
      await axios.get(`${SERVER_URL}/api/v1/user`, { withCredentials: true })
    ).data;

    return response.user;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      if (error.response?.status === 404) {
        throw new ApiError('No user found', ERROR_GET_USER);
      } else {
        throw new ApiError('Unknown server error', 'UNKNOWN');
      }
    } else {
      throw new ApiError('Couldn\'t get userObject', ERROR_GET_USER, e);
    }
  }
}
