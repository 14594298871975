import React, {
  createContext, useEffect,
} from 'react';
import { CommissionWritable } from 'src/representations/CommissionWritable';
import * as CommissionApi from '../../../../api/CommissionApi';
import CommissionContextObject from './CommissionContextObject';
import { Commission } from '../../../../representations/Commission';
import { CommissionMetadata } from '../../../../representations/CommissionMetadata';
import { ViewableCommissionAttachment } from '../../../../representations/CommissionAttachment';
import { ScheduleWritable } from '../../../../representations/CommissionSchedule';
import * as CommissionScheduleApi from '../../../../api/CommissionScheduleApi';

type Props = {
  children: React.ReactNode
};

export const CommissionContext = createContext<CommissionContextObject | undefined>(undefined);

function CommissionProvider({ children }: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [commissions, setCommissions] = React.useState<Commission[]>([]);
  const [archivedCommissions, setArchivedCommissions] = React.useState<Commission[]>([]);

  const reloadAllCommissions = async () => {
    setIsLoading(true);
    try {
      const loaded = await CommissionApi.getCommissions();
      const loadedArchived = await CommissionApi.getArchivedCommissions();

      setCommissions(loaded);
      setArchivedCommissions(loadedArchived);

      console.log(`Loaded [${loaded.length}] commissions and [${loadedArchived.length}] archived commissions`);
    } catch (e) {
      console.log('Error reloading all commissions from server', e);
    } finally {
      setIsLoading(false);
    }
  };

  // First load
  useEffect(() => {
    reloadAllCommissions().then(() => {
    });
  }, []);

  // Loading state must be set outside from this because it is only called within this file
  const findCommissionFromLists = async (commissionUuid: string) => {
    let foundCommission = commissions.find((c) => c.commissionUuid === commissionUuid);
    if (foundCommission === undefined) {
      foundCommission = archivedCommissions.find((c) => c.commissionUuid === commissionUuid);
    }

    return foundCommission;
  };

  // Loading state must be set outside from this because it is only called within this file
  const findCommissionOrFetch = async (commissionUuid: string) => {
    const commission = findCommissionFromLists(commissionUuid);
    if (commission !== undefined) {
      return commission;
    }

    console.log("couldn't find old commission, getting from server...");
    try {
      return await CommissionApi.getCommissionByUuid(commissionUuid);
    } catch (e) {
      console.error('Problem loading commission from server', e);
      throw e;
    }
  };

  const reloadCommission = async (commissionUuid: string) : Promise<Commission> => {
    setIsLoading(true);
    try {
      // Always get from server
      const reloadedCommission = await CommissionApi.getCommissionByUuid(commissionUuid);

      // Remove old commission
      const newArchivedCommissions = archivedCommissions.filter((c) => c.commissionUuid !== commissionUuid);
      const newCommissions = commissions.filter((c) => c.commissionUuid !== commissionUuid);

      // Replace with the reloaded Commission
      if (reloadedCommission.archived) {
        newArchivedCommissions.push(reloadedCommission);
      } else {
        newCommissions.push(reloadedCommission);
      }

      // Set the state with the newly added commission
      setCommissions(newCommissions);
      setArchivedCommissions(newArchivedCommissions);
      return reloadedCommission;
    } catch (e) {
      console.error('Problem reloading commission from server', e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const setPreviewImage = async (attachment: ViewableCommissionAttachment): Promise<void> => {
    setIsLoading(true);
    try {
      await CommissionApi.setPreviewImage(attachment);
      await reloadCommission(attachment.commissionUuid);
    } finally {
      setIsLoading(false);
    }
  };

  const archiveCommissions = async (commissionUuids: string[]) => {
    setIsLoading(true);
    try {
      await CommissionApi.archiveCommissions(commissionUuids);
      await reloadAllCommissions();
    } finally {
      setIsLoading(false);
    }
  };

  const unarchiveCommissions = async (commissionUuids: string[]) => {
    setIsLoading(true);
    try {
      await CommissionApi.unarchiveCommission(commissionUuids);
      await reloadAllCommissions();
    } finally {
      setIsLoading(false);
    }
  };

  const addCommission = async (commission: CommissionWritable) => {
    setIsLoading(true);
    try {
      const createdCommission = await CommissionApi.addCommission(commission);
      await reloadAllCommissions();
      return createdCommission;
    } finally {
      setIsLoading(false);
    }
  };

  const modifyCommission = async (commissionUuid: string, commission: CommissionWritable) => {
    setIsLoading(true);
    try {
      await CommissionApi.modifyCommission(commissionUuid, commission);
      return await reloadCommission(commissionUuid);
    } finally {
      setIsLoading(false);
    }
  };

  const modifyCommissionMetadata = async (commissionUuid: string, metadata: CommissionMetadata) => {
    try {
      setIsLoading(true);
      const { metadata: modifiedCommissionMetadata } = await CommissionApi.modifyCommissionMetadata(commissionUuid, metadata);
      await reloadCommission(commissionUuid);
      return modifiedCommissionMetadata;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  const getCommissionStatusHistory = async (commissionUuid: string) => CommissionApi.getCommissionStatusHistory(commissionUuid);

  const modifyCommissionStatus = async (commissionUuid: string, newStatusUuid: string) => {
    setIsLoading(true);
    try {
      const modifiedCommission = await CommissionApi.modifyCommissionStatus(commissionUuid, newStatusUuid);
      await reloadAllCommissions();
      return modifiedCommission;
    } finally {
      setIsLoading(false);
    }
  };

  const getCommissionByUuid = (commissionUuid: string): Commission => {
    const commission = commissions.concat(archivedCommissions).find((c) => c.commissionUuid === commissionUuid);
    if (!commission) {
      throw new Error(`Couldn't find the commission with commissionUuid ${commissionUuid}`);
    }
    return commission;
  };

  const modifyCommissionSchedule = async (commissionUuid: string, newSchedule: ScheduleWritable) => {
    const cs = await CommissionScheduleApi.putCommissionSchedule(commissionUuid, newSchedule);
    await reloadCommission(commissionUuid);
    return cs;
  };

  // Change only on isLoading status changes
  const memoizedCommissionContextObject = React.useMemo(
    (): CommissionContextObject => ({
      isLoading,
      commissions,
      archivedCommissions,
      archiveCommissions,
      unarchiveCommissions,
      addCommission,
      modifyCommission,
      modifyCommissionMetadata,
      modifyCommissionStatus,
      getCommissionStatusHistory,
      getCommissionByUuid,
      reloadCommission,
      setPreviewImage,
      modifyCommissionSchedule,
    }),
    [isLoading],
  );

  return (
    <CommissionContext.Provider value={memoizedCommissionContextObject}>
      {children}
    </CommissionContext.Provider>
  );
}

const useCommissionContext = () => {
  const context = React.useContext(CommissionContext);
  if (context === undefined) {
    throw new Error('useCommission must be used within a CommissionProvider');
  }
  return context;
};

export {
  CommissionProvider, useCommissionContext,
};
