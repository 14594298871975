import {
  Grid, Typography,
} from '@mui/material';
import React from 'react';
import CalendarComponent from './CalendarComponent';
import DashboardQuickAddCommissionComponent from './DashboardQuickAddCommissionComponent';
import DashboardActiveCommissionslistComponent from './DashboardActiveCommissionslistComponent';
import DashboardCommissionRequestsComponent from './DashboardCommissionRequestsComponent';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import CommissionPreviewPopover from '../CommissionPreviewPopover';
import { Commission } from '../../../representations/Commission';
import TitlePaper from '../styled-containers/TitlePaper';

function DashboardComponent() {
  const [clickedCommission, setClickedCommission] = React.useState<Commission | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onCommissionClicked = (element: HTMLElement, commission: Commission) => {
    setClickedCommission(commission);
    setAnchorEl(element);
  };

  // <RouterLink to={linkToDetails}>
  //   <Typography variant="h3">{commission.label}</Typography>
  // </RouterLink>

  return (
    <BoxFlexColumn>
      <CommissionPreviewPopover
        commission={clickedCommission}
        anchorEl={anchorEl}
        onPopoverClosed={() => {
          setClickedCommission(null);
          setAnchorEl(null);
        }}
      />
      <Typography variant="h1">Dashboard</Typography>
      {/* <DashboardGanttViewComponent /> */}
      <Grid container spacing={3}>
        <Grid id="left-grid" item container spacing={3} xs={5} sm={5} md={5}>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardActiveCommissionslistComponent
              onCommissionClicked={onCommissionClicked}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardQuickAddCommissionComponent />
          </Grid>
        </Grid>
        <Grid id="right-grid" item container spacing={3} xs={7} sm={7} md={7}>
          <Grid item xs={12} sm={12} md={12}>
            <TitlePaper title="Calendar">
              {/* <CalendarComponent */}
              {/*  onCommissionClicked={onCommissionClicked} */}
              {/* /> */}
            </TitlePaper>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardCommissionRequestsComponent />
          </Grid>
        </Grid>
      </Grid>
    </BoxFlexColumn>
  );
}

export default DashboardComponent;
