import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableOptions,
} from 'mui-datatables';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { RequestFormClientSubmissionNoAnswers } from '../../../representations/RequestFormClientSubmission';

interface TableData {
  clientDetails: string;
  dateCreated: Date;
  submissionUuid: string;
}

interface Props {
  requestSubmissions: RequestFormClientSubmissionNoAnswers[];
  onSubmissionSelected: (submissionUuid: string) => void;
  dateDisplayFormatter: (date: Date) => string;
}

export default function UndecidedRequestsTableComponent({
  requestSubmissions,
  onSubmissionSelected,
  dateDisplayFormatter,
} : Props) {
  const [tableData, setTableData] = React.useState<TableData[]>([]);

  useEffect(() => {
    const loadRequests = async () => {
      const td = requestSubmissions.map((d):TableData => ({
        clientDetails: `${d.clientName} <${d.clientEmail}>`,
        dateCreated: d.dateCreated,
        submissionUuid: d.submissionUuid,
      }));
      setTableData(td);
    };
    loadRequests().then(() => {});
  }, [requestSubmissions]);

  const onRowClick = (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
    if (tableData) {
      onSubmissionSelected(tableData[rowMeta.dataIndex].submissionUuid);
    } else {
      toast.error('Error on getting submission from list');
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'clientDetails',
      label: 'Requester',
    },
    {
      name: 'dateCreated',
      label: 'Date Received',
      options: { customBodyRender: dateDisplayFormatter },
    },
    // {
    //   name: 'batchId',
    //   label: 'Batch',
    // },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'checkbox',
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    onRowClick,
    textLabels: { body: { noMatch: requestSubmissions.length === 0 ? 'No requests received yet' : 'No search results' } },
  };

  return (
    <MUIDataTable
      title="Undecided"
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
