import React from 'react';
import ViewRequestSubmissionComponent from './ViewRequestSubmissionComponent';
import { RequestFormClientSubmissionWithFiles } from '../../../representations/RequestFormClientSubmission';
import TitlePaper from '../styled-containers/TitlePaper';

interface Props {
  requestFormSubmission: RequestFormClientSubmissionWithFiles;
}

export default function ViewRequestSubmissionWithoutClientComponent({ requestFormSubmission }: Props) {
  return (
    <TitlePaper title="Request Form">
      <ViewRequestSubmissionComponent requestFormAnswers={requestFormSubmission.answers} />
    </TitlePaper>
  );
}
