import './font.css';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    boldBody1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    boldBody1?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    boldBody1: true;
  }
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({
  sm, md, lg,
}: { sm: number, md: number, lg: number }) {
  return {
    '@media (min-width:600px)': { fontSize: pxToRem(sm) },
    '@media (min-width:900px)': { fontSize: pxToRem(md) },
    '@media (min-width:1200px)': { fontSize: pxToRem(lg) },
  };
}

export const FONT_PRIMARY = 'Segoe UI, Public Sans, sans-serif';
export const FONT_HEADERS = 'Poppins, Public Sans, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_HEADERS,
    fontWeight: 600,
    lineHeight: 80 / 64,
    fontSize: pxToRem(28),
    textTransform: 'capitalize',
  },
  h2: {
    fontFamily: FONT_HEADERS,
    fontWeight: 500,
    lineHeight: 64 / 48,
    fontSize: pxToRem(24),
    textTransform: 'capitalize',
  },
  h3: {
    fontFamily: FONT_HEADERS,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    textTransform: 'capitalize',
  },
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(13),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(15),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  boldBody1: {
    // fontWeight: 'bold',
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontFamily: FONT_HEADERS,
    fontWeight: 400,
    lineHeight: 24 / 14,
    fontSize: pxToRem(16),
    textTransform: 'capitalize',
  },
};

export default typography;
