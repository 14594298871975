import {
  Box, FormControl, MenuItem, Select, Typography,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import BoxFlexRow from './styled-containers/BoxFlexRow';
import {
  FONT_HEADERS, FONT_PRIMARY, pxToRem,
} from '../../theme/typography';

export interface SelectPairs {
  label: string,
  value: string,
}

interface Props {
  label:string
  selectedValue?: string
  values: SelectPairs[]
  onValueChanged?: (newValue: string) => void
  disabled?: boolean
}

export default function LabelledSelect({
  label,
  values,
  selectedValue,
  onValueChanged = (newValue) => { console.log(newValue); },
  disabled = false,
}:Props) {
  const [value, setValue] = React.useState<string | undefined>(selectedValue);

  const theme = useTheme();
  const padding = theme.spacing(0, 3);

  return (
    <BoxFlexRow sx={{
      gap: 0,
      height: 40,
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding,
        height: '100%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.getContrastText(theme.palette.grey[600]),
      }}
      >
        <Typography sx={{
          fontFamily: FONT_HEADERS,
          fontWeight: 400,
          fontSize: pxToRem(16),
        }}
        >
          {label}
        </Typography>
      </Box>
      <FormControl>
        <Select
          disabled={disabled}
          sx={{
            height: '100%',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          value={value}
          onChange={(e) => { setValue(e.target.value); onValueChanged(e.target.value); }}
        >
          {values.map((selectPair) => (
            <MenuItem
              key={selectPair.value}
              value={selectPair.value}
            >
              {selectPair.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </BoxFlexRow>
  );
}
