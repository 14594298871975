import { CommissionStatus } from '../../../../representations/CommissionStatus';

export default class CommissionStatusContextObject {
  isLoading: boolean;

  commissionStatuses: CommissionStatus[];

  constructor(isLoading:boolean, commissionStatuses: CommissionStatus[]) {
    this.isLoading = isLoading;
    this.commissionStatuses = commissionStatuses;
  }

  getCommissionStatusByUuid(uuid:string) {
    const status = this.commissionStatuses.filter((c: CommissionStatus) => c.statusUuid === uuid);

    if (status.length === 0) {
      throw new Error(`No status found with uuid [${uuid}]`);
    }
    return status[0];
  }
}
