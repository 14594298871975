import {
  TextField, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import {
  getDaysFromTimeUnit,
  ModifiedTask, NewTask, Task, TimeUnit,
} from '../../../representations/Task';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import * as TaskApi from '../../../api/TaskApi';

import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import SchedulerCalendar from '../calendar/SchedulerCalendar';
import TitlePaper from '../styled-containers/TitlePaper';
import { useUserContext } from '../context/user-context/UserContext';

interface Props {
  onTaskCreated: () => void
  onTaskModified: () => void
  commissionUuid?: string
  existingTask?: Task
}

export default function AddOrEditNewTaskComponent({
  commissionUuid,
  existingTask,
  onTaskCreated,
  onTaskModified,
}:Props) {
  const [label, setLabel] = React.useState<string | undefined>(existingTask ? existingTask.label : '');
  const [selectedTimeUnit, setSelectedTimeUnit] = React.useState<TimeUnit>(
    existingTask && existingTask.unit ? existingTask.unit : TimeUnit.DAY,
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined | null>(
    existingTask && existingTask.date ? new Date(existingTask.date) : undefined,
  );
  const [estimatedTimeUnits, setEstimatedTimeUnits] = React.useState<number | string>(
    existingTask && existingTask.requiredTimeUnits ? existingTask.requiredTimeUnits : '',
  );

  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const userContext = useUserContext();

  const saveTask = async () => {
    setIsSaving(true);
    try {
      if (!label) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('No Label');
      }

      const newDate = selectedDate || null;
      const newEstimate = (estimatedTimeUnits && typeof estimatedTimeUnits === 'number') ? estimatedTimeUnits : null;
      const newSelectedTimeUnit = estimatedTimeUnits ? selectedTimeUnit : null;

      if (existingTask) {
        const modifiedTask: ModifiedTask = {
          label,
          date: newDate,
          requiredTimeUnits: newEstimate,
          unit: newSelectedTimeUnit,
        };
        await TaskApi.modifyTask(existingTask.uuid, modifiedTask);
        onTaskModified();
      } else {
        const newTask: NewTask = {
          label,
          commissionUuid: commissionUuid || null,
          date: newDate,
          requiredTimeUnits: newEstimate,
          unit: newSelectedTimeUnit,
        };
        await TaskApi.addTask(newTask);
        onTaskCreated();
      }
    } catch (e) {
      console.error(e);
      toast.error('Error saving task');
    } finally {
      setIsSaving(false);
    }
  };

  const onSaveClicked = () => {
    saveTask().then(() => {});
  };
  /*
  ok Label
  Date
  Estimated Units
  Hours/Days/weeks
   */

  const labelHasError = (label !== undefined && label.trim() === '');

  const rows: LeftRightColumnRow[] = [
    {
      key: '1',
      left: 'Label',
      right: <TextField
        fullWidth
        size="small"
        value={label}
        disabled={isSaving}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setLabel(e.target.value);
          setIsFormDirty(true);
        }}
        error={isFormDirty && labelHasError}
        helperText={isFormDirty && labelHasError ? 'Label cannot be blank' : ' '}
      />,
    },
  ];

  const rows2: LeftRightColumnRow[] = [
    {
      key: '2',
      left: 'Unit',
      right: (
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={selectedTimeUnit}
          disabled={isSaving}
          exclusive
          onChange={(event, value: TimeUnit) => {
            if (value !== null) {
              setSelectedTimeUnit(value);
            }
          }}
        >
          <ToggleButton value={TimeUnit.HOUR}>Hours</ToggleButton>
          <ToggleButton value={TimeUnit.DAY}>Days</ToggleButton>
          <ToggleButton value={TimeUnit.WEEK}>Weeks</ToggleButton>
        </ToggleButtonGroup>
      ),
    },
    {
      key: '3',
      left: `Estimated ${selectedTimeUnit.valueOf().toLowerCase()}s`,
      right: <TextField
        fullWidth
        size="small"
        type="number"
        value={estimatedTimeUnits}
        disabled={isSaving}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value: string = e.target.value.trim();
          if (value !== '') {
            const valueInt = parseInt(e.target.value, 10);
            if (valueInt > 100) {
              setEstimatedTimeUnits(100);
            } else if (valueInt < 0) {
              setEstimatedTimeUnits(0);
            } else {
              setEstimatedTimeUnits(valueInt);
            }
          } else {
            setEstimatedTimeUnits('');
          }
          setIsFormDirty(true);
        }}
          // error={isFormDirty && labelHasError}
        // helperText={isFormDirty && labelHasError ? 'Label cannot be blank' : ' '}
      />,
    },
  ];

  return (
    <TitlePaper title={!existingTask ? 'Create New Task' : 'Modify Task'}>
      <BoxFlexColumn>
        <LeftRightColumnComponent rows={rows} />
        <Typography variant="h3">Date Scheduling (Optional)</Typography>
        <LeftRightColumnComponent rows={rows2} />
        <SchedulerCalendar
          dateFormatter={userContext.formatDate}
          showTextDateInput
          isSaving={isSaving}
          value={selectedDate || undefined}
          onDateSelected={(date) => {
            setSelectedDate(date);
          }}
        />
        <LoadingButton
          // startIcon={<SaveIcon />}
          disabled={labelHasError}
          loading={isSaving}
          onClick={onSaveClicked}
          variant="contained"
        >
          Save
        </LoadingButton>
      </BoxFlexColumn>
    </TitlePaper>
  );
}
