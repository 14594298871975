import React from 'react';
import {
  Paper, Typography,
} from '@mui/material';
import { Commission } from '../../../representations/Commission';
import TitlePaper from '../styled-containers/TitlePaper';

type Props = {
  commission: Commission;
  dateFormatter: (date: Date)=>string;
};

function CommissionProperties({
  commission,
  dateFormatter,
}:Props) {
  const headersVariant = 'h6';

  return (
    <TitlePaper title="Properties" color="grey">
      <Typography variant={headersVariant}>Created on</Typography>
      <Typography variant="body1">{dateFormatter(commission.dateCreated)}</Typography>
      <Typography variant={headersVariant}>Last Updated</Typography>
      <Typography variant="body1">{dateFormatter(commission.dateUpdated)}</Typography>
    </TitlePaper>
  );
}

export default CommissionProperties;
