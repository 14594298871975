import {
  Box, Grid,
} from '@mui/material';
import React from 'react';
import { DarkTransparentStyle } from './styled-containers/DarkTransparentPaper';

interface Props {
  left: React.ReactNode
  right: React.ReactNode
}

export default function LeftAndRightGridScrollableRightComponent({
  left, right,
}:Props) {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%' }}
    >
      <Grid item id="left-side" xs={12} sm={12} md={6}>
        {left}
      </Grid>
      <Grid
        item
        id="right-side"
        xs={12}
        sm={12}
        md={6}
        sx={{ height: '100%' }}
      >
        <Box
          id="scrollable-box"
          sx={{
            height: '100%',
            overflowY: 'auto',
            /* width */
            '::-webkit-scrollbar': { width: (theme) => theme.spacing(1.5) },
            /* Track */
            '::-webkit-scrollbar-track': { background: 'transparent' },
            /* Handle */
            '::-webkit-scrollbar-thumb': {
              background: DarkTransparentStyle.backgroundColor,
              borderRadius: (theme) => theme.shape.borderRadius,
            },
            /* Handle on hover */
            '::-webkit-scrollbar-thumb:hover': { background: DarkTransparentStyle.backgroundColorLighter },
            padding: (theme) => theme.spacing(3),
          }}
        >
          {right}
        </Box>
      </Grid>
    </Grid>
  );
}
