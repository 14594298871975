import * as React from 'react';
import {
  Autocomplete, Box, Button, TextField, Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  adjectives, animals, colors, uniqueNamesGenerator,
} from 'unique-names-generator';
import AddClientDialogComponent from './AddClientDialogComponent';
import InputItem from './styled-containers/InputItem';
import { Client } from '../../representations/Client';
import { Commission } from '../../representations/Commission';
import {
  commissionDiffersFrom, CommissionWritable,
} from '../../representations/CommissionWritable';
import { useCommissionContext } from './context/commission-context/CommissionContext';
import { useClientContext } from './context/client-context/ClientContext';

const getRandomLabel = () => uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals] })
  .replaceAll('_', ' ');

type Props = {
  existingCommission?: Commission,
  onComplete: (resultCommission: Commission) => void,
};

function AddCommissionComponent({
  existingCommission = undefined,
  onComplete = () => {},
}: Props) {
  const commissionContext = useCommissionContext();
  const clientContext = useClientContext();

  const [client, setClient] = React.useState<Client | null>(null);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isValidCommission, setIsValidCommission] = React.useState(false);
  const [label, setLabel] = React.useState<string | null>('');
  const [placeholderLabel, setPlaceholderLabel] = React.useState<string>(getRandomLabel());

  // Modal
  const [modalOpen, setModalOpen] = React.useState(false);

  const isModifying = existingCommission !== undefined;
  const originalCommission : CommissionWritable | undefined = isModifying ? existingCommission : undefined;

  const onAddClientComponentCompleted = async (newClient : Client) => {
    setModalOpen(false);
    setClient(newClient);
  };

  const initialiseExistingCommission = async (commission : Commission, existingClient: Client) => {
    if (existingCommission === undefined) {
      throw new Error(`Cannot find client with uuid [${commission.clientUuid}]`);
    }

    if (existingClient === undefined) {
      toast.error(`Failed to find a client for commission [${commission.label}]`);
      throw new Error(`Cannot find client with uuid [${commission.clientUuid}]`);
    } else {
      setLabel(commission.label);
      setClient(existingClient);
    }
  };

  const getCommissionToWrite = (clientUuid: string, commissionLabel: string) : CommissionWritable => ({
    clientUuid,
    label: commissionLabel.trim(),
    commissionMetadata: existingCommission ? existingCommission.commissionMetadata : {},
  });

  const validateCommission = () => client !== null;

  const isCommissionChanged = () => {
    if (originalCommission === undefined) {
      return true;
    }

    return commissionDiffersFrom(
      originalCommission,
      getCommissionToWrite(originalCommission.clientUuid, originalCommission.label),
    );
  };

  const onSubmitButtonClicked = async () => {
    if (client === null) {
      toast.error('Client was null when submitting');
      return;
    }

    const labelToCreate = !label ? placeholderLabel : label;

    const comm = getCommissionToWrite(client.clientUuid, labelToCreate);

    try {
      let writtenCommission;
      if (isModifying) {
        writtenCommission = await commissionContext.modifyCommission(existingCommission.commissionUuid, comm);
        toast.info(`Edited commission ${comm.label}`);
      } else {
        writtenCommission = await commissionContext.addCommission(comm);
        toast.info('Created new commission');
      }
      setClient(null);
      setLabel(null);
      setPlaceholderLabel(getRandomLabel());
      onComplete(writtenCommission);
    } catch (e) {
      toast.error(`Error ${isModifying ? 'editing' : 'creating'} commission`);
      console.error(e);
    }
  };

  const onInputLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value.trim());
  };

  const getOptionDisplay = (option : Client) => {
    const email = option.email === null ? '' : ` (${option.email})`;
    return `${option.name}${email}`;
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (isModifying) {
          await initialiseExistingCommission(
            existingCommission,
            clientContext.getClientByUuid(existingCommission?.clientUuid),
          );
        }
      } catch (e) {
        toast.error('Error loading commission details');
        console.error('Error loading commission details', e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      setIsValidCommission(validateCommission());
      setIsChanged(isCommissionChanged());
    })();
  }, [label, client]);

  if (clientContext.isLoading) {
    return (
      <Box>
        Loading...
      </Box>
    );
  }

  return (
    <Box>
      <AddClientDialogComponent
        isOpen={modalOpen}
        onComplete={onAddClientComponentCompleted}
        onCancel={() => { setModalOpen(false); }}
      />

      <InputItem>
        <Typography variant="subtitle1">Client</Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
        }}
        >
          <Autocomplete
            disabled={isModifying}
            isOptionEqualToValue={(a, b) => a.clientUuid === b.clientUuid}
            value={client}
            onChange={(event, newValue) => {
              setClient(newValue);
            }}
            options={clientContext.getActiveClients()}
            renderInput={(params) => (<TextField {...params} />)}
            getOptionLabel={(option) => getOptionDisplay(option)}
            renderOption={(props, option) => (
              <li {...props} key={option.clientUuid}>
                {getOptionDisplay(option)}
              </li>
            )}
            sx={{ flexGrow: 3 }}
          />
          {!isModifying
          && (
          <Button
            onClick={() => { setModalOpen(true); }}
            variant="contained"
            sx={{ }}
          >
            Add new client
          </Button>
          )}
        </Box>
      </InputItem>

      <InputItem>
        <Typography variant="subtitle1">Label</Typography>
        <TextField
          id="input-name"
          variant="outlined"
          value={label}
          onChange={onInputLabel}
          placeholder={placeholderLabel}
        />
      </InputItem>
      <InputItem>
        <Button
          disabled={!isValidCommission || !isChanged}
          onClick={onSubmitButtonClicked}
          variant="contained"
        >
          {isModifying ? 'Edit' : 'Add'}
        </Button>
      </InputItem>
    </Box>
  );
}

export default AddCommissionComponent;
