import React from 'react';
import { Outlet } from 'react-router-dom';
import { UserProvider } from 'src/components/myoc-components/context/user-context/UserContext';
import { CommissionStatusProvider } from 'src/components/myoc-components/context/status-context/CommissionStatusContext';
import { ClientProvider } from 'src/components/myoc-components/context/client-context/ClientContext';
import { CommissionProvider } from 'src/components/myoc-components/context/commission-context/CommissionContext';
import { styled } from '@mui/material/styles';
import {
  Box, IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { PollingDataProvider } from 'src/components/myoc-components/context/polling-data-context/PollingDataContext';
import DashboardSidebar from './DashboardSidebar';
import MyocLogoHorizontal from '../../pages/MyocLogoHorizontal';
import BoxFlexRow from '../../components/myoc-components/styled-containers/BoxFlexRow';
import TopAppBar from '../../components/myoc-components/TopAppBar';
import FeedbackReporter from '../../components/myoc-components/feedback-reporter/FeedbackReporter';

const PageLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

}));
const BelowNavbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export default function DashboardLayout() {
  const [open, setOpen] = React.useState(false);

  return (
    <UserProvider>
      <PollingDataProvider>
        <CommissionStatusProvider>
          <ClientProvider>
            <CommissionProvider>
              <PageLayout>
                <TopAppBar>
                  <BoxFlexRow>
                    <IconButton
                      aria-label="menu"
                      onClick={() => setOpen((prev) => !prev)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <MyocLogoHorizontal />
                  </BoxFlexRow>
                </TopAppBar>
                {/* <DashboardNavbar */}
                {/*  onOpenSidebar={() => setOpen(true)} */}
                {/* /> */}
                <BelowNavbar>
                  <DashboardSidebar
                    isOpenSidebar={open}
                    onSidebarOpened={() => setOpen(true)}
                    onSidebarClosed={() => setOpen(false)}
                  />
                  <Outlet />
                </BelowNavbar>
              </PageLayout>
              <FeedbackReporter />
            </CommissionProvider>
          </ClientProvider>
        </CommissionStatusProvider>
      </PollingDataProvider>
    </UserProvider>
  );
}
