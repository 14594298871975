import {
  Box, Paper, Typography,
} from '@mui/material';
import React from 'react';
import {
  CheckboxButtonGroup, FormContainer, RadioButtonGroup, TextFieldElement,
} from 'react-hook-form-mui';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  getUploadProgressArray, uploadAndGetKeys,
} from 'src/components/UploadFunctionComponents';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { JSONContent } from '@tiptap/react';
import { RequestForm } from '../../../representations/RequestForm';
import { RequestFormQuestion } from '../../../representations/RequestFormQuestion';
import { QuestionOption } from '../../../representations/QuestionOption';
import { RequestFormClientSubmissionWritable } from '../../../representations/RequestFormClientSubmissionWritable';
import * as PublicRequestFormApi from '../../../api/PublicRequestFormApi';
import FileUploadComponent, {
  FileWithPreview, UploadProgress,
} from '../FileUploadComponent';
import { RequestFormAnswer } from '../../../representations/BaseRequestFormAnswer';
import FormTextField from '../formbuilder/FormTextField';
import RichTextEditor from '../rich-text-editor/RichTextEditor';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

class FormValidationError extends Error {
}

function mapOptions(options: QuestionOption[] | undefined) {
  if (options === undefined) {
    return [];
  }

  return options.map((option) => ({
    id: option.uuid,
    label: option.title,
  }));
}

const CLIENT_NAME_TEXTFIELD_NAME = 'CLIENT_NAME_TEXTFIELD_NAME';
const CLIENT_EMAIL_TEXTFIELD_NAME = 'CLIENT_EMAIL_TEXTFIELD_NAME';

type QuestionFiles = {
  questionUuid: string,
  files: FileWithPreview[],
};

type Props = {
  artistUserUuid: string;
  requestForm: RequestForm;
  isPreview? : boolean;
  onSubmitComplete?:(submissionUuid: string)=>void;
  maximumFileBytes: number;
};

export default function CreateNewRequestComponent({
  artistUserUuid,
  requestForm,
  isPreview = false,
  onSubmitComplete = () => {},
  maximumFileBytes,
}:Props) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [questionFiles, setQuestionFiles] = React.useState<QuestionFiles[]>([]);
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgress[]>([]);

  // One-off as description can only be set once
  const [shouldShowErrorsNow, setShouldShowErrorsNow] = React.useState(false);
  const [descriptionValue, setDescriptionValue] = React.useState<JSONContent | undefined>(undefined);
  const [descriptionError, setDescriptionError] = React.useState<string | undefined>(undefined);

  const getFormQuestion = (question :RequestFormQuestion) => {
    switch (question.type) {
      case 'text':
        return (
          <FormTextField
            type="short"
            question={question}
          />
        );
      case 'description':
        return (
          <Box>
            <RichTextEditor
              readOnly={false}
              controlledMode
              alwaysShowControls
              content={descriptionValue}
              isSaving={isSubmitting}
              onSave={(content, isEmpty) => {
                setDescriptionValue(content);
                setDescriptionError(!question.isOptional && isEmpty ? 'This field is required' : undefined);
              }}
            />
            {shouldShowErrorsNow && descriptionError && (
              <Typography>
                {`(TODO make me nicer) Error here - ${descriptionError}`}
              </Typography>
            )}
          </Box>
        );
      case 'paragraph':
        return (
          <RichTextEditor
            readOnly
            content={question.paragraph}
          />
        );
      case 'checkbox':
        return (
          <CheckboxButtonGroup
            required={!question.isOptional}
            name={question.uuid}
            options={mapOptions(question.options)}
          />
        );
      case 'option':
        return (
          <RadioButtonGroup
            required={!question.isOptional}
            name={question.uuid}
            options={mapOptions(question.options)}
          />
        );
      case 'reference-images':
        return (
          <FileUploadComponent
            maxFileBytes={maximumFileBytes}
            uploadProgress={uploadProgress}
            onFilesChanged={(files:FileWithPreview[]) => {
              setQuestionFiles((oldQuestionFiles) => {
                const newQuestionFiles = oldQuestionFiles.filter((qf) => qf.questionUuid !== question.uuid);
                const qfs : QuestionFiles[] = [...newQuestionFiles, {
                  questionUuid: question.uuid,
                  files,
                }];

                return qfs;
              });
            }}
          />
        );
      default:
        return null;
    }
  };

  const onProgressUpdated = (fileName:string, current:number, total:number) => {
    setUploadProgress((previousValues) => getUploadProgressArray(fileName, current, total, previousValues));
  };

  const uploadFiles = async (files: File[]) => {
    try {
      // Set 0% for all progress immediately for nicer UX
      const newUploadProgress: UploadProgress[] = files.map((f) => ({
        fileName: f.name, current: 0, total: 100,
      }));
      setUploadProgress(newUploadProgress);

      return await uploadAndGetKeys(artistUserUuid, files, 'request', onProgressUpdated);
    } catch (e) {
      toast.error('Failed to upload files');
      setUploadProgress([]);
      throw e;
    }
  };

  const getFullFormResponse = async (values: FieldValues) => {
    const answers = await Promise.all(requestForm.formQuestions.map(async (q) => {
      const entries = Object.entries(values) as ([string, string | string[] | undefined])[];
      const answerValuePair = entries.find(([valueUuid]) => q.uuid === valueUuid);

      let answerValue;
      if (q.type === 'paragraph' || q.type === 'reference-images') {
        answerValue = null;
      } else if (q.type === 'description') {
        if (descriptionError) {
          throw new FormValidationError('Description has an error');
        }
        answerValue = descriptionValue || '';
      } else {
        if (answerValuePair === undefined) {
          throw new Error('question could not be found in values');
        }

        const answerValueObject = answerValuePair[1];

        switch (q.type) {
          case 'checkbox':
            answerValue = !answerValueObject ? [] : (answerValueObject as string[]);
            break;
          case 'option':
          case 'text':
            // eslint-disable-next-line no-case-declarations
            const stringAnswerValue = !answerValueObject ? null : (answerValueObject as string);
            if (stringAnswerValue === null) {
              answerValue = null;
            } else {
              answerValue = stringAnswerValue.trim() === '' ? null : stringAnswerValue;
            }
            break;
          default:
            throw new Error(`unhandled type ${q.type}`);
        }
      }

      // Obtain files to be uploaded and then upload them
      const qfs = questionFiles.find((qf) => qf.questionUuid === q.uuid);
      const files = qfs !== undefined ? qfs.files : [];
      const fileObjects = files.length > 0 ? await uploadFiles(files) : [];

      const x: RequestFormAnswer = {
        title: q.title,
        paragraph: q.paragraph,
        type: q.type,
        options: q.options,
        isOptional: q.isOptional,
        value: answerValue,
        uploadedFileKeys: fileObjects,
      };
      return x;
    }));

    const x: RequestFormClientSubmissionWritable = ({
      clientEmail: values[CLIENT_EMAIL_TEXTFIELD_NAME],
      clientName: values[CLIENT_NAME_TEXTFIELD_NAME],
      answers,
    });
    return x;
  };

  const onSubmit = async (values: FieldValues) => {
    try {
      setIsSubmitting(true);
      const formResponse = await getFullFormResponse(values);
      const submissionUuid = await PublicRequestFormApi.submitNewRequest(artistUserUuid, formResponse);

      onSubmitComplete(submissionUuid);
    } catch (e) {
      if (!(e instanceof FormValidationError)) {
        toast.error('Failed to submit form due to server error');
        console.error(e);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BoxFlexColumn>
      <Typography variant="h2">{requestForm.formTitle}</Typography>
      <FormContainer
        defaultValues={{}}
        onSuccess={onSubmit}
      >
        <BoxFlexColumn onKeyDown={(e) => {
          // Prevent enter key from submitting
          if (e.key === 'Enter') e.preventDefault();
        }}
        >
          <BoxFlexColumn>
            <Typography variant="h3">Name</Typography>
            <TextFieldElement
              required
              autoComplete="name"
              name={CLIENT_NAME_TEXTFIELD_NAME}
            />
          </BoxFlexColumn>
          <BoxFlexColumn>
            <Typography variant="h3">Email</Typography>
            <TextFieldElement
              required
              autoComplete="email"
              name={CLIENT_EMAIL_TEXTFIELD_NAME}
              type="email"
            />
          </BoxFlexColumn>
          {requestForm.formQuestions.map((q) => (
            <BoxFlexColumn key={q.uuid}>
              <Typography variant="h3">{q.title}</Typography>
              {getFormQuestion(q)}
            </BoxFlexColumn>
          ))}
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPreview}
            onClick={() => {
              setShouldShowErrorsNow(true);
            }}
          >
            Submit
          </LoadingButton>
        </BoxFlexColumn>
      </FormContainer>
    </BoxFlexColumn>
  );
}
