import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import React from 'react';
import DeferredImage from './DeferredImage';

const INPUTS = ['input', 'select', 'button', 'textarea'];

interface Props {
  onSaveFile: (files: File[]) => void
}

export default function PasteImageDialog({ onSaveFile }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [pastedImages, setPastedImages] = React.useState<File[]>([]);

  const loadFilesFromPaste = (clipboardItems: DataTransferItemList) : File[] => {
    // Get the data of clipboard
    const items: DataTransferItem[] = [].slice.call(clipboardItems)
      .filter((item: DataTransferItem) => item.type.indexOf('image') !== -1);
    if (items.length === 0) {
      return [];
    }

    const files: (File | null)[] = items.map((i) => i.getAsFile());

    const result: File[] = [];
    files.forEach((f) => {
      if (f) {
        result.push(f);
      }
    });

    return result;
  };

  React.useEffect(() => {
    const pasteListener = (event: ClipboardEvent) => {
      const { activeElement } = document;

      // If no active element, or if active element is not an input or ProseMirror type
      if (!activeElement || !(INPUTS.indexOf(activeElement.tagName.toLowerCase()) >= 0
          || activeElement.classList.contains('ProseMirror'))) {
        if (event.clipboardData) {
          const images = loadFilesFromPaste(event.clipboardData.items);
          setPastedImages(images);
          // TODO setting to skip this
          setIsOpen(images.length > 0);
        }
      }
    };

    window.document.addEventListener('paste', pasteListener);

    return () => {
      window.document.removeEventListener('paste', pasteListener);
    };
  }, []);

  const handleDiscard = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    onSaveFile(pastedImages);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={handleDiscard}
    >
      <DialogTitle>Paste Reference Image</DialogTitle>
      <DialogContent>
        <Typography>
          {`Add image${pastedImages.length > 1 ? 's' : ''} to reference images?`}
        </Typography>
        {pastedImages.map((image, index) => (
          <DeferredImage
            key={image.name}
            src={URL.createObjectURL(image)}
            alt={`Pasted image ${index + 1}: ${image.name}`}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>Add</Button>
        <Button onClick={handleDiscard}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
