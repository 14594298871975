import {
  Accordion, AccordionDetails, AccordionSummary,
  Autocomplete, Box, Button, IconButton, Stack, TextField, Typography,
} from '@mui/material';
import React, { KeyboardEvent } from 'react';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Commission } from '../../../representations/Commission';
import ClickInput from '../ClickInput';
import { useCommissionContext } from '../context/commission-context/CommissionContext';
import {
  CommissionMetadata,
  getMapFromStringProperties,
  getStringPropertiesFromMap,
  StringPropertySet,
} from '../../../representations/CommissionMetadata';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import RichTextEditor from '../rich-text-editor/RichTextEditor';
import TitlePaper from '../styled-containers/TitlePaper';
import { PAPER_PADDING_SPACING } from '../../../theme/overrides/Paper';

type Props = {
  commission: Commission;
};

function CommissionDetails({ commission } : Props) {
  const commissionContext = useCommissionContext();
  const [isSavingDescription, setIsSavingDescription] = React.useState<boolean>(false);
  const [isAddingNewProperty, setIsAddingNewProperty] = React.useState<boolean>(false);
  const [newProperty, setNewProperty] = React.useState<string>('');

  const onStringPropertyChange = async (key: string, newValue: string) => {
    const metadata: CommissionMetadata = commission.commissionMetadata;
    const theMap = metadata.stringProperties !== undefined
      ? getMapFromStringProperties(metadata.stringProperties)
      : getMapFromStringProperties();
    theMap.set(key, newValue);
    metadata.stringProperties = getStringPropertiesFromMap(theMap);
    try {
      await commissionContext.modifyCommissionMetadata(commission.commissionUuid, metadata).then(() => {
      });
    } catch (e) {
      toast.error('Error occurred modifying details');
    }
  };

  const onStringPropertyKeyChange = async (key: string, newKey: string) => {
    const metadata: CommissionMetadata = commission.commissionMetadata;
    const theMap = metadata.stringProperties !== undefined
      ? getMapFromStringProperties(metadata.stringProperties)
      : getMapFromStringProperties();

    if (newKey.trim() === '') {
      theMap.delete(key);
      metadata.stringProperties = getStringPropertiesFromMap(theMap);
    } else {
      const existingValue = theMap.get(key);
      theMap.delete(key);
      theMap.set(newKey, existingValue || '');
      metadata.stringProperties = getStringPropertiesFromMap(theMap);
    }

    try {
      await commissionContext.modifyCommissionMetadata(commission.commissionUuid, metadata).then(() => {
      });
    } catch (e) {
      toast.error('Error occurred modifying details');
    }
  };

  const getPropertiesRows = (stringProperties: StringPropertySet | undefined): LeftRightColumnRow[] => {
    const theMap = stringProperties !== undefined
      ? getMapFromStringProperties(stringProperties)
      : getMapFromStringProperties();

    const rows: LeftRightColumnRow[] = [];
    theMap.forEach((v, k) => {
      const onEdited = (newValue: string) => {
        onStringPropertyKeyChange(k, newValue);
      };

      rows.push({
        key: k,
        left: (
          <ClickInput
            size="small"
            variant="boldBody1"
            initialValue={k}
            onChange={onEdited}
          />
        ),
        right: (
          <ClickInput
            size="small"
            initialValue={v}
                  // autocompleteOptions={BACKGROUND_OPTIONS} TODO get options that represents normally used elements
            onChange={(newValue) => { onStringPropertyChange(k, newValue).then(() => {}); }}
          />
        ),
      });
    });

    return rows;
  };

  const onNewPropertyCreated = (newKey:string) => {
    const metadata: CommissionMetadata = commission.commissionMetadata;
    const theMap = metadata.stringProperties !== undefined
      ? getMapFromStringProperties(metadata.stringProperties)
      : getMapFromStringProperties();

    let isExistingKey = false;
    theMap.forEach((v, k) => {
      isExistingKey = isExistingKey || k.toLowerCase().trim() === newKey.toLowerCase().trim();
    });

    if (isExistingKey) {
      toast.error('This property already exists');
      return;
    }

    onStringPropertyChange(newKey, '');
  };

  const saveDescription = async (newDescription: object) => {
    const metadata: CommissionMetadata = commission.commissionMetadata;
    metadata.description = newDescription;
    try {
      setIsSavingDescription(true);
      await commissionContext.modifyCommissionMetadata(commission.commissionUuid, metadata);
    } catch (e) {
      toast.error('Error occurred modifying description');
    } finally {
      setIsSavingDescription(false);
    }
  };

  const saveNewProperty = () => {
    if (!newProperty) {
      throw new Error('New property undefined');
    }

    onNewPropertyCreated(newProperty);
    setIsAddingNewProperty(false);
    setNewProperty('');
  };

  const discardNewProperty = () => {
    setIsAddingNewProperty(false);
    setNewProperty('');
  };

  return (
    <TitlePaper title="Details">
      <BoxFlexColumn>
        <Accordion
          defaultExpanded
          elevation={0}
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: (theme) => theme.palette.grey[400],
            padding: (theme) => theme.spacing(0, PAPER_PADDING_SPACING),
            margin: (theme) => (theme.spacing(
              -PAPER_PADDING_SPACING,
              -PAPER_PADDING_SPACING,
              0,
              -PAPER_PADDING_SPACING,
            )),
            '&.Mui-expanded:first-of-type': {
              margin: (theme) => (theme.spacing(
                -PAPER_PADDING_SPACING,
                -PAPER_PADDING_SPACING,
                0,
                -PAPER_PADDING_SPACING,
              )),
            },
          }}
        >
          <AccordionSummary
            sx={{
              padding: 0,
              height: '70px',
            }}
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="properties-accordian-content"
            id="properties-accordian-header"
          >
            <Typography variant="h3">Quick Details</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: (theme) => theme.spacing(0, 0, PAPER_PADDING_SPACING, 0) }}>
            <BoxFlexColumn>
              <LeftRightColumnComponent
                gap={1}
                rows={getPropertiesRows(commission.commissionMetadata.stringProperties)}
                alignItems="center"
                breakpoint="md"
              />
              <Box id="add-new-property-section" sx={{ width: '40%' }}>
                {isAddingNewProperty
                  ? (
                    <Stack direction="row">
                      <Autocomplete
                        options={['Crop', 'Coloring']}
                        fullWidth
                        onBlur={() => { setIsAddingNewProperty(false); }}
                        value={newProperty}
                        freeSolo
                        onSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setNewProperty(event.target.value);
                        }}
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setNewProperty(event.target.value);
                        }}
                        onKeyDown={(e: KeyboardEvent) => {
                          if (e.key === 'Escape') {
                            discardNewProperty();
                          }

                          if (e.key === 'Enter') {
                            saveNewProperty();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            autoFocus
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                      <IconButton
                        disabled={newProperty.trim() === ''}
                        color="primary"
                        aria-label="save new detail"
                        onClick={((event: React.MouseEvent<HTMLButtonElement>) => {
                        // TODO why doesnt this work maybe losing focus of textbox?
                          saveNewProperty();
                        })}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Stack>
                  )
                  : (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => { setIsAddingNewProperty(true); }}
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Add Quick Detail
                    </Button>
                  )}
              </Box>
            </BoxFlexColumn>
          </AccordionDetails>
        </Accordion>
        <BoxFlexColumn id="description-text-area">
          <Typography variant="h3">Description</Typography>
          <RichTextEditor
            readOnly={false}
            content={commission.commissionMetadata.description}
            isSaving={isSavingDescription}
            onSave={(content) => { saveDescription(content).then(() => {}); }}
          />
        </BoxFlexColumn>
      </BoxFlexColumn>
    </TitlePaper>
  );
}

export default CommissionDetails;
