import {
  Autocomplete, TextField,
} from '@mui/material';
import React, { KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { AutocompletePropsSizeOverrides } from '@mui/material/Autocomplete/Autocomplete';
import typography from '../../theme/typography';

interface Props {
  initialValue?: string
  placeholder?: string
  onChange?: (newValue: string) => void
  autocompleteOptions?: string[]
  size?: OverridableStringUnion<'small' | 'medium', AutocompletePropsSizeOverrides>
  variant?: 'body1' | 'boldBody1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: 'transparent' } },
  '&:hover': {
    '& .MuiInputBase-root': { backgroundColor: theme.palette.action.hover },
    '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: theme.palette.grey[200] } },
  },
}));

function ClickInput({
  initialValue = '',
  placeholder = '(none)',
  onChange = () => {},
  autocompleteOptions = [],
  size = 'small',
  variant = 'body1',
}:Props) {
  const [isActive, setIsActive] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  const [shouldSubmit, setShouldSubmit] = React.useState(false);

  React.useEffect(() => {
    const newValue = value.trim();
    if (shouldSubmit) {
      if (newValue !== initialValue) {
        onChange(newValue);
      }
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  const discardChanges = () => {
    setIsActive(false);
  };

  const saveChanges = () => {
    setShouldSubmit(true);
    setIsActive(false);
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      discardChanges();
    }

    if (e.key === 'Enter') {
      saveChanges();
    }
  };

  const onClick = () => {
    setIsActive(true);
  };

  const onBlur = () => {
    saveChanges();
  };

  const inputSx = { sx: typography[variant] };

  if (!isActive) {
    return (
      <StyledTextField
        size={size}
        fullWidth
        onClick={onClick}
        value={initialValue}
        inputProps={{ ...inputSx }}
        placeholder={placeholder}
      />
    );
  }

  return (
    <Autocomplete
      size={size}
      fullWidth
      freeSolo
      open={autocompleteOptions.length > 0}
      openOnFocus
      blurOnSelect
      options={autocompleteOptions}
      onBlur={onBlur}
      onInput={onInput}
      onKeyDown={(e) => { onKeyDown(e); }}
      onChange={(event: React.SyntheticEvent, newValue: string | null) => {
        if (newValue === null) {
          setValue('');
        } else {
          setValue(newValue);
        }
      }}
      value={initialValue}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          autoFocus
          fullWidth
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            ...inputSx,
          }}
        />
      )}
    />
  );
}

export default ClickInput;
