import React from 'react';
import AddCommissionComponent from '../AddCommissionComponent';
import TitlePaper from '../styled-containers/TitlePaper';

export default function DashboardQuickAddCommissionComponent() {
  const onCompletedAddCommission = () => {
  };

  return (
    <TitlePaper title="Quick Add">
      <AddCommissionComponent onComplete={onCompletedAddCommission} />
    </TitlePaper>
  );
}
