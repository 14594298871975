import {
  AppBar, Toolbar,
} from '@mui/material';
import React from 'react';

interface Props {
  children: JSX.Element
}

export const TOP_APP_BAR_HEIGHT = 80;

export default function TopAppBar({ children }:Props) {
  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: TOP_APP_BAR_HEIGHT,
        padding: '0px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Toolbar>
        {children}
      </Toolbar>
    </AppBar>
  );
}

/*

TODO
* Create a component in which both public and private parts of the pages can use
top bars for, with logos easily.
* they will have different content though
* Logo will now appear on left, not right

 */
