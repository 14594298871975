import React, { useEffect } from 'react';
import {
  FileWithPath, useDropzone,
} from 'react-dropzone';
import {
  Box, CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import BoxFlexRow from './styled-containers/BoxFlexRow';
import CenteredPlusIconAndAddImagesComponent from './CenteredPlusIconAndAddImagesComponent';

export interface FileWithPreview extends FileWithPath {
  preview: string;
}

export interface UploadProgress {
  fileName: string
  current: number
  total: number
}

interface Props {
  onFilesChanged?: (files: FileWithPreview[]) => void
  uploadProgress: UploadProgress[]
  maxFileBytes: number
  shouldDeleteFileCounter?: number
  parentFiles?: File[]
  children?: React.ReactNode
}

const defaultUploadText: React.ReactNode = (
  <CenteredPlusIconAndAddImagesComponent text="Click or drag items to add images" />
);

export default function FileUploadComponent({
  uploadProgress,
  onFilesChanged = () => {},
  maxFileBytes,
  shouldDeleteFileCounter = 0,
  parentFiles,
  children = defaultUploadText,
}:Props) {
  const [files, setFiles] = React.useState<FileWithPreview[]>([]);

  const startUpload = (acceptedFiles: File[]) => {
    const anyTooBig = acceptedFiles.find((f) => f.size > maxFileBytes);
    if (anyTooBig !== undefined) {
      const sizeString = (maxFileBytes) / 1048576.0; // 1 MB in bytes
      toast.error(`Some files are too big! Larger than ${sizeString.toFixed(1)} MB`);
    } else {
      const newFiles = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      setFiles(newFiles);
      onFilesChanged(newFiles);
    }
  };

  const {
    getRootProps, getInputProps,
  } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: startUpload,
  });

  // When parent files changes, initiate an upload of the files
  React.useEffect(() => {
    if (parentFiles && parentFiles.length > 0) {
      startUpload(parentFiles);
    }
  }, [parentFiles]);

  const getUploadProgressComponent = (fileName: string) => {
    const fileProgress = uploadProgress.find((f) => f.fileName === fileName);
    if (fileProgress !== undefined) {
      const progressOutOf100 = Math.round((100 * fileProgress.current) / fileProgress.total);
      return (
        <BoxFlexRow sx={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 150,
          height: '100%',
          width: '100%',
          backgroundColor: 'white',
          opacity: 0.8,
        }}
        >
          <CircularProgress
            variant="determinate"
            value={progressOutOf100}
          />
        </BoxFlexRow>
      );
    }
    return null;
  };

  useEffect(() => {
    setFiles([]);
  }, [shouldDeleteFileCounter]);

  if (files.length < 1) {
    return (
      <Box
        id="drop-area"
        sx={{ border: '2px dashed #eaeaea' }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        {children}
      </Box>
    );
  }

  return (
    <Box>
      { files.length > 0 && (
      <BoxFlexRow
        id="thumbnail-container"
        sx={{ height: 150 }}
      >
        {files.map((file) => (
          <Box
            key={file.name}
            sx={{
              border: '1px solid #eaeaea',
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            {getUploadProgressComponent(file.name)}
            <img
              alt={file.name}
              src={file.preview}
              style={{
                display: 'block',
                height: '100%',
              }}
              onLoad={() => {
                // Revoke data uri after image is loaded
                URL.revokeObjectURL(file.preview);
              }}
            />
          </Box>
        ))}
        <Box
          id="drop-area"
          sx={{ border: '2px dashed #eaeaea' }}
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />
          <CenteredPlusIconAndAddImagesComponent />
        </Box>
      </BoxFlexRow>
      )}
    </Box>
  );
}
