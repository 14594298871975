import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Client } from 'src/representations/Client';
import ApiError, {
  ERROR_ARCHIVE_CLIENT,
  ERROR_CREATE_CLIENT,
  ERROR_GET_ACTIVE_CLIENTS,
  ERROR_GET_ALL_USER_CLIENTS,
  ERROR_GET_ARCHIVED_CLIENTS,
  ERROR_MODIFY_CLIENT,
  ERROR_UNARCHIVE_CLIENT,
} from './ApiError';
import { ClientWritable } from '../representations/ClientWritable';
import DuplicateItemError from './DuplicateItemError';
import { SERVER_URL } from './SharedApiStuff';

/**
 * Calls creation of client and then returns the client object
 *
 * @param client
 * @returns {Promise<Client>}
 */
export async function addClient(client: ClientWritable) {
  try {
    const url = `${SERVER_URL}/api/v1/client`;
    const response = await axios.post(url, { client }, { withCredentials: true });

    return response.data as Client;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      if (error.response !== undefined && error.response.status === 409) {
        throw new DuplicateItemError('Email already exists', 'email');
      }
    }

    throw new ApiError('Error creating client', ERROR_CREATE_CLIENT, e);
  }
}

/**
 * Calls modification of client and then returns the client object
 *
 * @param clientUuid
 * @param client
 * @returns {Promise<Client>}
 */
export async function modifyClient(clientUuid: string, client: ClientWritable) {
  try {
    const url = `${SERVER_URL}/api/v1/client/${clientUuid}`;
    const response = await axios.put(url, { client }, { withCredentials: true });

    return response.data as Client;
  } catch (e) {
    throw new ApiError('Error modifying client', ERROR_MODIFY_CLIENT, e);
  }
}

interface PutArchiveClientRequest {
  clientUuids: string[]
}

/**
 * Archive client
 *
 * @param clientUuids
 * @returns {Promise<void>}
 */
export async function archiveClient(clientUuids: string[]) {
  try {
    const url = `${SERVER_URL}/api/v1/client/archive`;
    const data: PutArchiveClientRequest = { clientUuids };
    await axios.put(url, data, { withCredentials: true });
  } catch (e) {
    toast.error('Error occurred');
    throw new ApiError('Error archiving client', ERROR_ARCHIVE_CLIENT, e);
  }
}

interface PutUnarchiveClientRequest {
  clientUuids: string[]
}

/**
 * Unarchive client
 *
 * @returns {Promise<void>}
 * @param clientUuids
 */
export async function unarchiveClient(clientUuids: string[]) {
  try {
    const url = `${SERVER_URL}/api/v1/client/unarchive`;
    const data:PutUnarchiveClientRequest = { clientUuids };
    await axios.put(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error archiving client', ERROR_UNARCHIVE_CLIENT, e);
  }
}

/**
 * Returns a list of clients that a user has
 *
 * @returns {Promise<Array<Client>>}
 */
export async function getAllUserClients() {
  try {
    const url = `${SERVER_URL}/api/v1/client`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data as Client[];
  } catch (e) {
    throw new ApiError('Error getting clients', ERROR_GET_ALL_USER_CLIENTS, e);
  }
}

/**
 * Returns a list of non-archived clients that a user has
 *
 * @returns {Promise<Array<Client>>}
 */
export async function getActiveClients() {
  try {
    const url = `${SERVER_URL}/api/v1/client/active`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data as Client[];
  } catch (e) {
    throw new ApiError('Error getting clients', ERROR_GET_ACTIVE_CLIENTS, e);
  }
}

/**
 * Returns a list of archived clients that a user has
 *
 * @returns {Promise<Array<Client>>}
 */
export async function getArchivedClients() {
  try {
    const url = `${SERVER_URL}/api/v1/client/archived`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data as Client[];
  } catch (e) {
    throw new ApiError('Error getting clients', ERROR_GET_ARCHIVED_CLIENTS, e);
  }
}
