import React from 'react';
import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableOptions,
} from 'mui-datatables';
import {
  TableCell, TableRow,
} from '@mui/material';
import { Draggable } from '@fullcalendar/interaction';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Duration } from '@fullcalendar/core';
import { Commission } from '../../../representations/Commission';
import { useUserContext } from '../context/user-context/UserContext';

interface TableData {
  label: string
  commissionUuid: string
  dueDate: Date | undefined
  startDate: Date | undefined
  status: string
}

interface CalendarDraggableRowProps extends TableData {
  onCommissionClicked: (element: HTMLElement, commissionUuid: string) => void
  isExternallyHovered: boolean
  onHoverChanged: (isHovered: boolean) => void
}

function CalendarDraggableRow({
  commissionUuid,
  status,
  label,
  dueDate,
  startDate,
  onCommissionClicked,
  isExternallyHovered,
  onHoverChanged,
}:CalendarDraggableRowProps) {
  const eventRef = React.useRef<HTMLElement>(null);
  const userContext = useUserContext();

  React.useEffect(() => {
    if (eventRef.current) {
      let duration: Duration | undefined;
      if (dueDate && startDate) {
        const durationInMs = dueDate.getTime() - startDate.getTime(); // calculate duration in milliseconds
        const durationInDays = Math.ceil(durationInMs / (1000 * 60 * 60 * 24)); // round up duration to days
        duration = {
          milliseconds: 0, months: 0, specifiedWeeks: false, years: 0, days: durationInDays,
        };
      }

      const draggable = new Draggable(
        eventRef.current,
        {
          eventData: (eventEl) => ({
            id: commissionUuid,
            title: 'commissionUuid',
            duration,
          }),
        },
      );

      return () => {
        draggable.destroy();
      };
    }
    return () => {};
  }, [eventRef]);

  return (
    <TableRow
      sx={{
        backgroundColor: isExternallyHovered ? (theme) => theme.palette.action.hover : 'inherit',
        '&:hover': { backgroundColor: (theme) => theme.palette.action.hover },
      }}
      onMouseEnter={() => { onHoverChanged(true); }}
      onMouseLeave={() => { onHoverChanged(false); }}
    >
      <TableCell
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: (theme) => theme.spacing(0),
          '&:hover': { cursor: 'pointer' },
        }}
        ref={eventRef}
      >
        <DragIndicatorIcon />
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={(event) => { onCommissionClicked(event.currentTarget, commissionUuid); }}
      >
        {label}
      </TableCell>
      <TableCell>{dueDate ? userContext.formatDate(dueDate) : '-'}</TableCell>
    </TableRow>
  );
}

interface Props {
  commissions: Commission[]
  onCommissionClicked: (element: HTMLElement, commissionUuid: string) => void
  externallyHoveredCommissionUuid: string | null
  onHoveredCommissionChanged: (commissionUuid: string | null) => void
}

/**
 * Shows a list of Commissions,their statuses, dates (if they have them), and acts as a source of draggable elements
 * that can be used to drop onto a calendar in order to reschedule a commission (or schedule in the case that a schedule
 * does not exist yet)
 */
export default function CalendarListComponent({
  commissions, onCommissionClicked, externallyHoveredCommissionUuid, onHoveredCommissionChanged,
}: Props) {
  const data: TableData[] = commissions.map((c) => ({
    label: c.label,
    commissionUuid: c.commissionUuid,
    dueDate: c.commissionSchedule?.endDate ? c.commissionSchedule.endDate : undefined,
    startDate: c.commissionSchedule?.startDate ? c.commissionSchedule.startDate : undefined,
    status: c.statusUuid,
  }));

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'commissionUuid',
      label: 'commissionUuid',
      options: { display: 'excluded' },
    },
    {
      name: 'dragIndicatorPlaceholder',
      label: ' ',
      options: { sort: false },
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'label',
      label: 'Label',
    },
    {
      name: 'startDate',
      label: 'startDate',
      options: { display: 'excluded' },
    },
    {
      name: 'dueDate',
      label: 'Date Due',
    },
  ];

  const customRowRender = (rowData: any[], dataIndex: number, rowIndex: number) => {
    const [commissionUuid, dragIndicatorPlaceholder, status, label, startDate, dueDate] = rowData;

    return (
      <CalendarDraggableRow
        key={commissionUuid}
        commissionUuid={commissionUuid}
        status={status}
        label={label}
        dueDate={dueDate}
        startDate={startDate}
        onCommissionClicked={onCommissionClicked}
        isExternallyHovered={commissionUuid === externallyHoveredCommissionUuid}
        onHoverChanged={(isHovered) => {
          if (isHovered) {
            onHoveredCommissionChanged(commissionUuid);
          } else {
            onHoveredCommissionChanged(null);
          }
        }}
      />
    );
  };

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customRowRender,
    tableBodyHeight: '51.2vh',
  };

  return (
    <MUIDataTable
      title="Commissions"
      data={data}
      columns={columns}
      options={options}
    />
  );
}
