import React from 'react';
import {
  Button, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DarkTransparentPaper } from '../styled-containers/DarkTransparentPaper';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

type Props = {
  submissionUuid: string;
};

export default function SubmittedRequestPageComponent({ submissionUuid }:Props) {
  return (
    <DarkTransparentPaper>
      <BoxFlexColumn>
        <Typography variant="h3">Request Submitted</Typography>
        <Typography variant="body1">Thank you. An email will be sent to you confirming your request.</Typography>
        <Typography variant="body1">You can view your submitted form with the button below.</Typography>
        <Button
          variant="contained"
          component={Link}
          to={`/requests/details/${submissionUuid}`}
        >
          View Submitted Request
        </Button>
      </BoxFlexColumn>
    </DarkTransparentPaper>
  );
}
