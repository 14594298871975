import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const BoxFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  gap: theme.spacing(2),
}));

export default BoxFlexRow;
