import React from 'react';

import 'react-calendar/dist/Calendar.css';
import {
  Button, Stack, TextField,
} from '@mui/material';
import Moment from 'moment';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

interface Props {
  onDateSelected?: (date: Date | null)=>void
  value?: Date
  showTextDateInput?: boolean
  isSaving?: boolean
  dateFormatter: (date: Date) => string;
}

export default function SchedulerCalendar({
  onDateSelected = () => {},
  value,
  showTextDateInput = false,
  isSaving = false,
  dateFormatter,
}: Props) {
  const [dateValue, setDateValue] = React.useState<Date | null>(value || null);

  const onDateChanged = (date: Date | null) => {
    const theValue = date && (date.getDate() !== dateValue?.getDate()) ? date : null;
    setDateValue(theValue);
    onDateSelected(theValue);
  };

  const rows: LeftRightColumnRow[] = [
    {
      key: '1',
      left: 'Date',
      right: (
        <TextField
          size="small"
          type="date"
          value={dateValue ? Moment(dateValue).format('yyyy-MM-DD') : ''}
          disabled={isSaving}
          onClick={(e) => {
            e.preventDefault(); // Prevent HTML5 datepicker
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newDate = Moment(e.currentTarget.value, 'YYYY-MM-DD').toDate();
            setDateValue(newDate);
            onDateChanged(newDate);
          }}
        />
      ),
    },
  ];

  return (
    <BoxFlexColumn>
      {showTextDateInput && (
      <LeftRightColumnComponent
        rows={rows}
        breakpoint="xl"
        leftWidthPercentage={35}
      />
      )}
      <Stack direction="row" spacing={2}>
        <Button
          disabled={isSaving}
          onClick={() => {
            setDateValue(null);
            onDateChanged(null);
          }}
        >
          Clear
        </Button>
      </Stack>
    </BoxFlexColumn>
  );
}
