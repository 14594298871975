import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailIcon from '@mui/icons-material/Email';
import ViewRequestSubmissionWithoutClientComponent from './ViewRequestSubmissionWithoutClientComponent';
import { RequestFormClientSubmissionWithFiles } from '../../../representations/RequestFormClientSubmission';
import * as RequestApi from '../../../api/RequestsApi';
import { useCommissionContext } from '../context/commission-context/CommissionContext';
import { useClientContext } from '../context/client-context/ClientContext';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import { useUserContext } from '../context/user-context/UserContext';
import { usePollingDataContext } from '../context/polling-data-context/PollingDataContext';
import TitlePaper from '../styled-containers/TitlePaper';

interface Props {
  submissionObject?: RequestFormClientSubmissionWithFiles | null;
  dateFormatter: (date: Date) => string;
  submissionUpdated: () => void;
}

export default function RequestReviewComponent({
  submissionObject,
  dateFormatter,
  submissionUpdated,
}: Props) {
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = React.useState<boolean>(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = React.useState<boolean>(false);
  const [isSubmittingReject, setIsSubmittingReject] = React.useState<boolean>(false);
  const [isSubmittingAccept, setIsSubmittingAccept] = React.useState<boolean>(false);

  const [isReasonCheckboxSelected, setIsReasonCheckboxSelected] = React.useState<boolean>(false);
  const [acceptLabel, setAcceptLabel] = React.useState('');
  const [rejectReason, setRejectReason] = React.useState('');

  const userContext = useUserContext();
  const commissionContext = useCommissionContext();
  const clientContext = useClientContext();
  const pollingDataContext = usePollingDataContext();

  if (submissionObject === null) {
    return (
      <Paper>Choose one on the left</Paper>
    );
  }

  if (submissionObject === undefined || !userContext.isFirstLoaded) {
    return (
      <Paper>Loading</Paper>
    );
  }

  if (!userContext.settings) {
    throw new Error('Settings cannot be undefined after userContext loaded');
  }

  const onAcceptClicked = () => {
    setIsAcceptDialogOpen(true);
  };

  const onAcceptSubmitClicked = async () => {
    try {
      setIsSubmittingAccept(true);
      const newCommissionUuid = await RequestApi.acceptRequest(submissionObject.submissionUuid, acceptLabel);
      await commissionContext.reloadCommission(newCommissionUuid);
      clientContext.reloadClients();
      submissionUpdated();
      setIsAcceptDialogOpen(false);
      setTimeout(() => {
        // Set this later to allow the box to close completely before toggling the switch
        setAcceptLabel('');
      }, 100);
      pollingDataContext.pollDataNow();
    } catch (e) {
      toast.error('Error accepting request');
      console.error(e);
    } finally {
      setIsSubmittingAccept(false);
    }
  };

  const onRejectClicked = () => {
    setIsRejectDialogOpen(true);
  };

  const onRejectSubmitClicked = async () => {
    try {
      setIsSubmittingReject(true);
      await RequestApi.rejectRequest(submissionObject.submissionUuid, rejectReason);
      setIsRejectDialogOpen(false);
      submissionUpdated();
      setTimeout(() => {
        // Set this later to allow the box to close completely before toggling the switch
        setIsReasonCheckboxSelected(false);
        setRejectReason('');
      }, 100);
      pollingDataContext.pollDataNow();
    } catch (e) {
      toast.error('Error rejecting request');
      console.error(e);
    } finally {
      setIsSubmittingReject(false);
    }
  };

  const details: [string, string][] = [
    ['Name', submissionObject.clientName],
    ['Email', submissionObject.clientEmail],
    ['Date Received', dateFormatter(submissionObject.dateCreated)],
  ];

  if (submissionObject.decision !== null) {
    if (submissionObject.decision) {
      details.push(['Accepted/Rejected', 'Accepted']);
    } else {
      details.push(['Accepted/Rejected', 'Rejected']);
      details.push(['Reason Given', !submissionObject.reason ? '(None)' : submissionObject.reason]);
    }
  }

  const sendAcceptEmailText = userContext.settings.clientEmails.isSendClientAcceptEmail
    ? 'An email will be sent to the client accepting their request'
    : undefined;
  const sendDeclineEmailText = userContext.settings.clientEmails.isSendClientDeclineEmail
    ? 'An email will be sent to the client declining their request'
    : undefined;
  const declineReasonEmailText = userContext.settings.clientEmails.isSendClientDeclineEmail
    ? 'This will be included in the decline email'
    : undefined;

  return (
    <Box>
      <BoxFlexColumn>
        <TitlePaper title="Request Details">
          <BoxFlexColumn>
            {details.map((detail) => (
              <BoxFlexRow
                key={detail[0]}
              >
                <Typography variant="boldBody1" sx={{ width: '40%' }}>{detail[0]}</Typography>
                <Typography variant="body1" sx={{ }}>{detail[1]}</Typography>
              </BoxFlexRow>
            ))}
            { submissionObject.decision === null && (
            <BoxFlexRow>
              <Button variant="contained" startIcon={<CheckCircleOutlinedIcon />} onClick={onAcceptClicked}>
                Accept
              </Button>
              <Button variant="outlined" startIcon={<CancelOutlinedIcon />} onClick={onRejectClicked}>
                Reject
              </Button>
            </BoxFlexRow>
            )}
            {submissionObject.decision === true && (
            <Box>
              <Link
                to={`/app/commissions/details/${submissionObject.commissionUuid}`}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained">
                  Go to commission
                </Button>
              </Link>
            </Box>
            )}
          </BoxFlexColumn>
        </TitlePaper>
        <ViewRequestSubmissionWithoutClientComponent requestFormSubmission={submissionObject} />

        <Dialog
          open={isAcceptDialogOpen}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Accept Request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Accept this request?
              { sendAcceptEmailText && (
              <Stack direction="row" spacing={1}>
                <Typography>
                  (
                </Typography>
                <EmailIcon />
                <Typography>
                  {sendAcceptEmailText}
                </Typography>
                <Typography>
                  )
                </Typography>
              </Stack>
              )}
            </DialogContentText>
            <TextField
              autoFocus
              disabled={isSubmittingAccept}
              margin="dense"
              label="Commission Label"
              type="text"
              fullWidth
              variant="standard"
              value={acceptLabel}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAcceptLabel(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <BoxFlexRow>
              <LoadingButton
                loading={isSubmittingAccept}
                variant="contained"
                onClick={onAcceptSubmitClicked}
              >
                Confirm Accept
              </LoadingButton>
              <Button
                disabled={isSubmittingAccept}
                onClick={() => {
                  setIsAcceptDialogOpen(false);
                  setTimeout(() => {
                  // Set this later to allow the box to close completely before toggling the switch
                    setAcceptLabel('');
                  }, 100);
                }}
              >
                Cancel
              </Button>
            </BoxFlexRow>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isRejectDialogOpen}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Reject Request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Reject this request?
              { sendDeclineEmailText && (
              <Stack direction="row" spacing={1}>
                <Typography>
                  (
                </Typography>
                <EmailIcon />
                <Typography>
                  {sendDeclineEmailText}
                </Typography>
                <Typography>
                  )
                </Typography>
              </Stack>
              )}
              <FormGroup>
                <FormControlLabel
                  label="Add Rejection Reason"
                  disabled={isSubmittingReject}
                  control={(
                    <Switch
                      checked={isReasonCheckboxSelected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIsReasonCheckboxSelected(event.target.checked);
                        setRejectReason('');
                      }}
                    />
                    )}
                />
              </FormGroup>
            </DialogContentText>

            <Collapse in={isReasonCheckboxSelected}>
              <Fade in={isReasonCheckboxSelected}>
                <Box>
                  <DialogContentText>
                    (Optional) Enter a reason for rejecting this request.
                    { declineReasonEmailText && (
                    <Stack direction="row" spacing={1}>
                      <Typography>
                        (
                      </Typography>
                      <EmailIcon />
                      <Typography>
                        {declineReasonEmailText}
                      </Typography>
                      <Typography>
                        )
                      </Typography>
                    </Stack>
                    )}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    disabled={isSubmittingReject}
                    margin="dense"
                    label="Reason"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={rejectReason}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRejectReason(e.target.value);
                    }}
                  />
                </Box>
              </Fade>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <BoxFlexRow>
              <LoadingButton
                loading={isSubmittingReject}
                variant="contained"
                onClick={onRejectSubmitClicked}
              >
                Confirm Rejection
              </LoadingButton>
              <Button
                disabled={isSubmittingReject}
                onClick={() => {
                  setIsRejectDialogOpen(false);
                  setTimeout(() => {
                  // Set this later to allow the box to close completely before toggling the switch
                    setIsReasonCheckboxSelected(false);
                    setRejectReason('');
                  }, 100);
                }}
              >
                Cancel
              </Button>
            </BoxFlexRow>
          </DialogActions>
        </Dialog>
      </BoxFlexColumn>
    </Box>
  );
}
