import { toast } from 'react-toastify';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Commission } from '../../representations/Commission';
import { useCommissionContext } from './context/commission-context/CommissionContext';

interface Props {
  isOpen: boolean
  commission: Commission
  onClose: () => void
}
export default function EditCommissionLabelDialog({
  isOpen,
  commission,
  onClose,
}: Props) {
  const commissionContext = useCommissionContext();

  const [isSavingCommissionLabel, setIsSavingCommissionLabel] = React.useState(false);
  const [newLabel, setNewLabel] = React.useState(commission.label);

  const saveCommissionLabel = async () => {
    try {
      setIsSavingCommissionLabel(true);
      await commissionContext.modifyCommission(commission.commissionUuid, {
        clientUuid: commission.clientUuid,
        commissionMetadata: commission.commissionMetadata,
        label: newLabel,
      });
      onClose();
    } catch (e) {
      toast.error('Error saving label');
      console.error(e);
    } finally {
      setIsSavingCommissionLabel(false);
    }
  };

  const handleDiscard = () => {
    setNewLabel(commission.label);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      onClose={handleDiscard}
    >
      <DialogTitle>Edit Label</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Commission Label"
          type="text"
          fullWidth
          variant="standard"
          value={newLabel}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewLabel(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDiscard}>Cancel</Button>
        <LoadingButton
          disabled={newLabel === commission.label || newLabel.trim() === ''}
          loading={isSavingCommissionLabel}
          onClick={saveCommissionLabel}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
