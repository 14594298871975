import React from 'react';
import {
  Box,
  Button,
  Fab,
  FormControl,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TitlePaper from '../styled-containers/TitlePaper';
import {
  LeftRightColumnComponent, LeftRightColumnRow,
} from '../LeftRightColumnComponent';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import {
  IssueImportance, IssueType,
} from '../../../representations/FeedbackReport';
import ConfirmationDialog from '../ConfirmationDialog';
import { submitReport } from '../../../api/FeedbackReportApi';

export default function FeedbackReporter() {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [currentPage, setCurrentPage] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [issueType, setIssueType] = React.useState<IssueType>(IssueType.Unsure);
  const [issueImportance, setIssueImportance] = React.useState<IssueImportance>(IssueImportance.Unsure);

  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [isConfirmingReset, setIsResetConfirmDialogOpen] = React.useState(false);
  const [isConfirmingSubmitDialogOpen, setIsConfirmingSubmitDialogOpen] = React.useState(false);
  const [isSubmittingReport, setIsSubmittingReport] = React.useState(false);

  const resetForm = () => {
    setIssueType(IssueType.Unsure);
    setIssueImportance(IssueImportance.Unsure);
    setDescription('');
    setIsFormDirty(false);
  };

  const isOpen = Boolean(anchorEl);

  const onClickReportIssue = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentPage(location.pathname);
    setAnchorEl(event.currentTarget);
  };

  const getConfirmationMessage = () => {
    const confirmRows: LeftRightColumnRow[] = [
      {
        key: 'Page',
        left: 'Page (auto-filled)',
        right: (
          <TextField
            size="small"
            disabled
            fullWidth
            multiline
            value={currentPage}
          />
        ),
      },
      {
        key: 'type',
        left: 'Issue Type',
        right: (
          <TextField
            size="small"
            disabled
            fullWidth
            multiline
            value={issueType}
          />
        ),
      },
      {
        key: 'importance',
        left: 'Issue Importance',
        right: (
          <TextField
            size="small"
            disabled
            fullWidth
            multiline
            value={issueImportance}
          />
        ),
      },
      {
        key: 'Description',
        left: 'Description',
        right: (
          <TextField
            size="small"
            disabled
            fullWidth
            multiline
            value={description}
          />),
      },
    ];

    return (
      <LeftRightColumnComponent rows={confirmRows} leftWidthPercentage={30} />
    );
  };

  const rows: LeftRightColumnRow[] = [
    {
      key: 'Page',
      left: 'Page (auto-filled)',
      right: <TextField
        disabled
        size="small"
        value={currentPage}
        fullWidth
      />
      ,
    },
    {
      key: 'type',
      left: 'Issue Type',
      right: (
        <FormControl
          size="small"
          fullWidth
        >
          <Select
            value={issueType}
            onChange={(event: SelectChangeEvent) => {
              setIssueType(event.target.value as IssueType);
              setIsFormDirty(true);
            }}
          >
            <MenuItem value={IssueType.Unsure}>Unsure</MenuItem>
            <MenuItem value={IssueType.Bug}>Bug</MenuItem>
            <MenuItem value={IssueType.Cosmetic}>Cosmetic</MenuItem>
            <MenuItem value={IssueType.Suggestion}>Suggestion</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      key: 'importance',
      left: 'Issue Importance',
      right: (
        <FormControl
          size="small"
          fullWidth
        >
          <Select
            value={issueImportance}
            onChange={(event: SelectChangeEvent) => {
              setIssueImportance(event.target.value as IssueImportance);
              setIsFormDirty(true);
            }}
          >
            <MenuItem value={IssueImportance.Unsure}>Unsure / Let feng decide</MenuItem>
            <MenuItem value={IssueImportance.Critical}>Critical / Blocks my work</MenuItem>
            <MenuItem value={IssueImportance.High}>High / Very annoying</MenuItem>
            <MenuItem value={IssueImportance.Medium}>Medium / Mildly annoying</MenuItem>
            <MenuItem value={IssueImportance.Low}>Low / Not too bad</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      key: 'Description',
      left: 'Description',
      right: <TextField
        size="small"
        value={description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setDescription(event.target.value);
          setIsFormDirty(true);
        }}
        fullWidth
        multiline
        minRows={4}
        placeholder="Describe issue - Even two or three words is okay, feng will find out, but more details is better 😳"
        helperText="Please describe the issue/improvement here.
        The page you are on is automatically recorded so feng will know what you're looking at."
      />
      ,
    },
  ];

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 10000,
        bottom: 0,
        right: 0,
        padding: (theme) => theme.spacing(3),
      }}
    >
      <ConfirmationDialog
        id="report-discard-confirmation-dialog"
        open={isConfirmingReset}
        confirmButtonText="Discard"
        title="Discard Report Details"
        confirmationMessage="Reset all details of the report?"
        onConfirmedClicked={() => {
          resetForm();
          setIsResetConfirmDialogOpen(false);
        }}
        onClosedOrCancelClicked={() => {
          setIsResetConfirmDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        id="report-confirmation-dialog"
        open={isConfirmingSubmitDialogOpen}
        confirmButtonText="Confirm and Submit"
        title="Confirm Report Details"
        confirmationMessage={getConfirmationMessage()}
        onConfirmedClicked={async () => {
          // Loading button
          setIsSubmittingReport(true);
          try {
            await submitReport({
              description,
              importance: issueImportance,
              page: currentPage,
              type: issueType,
            });
            setIsConfirmingSubmitDialogOpen(false);
            resetForm();
            setAnchorEl(null);
            toast.info('Thanks for submitting a report!');
          } catch (e) {
            console.error(e);
            toast.error('Something went wrong submitting a report - Plz contact feng!');
          } finally {
            setIsSubmittingReport(false);
          }
        }}
        onClosedOrCancelClicked={() => {
          setIsConfirmingSubmitDialogOpen(false);
        }}
        isSubmittingConfirmation={isSubmittingReport}
      />
      <Popover
        open={isOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { padding: 0 } }}
      >
        <TitlePaper
          title="Report Issue"
          color="secondary"
        >
          <BoxFlexColumn>
            <Typography variant="body1">
              Report issues to help feng figure out where to fix up the app 😊
            </Typography>
            <LeftRightColumnComponent rows={rows} leftWidthPercentage={30} />
          </BoxFlexColumn>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
          >
            <Stack gap={3} direction="row">
              <Button
                variant="contained"
                disabled={!isFormDirty}
                onClick={() => {
                  setIsConfirmingSubmitDialogOpen(true);
                }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                disabled={!isFormDirty}
                onClick={() => {
                  setIsResetConfirmDialogOpen(true);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Box>
        </TitlePaper>
      </Popover>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={onClickReportIssue}
      >
        <AnnouncementIcon />
        Report Issue
      </Fab>
    </Box>
  );
}
