import './ProseMirrorStyles.css';
import {
  useEditor, EditorContent, Editor, JSONContent,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { KeyboardEvent } from 'react';
import {
  Box, Button, Stack,
} from '@mui/material';
import {
  styled, useTheme,
} from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Theme } from '@mui/material/styles/createTheme';
import MenuBar from './MenuBar';
import { MuiOutlinedInputRoot } from '../../../theme/overrides/Input';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';

const StyledEditor = styled(Box)(({ theme }) => ({
  ...MuiOutlinedInputRoot(theme).normal,
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  color: '#0d0d0d',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '26rem',
}));

const StyledEditorContent = styled(EditorContent, { shouldForwardProp: (prop) => prop !== 'isEditing' })(({
  theme, isEditing,
}:{
  theme: Theme,
  isEditing: boolean
}) => ({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: theme.spacing(1),
  minHeight: isEditing ? '6rem' : '3rem',
  WebkitOverflowScrolling: 'touch',
}));

const getJsonContentFromString = (original: string) => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: original,
        },
      ],
    },
  ],
});

const defaultContent = getJsonContentFromString('');

interface Props {
  autoFocus?: boolean
  readOnly: boolean
  isSaving?: boolean
  content?: JSONContent | string
  onSave?: (content: JSONContent, isEmpty: boolean) => void
  onCancel?: () => void
  // Always show controls for the editor when it is visible. Useless when readOnly == true.
  alwaysShowControls?: boolean
  // Calls onSave immediately on every change, to notify external components of value changes.
  controlledMode?: boolean
}

export default function RichTextEditor({
  autoFocus = false,
  readOnly,
  isSaving = false,
  onSave = () => {},
  onCancel = () => {},
  content = defaultContent,
  alwaysShowControls = false,
  controlledMode = false,
}:Props) {
  const theme = useTheme();

  const [editingControlsEnabled, setEditingControlsEnabled] = React.useState(false);
  const [lastSavedContent, setLastSavedContent] = React.useState<JSONContent>({});

  const [calledJustOnceYet, setCalledJustOnceYet] = React.useState(false);

  const internalSave = (theContent: JSONContent, isEmpty: boolean) => {
    setLastSavedContent(theContent);
    onSave(theContent, isEmpty);
  };

  const newContent = typeof content !== 'string'
    ? content
    : getJsonContentFromString(content);

  const editor = useEditor({
    onUpdate: ({ editor: theEditor }) => {
      // Controlled value mode - can still use same onSave function
      if (controlledMode) {
        internalSave(theEditor.getJSON(), theEditor.isEmpty);
      }
    },
    extensions: [
      StarterKit,
    ],
    content: newContent,
    editable: false,
  });

  const toggleEditing = (theEditor: Editor, isNowEditing: boolean) => {
    if (!readOnly) {
      theEditor.setEditable(isNowEditing);
      setEditingControlsEnabled(isNowEditing);

      if (isNowEditing && autoFocus) {
        theEditor.commands.focus();
      }
    }
  };

  const onEditorFocused = () => {
    if (!editor) {
      throw new Error('editor is null');
    }

    toggleEditing(editor, true);
  };

  const onSaveClicked = () => {
    if (!editor) {
      throw new Error('editor is null');
    }

    const theContent = editor.getJSON();
    if (theContent) {
      internalSave(theContent, editor.isEmpty);
    } else {
      throw new Error('Content was undefined');
    }
  };

  const discardChanges = () => {
    if (!editor) {
      throw new Error('editor is null');
    }

    toggleEditing(editor, false);
    editor.commands.setContent(lastSavedContent);
    onCancel();
  };

  const onDiscardClicked = () => {
    discardChanges();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (!alwaysShowControls) {
        discardChanges();
      }
    }
  };

  /**
   * Update lastSavedContent with the newest content from props, and ensure editing is disabled.
   */
  React.useEffect(() => {
    if (editor) {
      if (!editor.isFocused) {
        // Only need to set content on the editor based on outside changes when:
        // * The editor is not focused (if it's focused we don't need to change the value - it'll change internally
        editor.commands.setContent(content);
      }

      if (!controlledMode) {
        setLastSavedContent(newContent);
        toggleEditing(editor, false);
      } else {
        setCalledJustOnceYet((lastValue) => {
          if (!lastValue) {
            onSave(newContent, editor.isEmpty);
            return true;
          }
          return lastValue;
        });
      }
    }
  }, [editor, content]);

  /**
   * If alwaysShowControls, open editor with controls showing immediately
   *
   * TODO focusing on editor immediately not yet working
   */
  React.useEffect(() => {
    if (editor && alwaysShowControls) {
      toggleEditing(editor, true);
    }
  }, [editor, alwaysShowControls]);

  return (
    <BoxFlexColumn sx={{ width: '100%' }}>
      <StyledEditor>
        {editor && editingControlsEnabled && <MenuBar editor={editor} />}
        <StyledEditorContent
          theme={theme}
          isEditing={!readOnly}
          editor={editor}
          onMouseDown={onEditorFocused}
          onKeyDown={onKeyDown}
        />
      </StyledEditor>
      {editingControlsEnabled && !controlledMode && (
      <Stack direction="row" spacing={1}>
        <LoadingButton
          loading={isSaving}
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSaveClicked}
        >
          Save
        </LoadingButton>
        <Button
          disabled={isSaving}
          onClick={onDiscardClicked}
        >
          Discard
        </Button>
      </Stack>
      )}
    </BoxFlexColumn>
  );
}
