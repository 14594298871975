import React from 'react';
import {
  Box, Divider, Typography, useTheme,
} from '@mui/material';
import Page from '../components/Page';
import StyledPaper from '../components/myoc-components/StyledPaper';

export default function Page404() {
  const theme = useTheme();

  return (
    <Page
      title="404 Not Found"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledPaper>
        <Box
          component="img"
          src="/static/illustrations/illustration_404.svg"
          sx={{
            height: 260,
            mx: 'auto',
            my: {
              xs: 5, sm: 10,
            },
          }}
        />
        <Typography variant="body1" fontSize="25px" sx={{ color: 'text.secondary' }}>
          Manage Your Own Commissions
        </Typography>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: theme.palette.grey[600],
          }}
          variant="middle"
        />
        <Typography variant="body1" fontSize="19px" fontWeight="400" sx={{ color: 'text.secondary' }}>
          Couldn&apos;t find this page!
        </Typography>
      </StyledPaper>
    </Page>
  );
}
