import axios from 'axios';
import ApiError, {
  ERROR_ACCEPT_REQUEST,
  ERROR_CREATE_FORM,
  ERROR_GET_DECIDED_REQUESTS,
  ERROR_GET_FORM,
  ERROR_GET_UNDECIDED_REQUESTS,
  ERROR_REJECT_REQUEST,
} from './ApiError';
import { RequestForm } from '../representations/RequestForm';
import { RequestFormWritable } from '../representations/RequestFormWritable';
import { RequestFormClientSubmissionNoAnswers } from '../representations/RequestFormClientSubmission';
import { SERVER_URL } from './SharedApiStuff';

export async function getForm() {
  try {
    const url = `${SERVER_URL}/api/v1/request/form`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data !== undefined && response.data !== '' ? (response.data as RequestForm) : undefined;
  } catch (e) {
    throw new ApiError('Error getting form', ERROR_GET_FORM, e);
  }
}

export async function saveForm(requestForm: RequestFormWritable) {
  try {
    const url = `${SERVER_URL}/api/v1/request/form`;
    const response = await axios.post(url, { requestForm }, { withCredentials: true });

    return (response.data as RequestForm);
  } catch (e) {
    throw new ApiError('Error creating form', ERROR_CREATE_FORM, e);
  }
}

interface GetUndecidedRequestsResponse {
  requestSubmissions: RequestFormClientSubmissionNoAnswers[],
}

export async function getUndecidedRequests() {
  try {
    const url = `${SERVER_URL}/api/v1/request/received/undecided`;
    const response = await axios.get(url, { withCredentials: true });

    return (response.data as GetUndecidedRequestsResponse).requestSubmissions;
  } catch (e) {
    throw new ApiError('Error getting undecided requests', ERROR_GET_UNDECIDED_REQUESTS, e);
  }
}

interface GetDecidedRequestsResponse {
  requestSubmissions: RequestFormClientSubmissionNoAnswers[],
}

export async function getDecidedRequests() {
  try {
    const url = `${SERVER_URL}/api/v1/request/received/decided`;
    const response = await axios.get(url, { withCredentials: true });

    return (response.data as GetDecidedRequestsResponse).requestSubmissions;
  } catch (e) {
    throw new ApiError('Error getting decided requests', ERROR_GET_DECIDED_REQUESTS, e);
  }
}

interface PostRejectRequestRequest {
  reason: string;
  submissionUuid: string;
}

export async function rejectRequest(submissionUuid:string, reason: string) {
  try {
    const url = `${SERVER_URL}/api/v1/request/received/reject`;
    const data: PostRejectRequestRequest = {
      submissionUuid,
      reason,
    };

    await axios.post(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error rejecting request', ERROR_REJECT_REQUEST, e);
  }
}

interface PostAcceptRequestRequest {
  submissionUuid: string;
  label: string;
}

interface PostAcceptRequestResponse {
  commissionUuid: string;
}

export async function acceptRequest(submissionUuid:string, label:string) {
  try {
    const url = `${SERVER_URL}/api/v1/request/received/accept`;
    const data: PostAcceptRequestRequest = {
      submissionUuid,
      label,
    };

    const response: PostAcceptRequestResponse = (await axios.post(url, data, { withCredentials: true })).data;

    return response.commissionUuid;
  } catch (e) {
    throw new ApiError('Error accepting request', ERROR_ACCEPT_REQUEST, e);
  }
}

interface GetUndecidedRequestCountResponse {
  undecidedRequestCount: number
}

export async function getUndecidedRequestCount(): Promise<number> {
  try {
    const url = `${SERVER_URL}/api/v1/request/received/undecided-count`;
    const response = await axios.get(url, { withCredentials: true });

    return (response.data as GetUndecidedRequestCountResponse).undecidedRequestCount;
  } catch (e) {
    throw new ApiError('Error getting undecided requests count', 'ERROR_GET_UNDECIDED_REQUESTS_COUNT', e);
  }
}
