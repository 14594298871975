import React, { useEffect } from 'react';
import {
  useNavigate, useSearchParams,
} from 'react-router-dom';
import {
  Container, Typography,
} from '@mui/material';
import AddCommissionComponent from '../../components/myoc-components/AddCommissionComponent';
import Page from '../../components/Page';
import ItemNotFoundError from '../../api/ItemNotFoundError';
import { Commission } from '../../representations/Commission';
import StyledContainer from '../../components/myoc-components/styled-containers/StyledContainer';
import { useCommissionContext } from '../../components/myoc-components/context/commission-context/CommissionContext';

type Props = {
  existingCommission: Commission | undefined | null,
  onCompletedEditingCommission: (resultCommission: Commission)=>void,
};

function ThisPage({
  existingCommission, onCompletedEditingCommission,
} : Props) {
  if (existingCommission === undefined) {
    return (
      <Typography variant="h4">No commission id specified in url, error!</Typography>
    );
  }

  if (existingCommission === null) {
    return (
      <Typography variant="h4">Unable to find commission, error!</Typography>
    );
  }

  return (
    <Container>
      <Typography variant="h3">Edit Commission</Typography>
      <StyledContainer>
        <AddCommissionComponent
          onComplete={onCompletedEditingCommission}
          existingCommission={existingCommission}
        />
      </StyledContainer>
    </Container>
  );
}

function EditCommission() {
  const commissionContext = useCommissionContext();

  if (commissionContext.isLoading) {
    return (
      <Page title="Edit Commission">
        <Typography variant="h3">Edit Commission</Typography>
        <Typography variant="h4">Loading...</Typography>
      </Page>
    );
  }

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [existingCommission, setExistingCommission] = React.useState<Commission | undefined | null>(undefined);

  const onCompletedEditingCommission = () => {
    navigate('/app/commissions');
  };

  const getCommission = async () => {
    const commissionUuid = searchParams.get('commissionUuid');
    if (commissionUuid === undefined || commissionUuid === null) {
      return undefined;
    }

    try {
      return commissionContext.getCommissionByUuid(commissionUuid);
    } catch (e) {
      if (e instanceof ItemNotFoundError) {
        return null;
      }
      throw e;
    }
  };

  useEffect(() => {
    const lol = async () => {
      setExistingCommission(await getCommission());
    };

    lol().then(() => {});
  }, []);

  return (
    <Page title="Edit Commission">
      <ThisPage
        existingCommission={existingCommission}
        onCompletedEditingCommission={onCompletedEditingCommission}
      />
    </Page>
  );
}

export default EditCommission;
