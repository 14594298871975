import React from 'react';
import Page from 'src/components/Page';
import {
  Box, Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import CalendarComponent from '../components/myoc-components/dashboard/CalendarComponent';
import { Commission } from '../representations/Commission';
import CommissionPreviewPopover from '../components/myoc-components/CommissionPreviewPopover';
import CalendarListComponent from '../components/myoc-components/dashboard/CalendarListComponent';
import BoxFlexRow from '../components/myoc-components/styled-containers/BoxFlexRow';
import { useCommissionContext } from '../components/myoc-components/context/commission-context/CommissionContext';
import ConfirmationDialog from '../components/myoc-components/ConfirmationDialog';
import { ScheduleWritable } from '../representations/CommissionSchedule';
import { useUserContext } from '../components/myoc-components/context/user-context/UserContext';
import BoxFlexColumn from '../components/myoc-components/styled-containers/BoxFlexColumn';

export default function SchedulePage() {
  const commissionContext = useCommissionContext();
  const userContext = useUserContext();

  const [clickedCommission, setClickedCommission] = React.useState<Commission | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredCommissionUuid, setHoveredCommissionUuid] = React.useState<string | null>(null);

  const [isRescheduleDialogOpen, setIsRescheduleDialogOpen] = React.useState(false);
  const [currentlyReschedulingCommission, setCurrentlyReschedulingCommission] = React.useState<Commission | undefined>(undefined);
  const [currentlyReschedulingCommissionNewSchedule, setCurrentlyReschedulingCommissionNewSchedule] = React.useState<ScheduleWritable | undefined>(undefined);
  const [isSubmittingReschedule, setIsSubmittingReschedule] = React.useState(false);
  // const getClickedCommissionByScheduleId = (scheduleItemId: string) => commissionContext.commissions.find(
  //   (c) => c.commissionSchedule?.uuid === scheduleItemId,
  // );
  const getClickedCommission = (commissionUuid: string) => commissionContext.commissions.find(
    (c) => c.commissionUuid === commissionUuid,
  );
  // const loadEventsForRange = async (start: Date, end: Date) => {
  //   setCommissions(await CommissionApi.getCommissionSchedulesForDateRange(start, end));
  // };
  //
  // React.useEffect(() => {
  //   // TODO dynamically load events
  //   const minDate = new Date(0);
  //   const maxDate = new Date(8640000000000);
  //
  //   loadEventsForRange(minDate, maxDate);
  // }, []);

  const onCommissionClicked = (element: HTMLElement, commissionUuid: string) => {
    setAnchorEl(element);
    const c = getClickedCommission(commissionUuid);
    if (!c) {
      throw new Error("Couldn't determine clicked commission");
    }
    setClickedCommission(c);
  };

  const onEventClicked = (element: HTMLElement, eventId: string) => {
    setAnchorEl(element);
    const c = getClickedCommission(eventId);
    if (!c) {
      throw new Error("Couldn't determine clicked commission");
    }
    setClickedCommission(c);
  };

  const modifySchedule = async (commission: Commission, newSchedule: ScheduleWritable, isReschedule: boolean) => {
    setIsSubmittingReschedule(true);
    try {
      await commissionContext.modifyCommissionSchedule(commission.commissionUuid, newSchedule);
      toast.info(isReschedule ? 'Rescheduled commission' : 'Scheduled commission');
      setCurrentlyReschedulingCommission(undefined);
      setCurrentlyReschedulingCommissionNewSchedule(undefined);
      setIsRescheduleDialogOpen(false);
    } finally {
      setIsSubmittingReschedule(false);
    }
  };

  /**
     * Handles the drop of an event and creates/edits the schedule of a commission onto the
     * date that it's dropped onto.
     */
  const onCommissionDateChanged = (
    commissionUuid: string,
    commissionDueDate: Date,
    commissionStartDate?: Date,
  ) => {
    const dateDetails = !commissionStartDate
      ? {
        startDate: null,
        endDate: commissionDueDate,
      }
      : {
        startDate: commissionStartDate,
        endDate: commissionDueDate,
      };

    const commission = commissionContext.getCommissionByUuid(commissionUuid);
    const newSchedule: ScheduleWritable = {
      ...dateDetails,
      commissionUuid,
      existingUuid: commission.commissionSchedule?.uuid,
    };

    if (commission.commissionSchedule) {
      setIsRescheduleDialogOpen(true);
      setCurrentlyReschedulingCommission(commission);
      setCurrentlyReschedulingCommissionNewSchedule(newSchedule);
    } else {
      modifySchedule(commission, newSchedule, false);
    }
  };

  return (
    <Page title="Schedule">
      <ConfirmationDialog
        id="reschedule-confirmation-dialog"
        open={isRescheduleDialogOpen}
        confirmButtonText="Reschedule"
        title="Reschedule"
        isSubmittingConfirmation={isSubmittingReschedule}
        confirmationMessage={(() => {
          if (!currentlyReschedulingCommissionNewSchedule) {
            return '';
          }
          // eslint-disable-next-line max-len
          return `The commission "${currentlyReschedulingCommission?.label}" will be rescheduled to be due on ${userContext.formatDate(currentlyReschedulingCommissionNewSchedule.endDate)}`;
        })()}
        onConfirmedClicked={() => {
          if (!currentlyReschedulingCommissionNewSchedule) {
            throw new Error('currentlyReschedulingCommissionNewSchedule undefined');
          }
          if (!currentlyReschedulingCommission) {
            throw new Error('currentlyReschedulingCommission undefined');
          }
          modifySchedule(currentlyReschedulingCommission, currentlyReschedulingCommissionNewSchedule, true);
        }}
        onClosedOrCancelClicked={() => {
          setIsRescheduleDialogOpen(false);
        }}
      />
      <CommissionPreviewPopover
        commission={clickedCommission}
        anchorEl={anchorEl}
        onPopoverClosed={() => {
          setClickedCommission(null);
          setAnchorEl(null);
        }}
      />
      <BoxFlexColumn>
        <Typography variant="h1">
          Schedule
        </Typography>
        <BoxFlexRow sx={{ height: '60vh' }}>
          <Box
            sx={{ flexGrow: 1 }}
          >
            <CalendarComponent
              commissions={commissionContext.commissions}
              onEventClicked={onEventClicked}
              onDateChanged={() => {}}
              onCommissionDateChanged={onCommissionDateChanged}
              externallyHoveredCommissionUuid={hoveredCommissionUuid}
              onHoveredCommissionChanged={(x) => { setHoveredCommissionUuid(x); }}
            />
          </Box>
          <Box
            sx={{ width: 550 }}
          >
            <CalendarListComponent
              commissions={commissionContext.commissions}
              onCommissionClicked={onCommissionClicked}
              externallyHoveredCommissionUuid={hoveredCommissionUuid}
              onHoveredCommissionChanged={(x) => { setHoveredCommissionUuid(x); }}
            />
          </Box>
        </BoxFlexRow>
      </BoxFlexColumn>
    </Page>
  );
}
