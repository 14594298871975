import {
  Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import { Commission } from 'src/representations/Commission';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import ClientAvatar from '../ClientAvatar';
import { useCommissionContext } from '../context/commission-context/CommissionContext';
import { useClientContext } from '../context/client-context/ClientContext';
import { Client } from '../../../representations/Client';
import Label from '../../Label';
import { useCommissionStatusContext } from '../context/status-context/CommissionStatusContext';
import TitlePaper from '../styled-containers/TitlePaper';

type Props = {
  onCommissionClicked: (element: HTMLElement, commission: Commission) => void
};

export default function DashboardActiveCommissionslistComponent(
  { onCommissionClicked }: Props,
) {
  const commissionContextObject = useCommissionContext();
  const clientContextObject = useClientContext();
  const statusContext = useCommissionStatusContext();
  // TODO filter this for non-todo / archived/ etc commissions

  const renderAvatar = (client : Client | undefined, commission: Commission) => {
    if (client === undefined) {
      toast.error("Couldn't find client");
      console.error(`Couldn't find client with uuid ${commission.clientUuid}`);
      return (
        <ClientAvatar
          clientName="?"
        />
      );
    }
    return (
      <ClientAvatar
        clientName={client.name}
      />
    );
  };

  const renderCommissionTodo = (commission: Commission, index: number) => {
    if (clientContextObject.isLoading || statusContext.isLoading) {
      return undefined;
    }
    const client = clientContextObject.getAllClients().find((c) => c.clientUuid === commission.clientUuid);
    const status = statusContext.getCommissionStatusByUuid(commission.statusUuid);

    const avatarPart = renderAvatar(client, commission);

    const linkToDetails = `/app/commissions/details/${commission.commissionUuid}`;

    return (
      <Box key={commission.commissionUuid}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            {avatarPart}
          </ListItemAvatar>
          <ListItemText>
            <Box sx={{
              display: 'flex', flexDirection: 'row',
            }}
            >
              <Box sx={{ flex: 4 }} onClick={(event) => { onCommissionClicked(event.currentTarget, commission); }}>
                <Typography variant="h3">{commission.label}</Typography>
                <Typography variant="subtitle1">Some Action</Typography>
              </Box>
              <Box sx={{
                display: 'flex', alignItems: 'center',
              }}
              >
                <Label
                  variant="outlined"
                  color="info"
                >
                  {status.label}
                </Label>
              </Box>
            </Box>
          </ListItemText>
        </ListItem>
        {commissionContextObject.commissions.length - 1 !== index && <Divider variant="inset" component="li" />}
      </Box>
    );
  };

  if (commissionContextObject.isLoading || clientContextObject.isLoading) {
    return (
      <TitlePaper title="Active Commissions">
        <Typography variant="h3">Loading...</Typography>
      </TitlePaper>
    );
  }

  return (
    <TitlePaper title="Active Commissions">
      <List>
        { commissionContextObject.commissions.length > 0
          ? commissionContextObject.commissions.map(renderCommissionTodo)
          : (
            <Typography variant="body1">
              No active commissions yet! Start tracking one with the quick add functionality below
            </Typography>
          )}
      </List>
    </TitlePaper>
  );
}
