import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const SimplePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  width: 'fit-content',
  height: 'fit-content',
}));

export default SimplePaper;
