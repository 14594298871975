import React from 'react';
import { Box } from '@mui/material';
import DeferredImage from '../DeferredImage';
import { UploadedFile } from '../../representations/UploadedFile';

interface Props {
  image?: UploadedFile
}

/**
 * Handles showing of a preview image of a commission, or its placeholder if none exists yet
 * @constructor
 */
export default function PreviewImageContainer({ image }:Props) {
  return (
    <Box
      sx={{
        height: 150,
        width: 100,
        backgroundColor: (theme) => theme.palette.grey[300],
      }}
    >
      {/* TODO Add a icon for no image */}
      {image && (
        <DeferredImage alt={image.originalFileName} src={image.url} />
      )}
    </Box>
  );
}
