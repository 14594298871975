import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CommissionReferencesImages from 'src/components/myoc-components/commission-details/CommissionReferencesImages';
import EditIcon from '@mui/icons-material/Edit';
import SimplePaper from 'src/components/myoc-components/styled-containers/SimplePaper';
import { toast } from 'react-toastify';
import Page from '../../../components/Page';
import { useCommissionContext } from '../../../components/myoc-components/context/commission-context/CommissionContext';
import ClientDetails from '../../../components/myoc-components/commission-details/ClientDetails';
import { useClientContext } from '../../../components/myoc-components/context/client-context/ClientContext';
import CommissionDetails from '../../../components/myoc-components/commission-details/CommissionDetails';
import CommissionNotes from '../../../components/myoc-components/commission-details/CommissionNotes';
import CommissionProperties from '../../../components/myoc-components/commission-details/CommissionProperties';
import CommissionStatusDetails from '../../../components/myoc-components/commission-details/CommissionStatusDetails';
import { useCommissionStatusContext } from '../../../components/myoc-components/context/status-context/CommissionStatusContext';
import { useUserContext } from '../../../components/myoc-components/context/user-context/UserContext';
import CommissionTasks from '../../../components/myoc-components/commission-details/CommissionTasks';
import { Commission } from '../../../representations/Commission';
import { Client } from '../../../representations/Client';
import CommissionScheduleComponent
  from '../../../components/myoc-components/commission-details/CommissionScheduleComponent';
import DeferredImage from '../../../components/DeferredImage';
import { UploadedFile } from '../../../representations/UploadedFile';
import * as CommissionApi from '../../../api/CommissionApi';
import { DarkTransparentPaper } from '../../../components/myoc-components/styled-containers/DarkTransparentPaper';
import BoxFlexColumn from '../../../components/myoc-components/styled-containers/BoxFlexColumn';
import LabelledSelect, { SelectPairs } from '../../../components/myoc-components/LabelledSelect';
import { CommissionStatus } from '../../../representations/CommissionStatus';
import EditCommissionLabelDialog from '../../../components/myoc-components/EditCommissionLabelDialog';
import PasteImageDialog from '../../../components/PasteImageDialog';

const BANNER_HEIGHT = 150;

function CommissionDetailsPage() {
  const { commissionUuid } = useParams();
  const commissionContext = useCommissionContext();
  const clientContext = useClientContext();
  const statusContext = useCommissionStatusContext();
  const userContext = useUserContext();

  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [commission, setCommission] = React.useState<Commission | undefined>(undefined);
  const [client, setClient] = React.useState<Client | undefined>(undefined);
  const [bannerImage, setBannerImage] = React.useState<UploadedFile | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = React.useState<undefined | CommissionStatus>(undefined);
  const [isModifyingStatus, setIsModifyingStatus] = React.useState<boolean>(false);
  const [isClientLoading, setIsClientLoading] = React.useState(true);
  const [isCommissionLoading, setIsCommissionLoading] = React.useState(true);
  const [pastedFiles, setPastedFiles] = React.useState<File[]>([]);

  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = React.useState(false);

  // Determine the banner image - Use preview image first
  const reloadBanner = async (c: Commission) => {
    const viewableCommissionAttachments = await CommissionApi.getCommissionReferenceImages(c.commissionUuid);
    const previewImage = viewableCommissionAttachments.find(
      (a) => a.objectKey === c.commissionMetadata.commissionThumbnailObjectKey,
    );
    if (previewImage) {
      setBannerImage(previewImage.uploadedFile);
    }
  };

  const initialisePage = async () => {
    try {
      if (!commissionUuid) {
        throw new Error('No commission uuid supplied');
      }

      const c = commissionContext.getCommissionByUuid(commissionUuid);
      setCommission(c);
      setClient(clientContext.getClientByUuid(c.clientUuid));
      setSelectedStatus(statusContext.getCommissionStatusByUuid(c.statusUuid));
      await reloadBanner(c);
    } finally {
      setIsClientLoading(false);
      setIsCommissionLoading(false);
    }
  };

  useEffect(() => {
    if (!isClientLoading
      && !isCommissionLoading) {
      setIsPageLoading(false);
    }
  }, [

    isClientLoading,
    isCommissionLoading,
  ]);

  useEffect(() => {
    if (!commissionContext.isLoading
    && !clientContext.isLoading
    && !statusContext.isLoading
    && !userContext.isLoading) {
      initialisePage().then(() => {});
    }
  }, [commissionContext.isLoading,
    clientContext.isLoading,
    statusContext.isLoading,
    userContext.isLoading,
  ]);

  if (isPageLoading) {
    return (
      <Page title="Commission Details">
        <Typography variant="h3">Loading...</Typography>
      </Page>
    );
  }

  if (!commission) {
    throw new Error('No commission after loading');
  }

  if (!userContext.user) {
    throw new Error('No user in user context after loading');
  }

  if (!client) {
    throw new Error('No client after loading');
  }

  const onStatusSelected = async (newValue: string) => {
    if (!commission) {
      throw new Error('Trying to edit an undefined commission');
    }

    try {
      setIsModifyingStatus(true);
      const returnedCommission = await commissionContext.modifyCommissionStatus(commission.commissionUuid, newValue);
      setSelectedStatus(statusContext.getCommissionStatusByUuid(returnedCommission.statusUuid));
      setCommission(returnedCommission);
      toast.info('Commission status changed');
    } finally {
      setIsModifyingStatus(false);
    }
  };

  const availableStatuses: SelectPairs[] = statusContext.commissionStatuses.map((status) => ({
    label: status.label, value: status.statusUuid,
  }));

  const commissionPageTitle = commission.archived
    ? `(ARCHIVED) ${commission.label}`
    : commission.label;

  return (
    <Page title="Commission Details" paddingTop={0}>
      <PasteImageDialog onSaveFile={(files) => { setPastedFiles(files); }} />
      <EditCommissionLabelDialog
        isOpen={isEditLabelModalOpen}
        commission={commission}
        onClose={() => { setIsEditLabelModalOpen(false); }}
      />
      <BoxFlexColumn>
        <Box
          id="top-title-bar-image"
          sx={{
            height: BANNER_HEIGHT,
            marginBottom: '20px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box sx={{
            height: BANNER_HEIGHT,
            position: 'absolute',
            width: '100%',
            zIndex: -1000,
            filter: 'blur(6px)',
            maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0));',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.15) 30%, rgba(0,0,0,0))',
            transform: 'scale(1.06)',
          }}
          >
            {bannerImage && (<DeferredImage src={bannerImage ? bannerImage.url : ''} alt="banner image" />)}
          </Box>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column-reverse',
              paddingBottom: (theme) => theme.spacing(3),
            }}
          >
            <DarkTransparentPaper sx={{
              width: 'fit-content',
              padding: (theme) => theme.spacing(1.5, 3),
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: (theme) => theme.spacing(1.5),
            }}
            >
              <Typography variant="h1">{commissionPageTitle}</Typography>
              <IconButton
                onClick={() => { setIsEditLabelModalOpen(true); }}
                color="primary"
                sx={{
                  opacity: 0.4,
                  '&:hover': { opacity: 1 },
                }}
              >
                <EditIcon />
              </IconButton>
            </DarkTransparentPaper>
          </Box>
        </Box>
        <SimplePaper>
          <LabelledSelect
            label="Status"
            selectedValue={selectedStatus?.statusUuid}
            values={availableStatuses}
            onValueChanged={onStatusSelected}
            disabled={isModifyingStatus}
          />
        </SimplePaper>
        <Box>
          <Grid container spacing={3}>
            {/* /Left Side */}
            <Grid item xs={12} sm={5} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionReferencesImages
                    commissionUuid={commission.commissionUuid}
                    previewImageObjectKey={commission.commissionMetadata.commissionThumbnailObjectKey}
                    artistUserUuid={userContext.user.userUuid}
                    maxFileSizeBytes={userContext.user.maxUploadFileSize}
                    parentFiles={pastedFiles}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionDetails commission={commission} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionNotes
                    commission={commission}
                    dateFormatter={userContext.formatDate}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Right Side */}
            <Grid item xs={12} sm={5} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <ClientDetails
                    client={client}
                    originatingRequestUuid={commission.submissionUuid}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionScheduleComponent
                    commission={commission}
                    dateFormatter={userContext.formatDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionTasks
                    commissionUuid={commission.commissionUuid}
                    dateFormatter={userContext.formatDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionStatusDetails
                    commission={commission}
                    statusContext={statusContext}
                    dateFormatter={userContext.formatDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CommissionProperties
                    commission={commission}
                    dateFormatter={userContext.formatDate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </BoxFlexColumn>
    </Page>
  );
}

export default CommissionDetailsPage;
