import axios from 'axios';
import { Commission } from 'src/representations/Commission';
import { CommissionStatusHistory } from 'src/representations/CommissionStatusHistory';
import { ViewableCommissionAttachment } from 'src/representations/CommissionAttachment';
import ApiError, {
  ERROR_ARCHIVE_COMMISSION,
  ERROR_CREATE_COMMISSION,
  ERROR_GET_COMMISSION,
  ERROR_MODIFY_COMMISSION,
  ERROR_MODIFY_COMMISSION_STATUS,
  ERROR_UNARCHIVE_COMMISSION,
} from './ApiError';
import { CommissionWritable } from '../representations/CommissionWritable';
import { CommissionMetadata } from '../representations/CommissionMetadata';
import { SERVER_URL } from './SharedApiStuff';
import { UploadedFile } from '../representations/UploadedFile';
import { CommissionSchedule } from '../representations/CommissionSchedule';

const mapCommissionResponseToCommission = (data: Commission): Commission => {
  const commissionSchedule: CommissionSchedule | undefined = data.commissionSchedule
    ? {
      ...data.commissionSchedule,
      startDate: data.commissionSchedule.startDate ? new Date(data.commissionSchedule.startDate) : null,
      endDate: new Date(data.commissionSchedule.endDate),
    }
    : undefined;

  return {
    ...data,
    commissionSchedule,
  };
};

interface GetCommissionSchedulesForDateRangeResponse {
  commissions: Commission[]
}

export async function getCommissionSchedulesForDateRange(
  startDate: Date,
  endDate: Date,
): Promise<Commission[]> {
  try {
    const url = `${SERVER_URL}/api/v1/commission`;
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    const response = (await axios.get(url, {
      withCredentials: true,
      params,
    })).data as GetCommissionSchedulesForDateRangeResponse;

    return response.commissions;
  } catch (e) {
    throw new ApiError('errorGettingCommissionsWithSchedule', 'ERROR_GET_COMMISSION_WITH_SCHEDULE', e);
  }
}

/**
 * Returns list of commissions
 *
 * @returns {Promise<Array<Commission>>}
 */
export async function getCommissions(): Promise<Commission[]> {
  try {
    const url = `${SERVER_URL}/api/v1/commission/active`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data.map(mapCommissionResponseToCommission);
  } catch (e) {
    throw new ApiError('errorGettingCommission', ERROR_GET_COMMISSION, e);
  }
}

/**
 * Archive commission
 *
 * @param commission
 * @returns {Promise<void>}
 */
export async function archiveCommission(commission: Commission) {
  try {
    const url = `${SERVER_URL}/api/v1/commission/archive/${commission.commissionUuid}`;
    const data = { };
    await axios.put(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error archiving commission', ERROR_ARCHIVE_COMMISSION, e);
  }
}

interface PutArchiveCommissionsRequest {
  commissionUuids: string[]
}

/**
 * Archive commissions
 *
 * @param commissionUuids
 * @returns {Promise<void>}
 */
export async function archiveCommissions(commissionUuids: string[]) {
  try {
    const url = `${SERVER_URL}/api/v1/commission/archive`;
    const data: PutArchiveCommissionsRequest = { commissionUuids };
    await axios.put(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error archiving commissions', ERROR_ARCHIVE_COMMISSION, e);
  }
}

interface PutUnarchiveCommissionsRequest {
  commissionUuids: string[]
}

/**
 * Unarchive commission
 *
 * @param commissionUuids
 * @returns {Promise<void>}
 */
export async function unarchiveCommission(commissionUuids: string[]) {
  try {
    const url = `${SERVER_URL}/api/v1/commission/unarchive`;
    const data: PutUnarchiveCommissionsRequest = { commissionUuids };
    await axios.put(url, data, { withCredentials: true });
  } catch (e) {
    throw new ApiError('Error unarchiving commission', ERROR_UNARCHIVE_COMMISSION, e);
  }
}

/**
 * Returns archived list of commissions
 *
 * @returns {Promise<Array<Commission>>}
 */
export async function getArchivedCommissions():Promise<Commission[]> {
  try {
    const url = `${SERVER_URL}/api/v1/commission/archived`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data.map(mapCommissionResponseToCommission);
  } catch (e) {
    throw new ApiError('errorGettingCommission', ERROR_GET_COMMISSION, e);
  }
}

/**
 * Add commission and return the newly created commission object
 *
 * @param commission
 * @returns {Promise<Commission>}
 */
export async function addCommission(commission: CommissionWritable): Promise<Commission> {
  try {
    const response = await axios.post(`${SERVER_URL}/api/v1/commission`, { commission }, { withCredentials: true });

    return mapCommissionResponseToCommission(response.data);
  } catch (e) {
    throw new ApiError('Error creating commission', ERROR_CREATE_COMMISSION, e);
  }
}

export async function modifyCommission(commissionUuid: string, commission: CommissionWritable): Promise<void> {
  try {
    await axios.put(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}`,
      { commission },
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error modifying commission', ERROR_MODIFY_COMMISSION, e);
  }
}

interface PutCommissionMetadataRequest {
  metadata: CommissionMetadata
}

interface PutCommissionMetadataResponse {
  metadata: CommissionMetadata
}

export async function modifyCommissionMetadata(commissionUuid: string, metadata: CommissionMetadata) {
  try {
    const req: PutCommissionMetadataRequest = { metadata };
    const response: PutCommissionMetadataResponse = (await axios.put(
      `${SERVER_URL}/api/v1/commission/metadata/${commissionUuid}`,
      req,
      { withCredentials: true },
    )).data;

    return response;
  } catch (e) {
    throw new ApiError('Error modifying commission', ERROR_MODIFY_COMMISSION, e);
  }
}

export async function modifyCommissionStatus(commissionUuid: string, newStatusUuid: string): Promise<Commission> {
  try {
    const response = await axios.put(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}/status`,
      { newStatusUuid },
      { withCredentials: true },
    );

    return mapCommissionResponseToCommission(response.data);
  } catch (e) {
    throw new ApiError('Error modifying commission status', ERROR_MODIFY_COMMISSION_STATUS, e);
  }
}

interface GetCommissionByUuidResponse {
  commission: Commission;
}

/**
 * Returns commission found by uuid for a user - If the user doesn't own this they can't see it btw
 *
 * @param existingCommissionUuid
 * @returns {Promise<Commission>}
 */
export async function getCommissionByUuid(existingCommissionUuid: string): Promise<Commission> {
  try {
    const response: GetCommissionByUuidResponse = (await axios.get(
      `${SERVER_URL}/api/v1/commission/${existingCommissionUuid}`,
      { withCredentials: true },
    )).data;

    return mapCommissionResponseToCommission(response.commission);
  } catch (e) {
    throw new ApiError('Error getting commission by UUID', ERROR_GET_COMMISSION, e);
  }
}

export async function getCommissionStatusHistory(commissionUuid: string) {
  let response;
  try {
    response = await axios.get(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}/status-history`,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error getting commission status history by UUID', ERROR_GET_COMMISSION, e);
  }

  return response.data as CommissionStatusHistory[];
}

interface GetCommissionReferenceImagesResponse {
  referenceImages: ViewableCommissionAttachment[]
}

export async function getCommissionReferenceImages(commissionUuid: string) {
  try {
    const response: GetCommissionReferenceImagesResponse = (await axios.get(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}/attachments`,
      { withCredentials: true },
    )).data;

    return response.referenceImages;
  } catch (e) {
    throw new ApiError('Error getting commission status history by UUID', ERROR_GET_COMMISSION, e);
  }
}

export interface GetCommissionPreviewResponse {
  previewImage?: UploadedFile
}

export async function getCommissionPreview(commissionUuid: string): Promise<GetCommissionPreviewResponse> {
  try {
    return (await axios.get(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}/preview`,
      { withCredentials: true },
    )).data;
  } catch (e) {
    throw new ApiError('Error getting commission status history by UUID', ERROR_GET_COMMISSION, e);
  }
}

interface PostAddCommissionReferenceImagesRequest {
  newAttachmentObjectKeys: string[]
}

/**
 * Register object keys as reference images for a commission
 * @param commissionUuid
 * @param keys
 */
export async function addCommissionReferenceImages(commissionUuid: string, keys: string[]) {
  try {
    const data: PostAddCommissionReferenceImagesRequest = { newAttachmentObjectKeys: keys };

    await axios.post(
      `${SERVER_URL}/api/v1/commission/${commissionUuid}/attachments`,
      data,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error adding commission attachments', 'ERROR_ADD_ATTACHMENT', e);
  }
}

export async function deleteCommissionReferenceImages(referenceImage: ViewableCommissionAttachment) {
  try {
    await axios.delete(
      `${SERVER_URL}/api/v1/commission/${referenceImage.commissionUuid}/attachments/${referenceImage.objectKey}`,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error deleting commission attachments', 'ERROR_DELETE_ATTACHMENT', e);
  }
}

interface PutCommissionPreviewRequest {
  objectKey?: string
}

export async function setPreviewImage(referenceImage: ViewableCommissionAttachment): Promise<void> {
  try {
    const data: PutCommissionPreviewRequest = { objectKey: referenceImage.objectKey };
    await axios.put(
      `${SERVER_URL}/api/v1/commission/${referenceImage.commissionUuid}/preview`,
      data,
      { withCredentials: true },
    );
  } catch (e) {
    throw new ApiError('Error updated preview image', 'ERROR_UPDATE_PREVIEW', e);
  }
}

// export async function deleteCommission(commission) {
//   try {
//     const response = await axios.post(`${SERVER_URL}/api/v1/commission`, { commission }, { withCredentials: true });
//     return response.data;
//
//     return await axios.post(`${SERVER_URL}/api/v1/commission/delete`, { commission }).then((res) => res.data);
//   } catch (e) {
//     console.error('Shit something went wrong');
//     throw e;
//   }
// }
