import React, { createContext } from 'react';
import ArtistUserContextObject from './ArtistUserContextObject';
import {
  getPublicArtistPageItems, getPublicArtistPageItemsByUserUuid,
} from '../../../../api/PublicRequestFormApi';
import { ViewableArtistBackgroundImage } from '../../../../representations/ArtistBackgroundImage';
import { UserPublic } from '../../../../representations/User';

type Props = {
  children: React.ReactNode
};

export const ArtistUserContext = createContext<ArtistUserContextObject | undefined>(undefined);

export function ArtistUserProvider({ children }: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false); // yes this is false!
  const [isLoadingComplete, setIsLoadingComplete] = React.useState(false);
  const [artistUserCustomUrlToLoadL, setArtistUserCustomUrlToLoadL] = React.useState<string | undefined>(undefined);
  const [artistUserUuidToLoadL, setArtistUserUuidToLoadL] = React.useState<string | undefined>(undefined);
  const [artistUser, setArtistUser] = React.useState<UserPublic | undefined>(undefined);
  const [backgroundImages, setBackgroundImages] = React.useState<ViewableArtistBackgroundImage[]>([]);

  React.useEffect(() => {
    if (artistUserCustomUrlToLoadL || artistUserUuidToLoadL) {
      const load = async () => {
        try {
          setIsLoading(true);

          let getPublicArtistPageItemsResponse;
          if (artistUserCustomUrlToLoadL) {
            getPublicArtistPageItemsResponse = await getPublicArtistPageItems(artistUserCustomUrlToLoadL);
          } else if (artistUserUuidToLoadL) {
            getPublicArtistPageItemsResponse = await getPublicArtistPageItemsByUserUuid(artistUserUuidToLoadL);
          } else {
            throw new Error('Both artist user uuid and url were undefined');
          }

          setArtistUser(getPublicArtistPageItemsResponse.artistUser);
          setBackgroundImages(getPublicArtistPageItemsResponse.backgroundImages);
          setIsLoadingComplete(true);

          // Set this back to reset and allow second loads
          setArtistUserCustomUrlToLoadL(undefined);
          setArtistUserUuidToLoadL(undefined);
        } finally {
          setIsLoading(false);
        }
      };
      load().then(() => {});
    }
  }, [artistUserCustomUrlToLoadL, artistUserUuidToLoadL]);

  const setArtistUrlToLoad = (artistUrl: string) => {
    setArtistUserCustomUrlToLoadL(artistUrl);
  };

  const setArtistUserUuidToLoad = (artistUserUuid: string) => {
    setArtistUserUuidToLoadL(artistUserUuid);
  };

  // Change only on isLoading status changes - This is when we know that the data changes are all completed
  const memoed = React.useMemo(
    ():ArtistUserContextObject => ({
      isLoading,
      isLoadingComplete,
      setArtistUrlToLoad,
      setArtistUserUuidToLoad,
      artistUser,
      backgroundImages,
    }),
    [isLoading],
  );

  return (
    <ArtistUserContext.Provider value={memoed}>
      {children}
    </ArtistUserContext.Provider>
  );
}

export const useArtistUserContext = () => {
  const context = React.useContext(ArtistUserContext);
  if (context === undefined) {
    throw new Error('useArtistUserContext must be used within a ArtistUserProvider');
  }
  return context;
};
