import {
  Box, Button, IconButton, Paper, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { JSONContent } from '@tiptap/react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Commission } from '../../../representations/Commission';
import { Note } from '../../../representations/commission-metadata/Note';
import { useCommissionContext } from '../context/commission-context/CommissionContext';
import { CommissionWritable } from '../../../representations/CommissionWritable';
import ConfirmationDialog from '../ConfirmationDialog';
import BoxFlexColumn from '../styled-containers/BoxFlexColumn';
import RichTextEditor from '../rich-text-editor/RichTextEditor';
import TitlePaper from '../styled-containers/TitlePaper';

const NotesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

interface Props {
  commission: Commission;
  dateFormatter: (date: Date) => string;
}

export default function CommissionNotes({
  commission,
  dateFormatter,
}:Props) {
  const commissionContext = useCommissionContext();

  const [showAddNoteButton, setShowAddNoteButton] = React.useState<boolean>(true);
  const [showAddNoteEntry, setShowAddNoteEntry] = React.useState<boolean>(false);
  const [currentNote, setCurrentNote] = React.useState<Note | undefined>(undefined);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = React.useState<boolean>(false);
  const [currentlyDeletingNote, setCurrentlyDeletingNote] = React.useState<Note | undefined>(undefined);

  const [isSavingNote, setIsSavingNote] = React.useState<boolean>(false);
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);

  const saveNote = async (content: JSONContent) => {
    setIsSavingNote(true);
    try {
      const cw: CommissionWritable = commission;
      const oldNotesList = cw.commissionMetadata.notes === undefined ? [] : cw.commissionMetadata.notes;

      // Highest index number of the notes list
      let highestIndex = 0;
      oldNotesList.forEach((note) => {
        highestIndex = highestIndex < note.index ? note.index : highestIndex;
      });

      // Does the old notes list contain this note?
      const savingNewNote = oldNotesList.filter((note) => note.index === currentNote?.index).length === 0;

      let newNotesList;
      if (savingNewNote) {
        newNotesList = oldNotesList.map((note) => note);

        const date = new Date();
        const newNote: Note = ({
          notes: content,
          index: (highestIndex + 1),
          dateCreated: date,
          dateUpdated: date,
        });
        newNotesList.push(newNote);
      } else {
        newNotesList = oldNotesList.map((note) => {
          if (note.index === currentNote?.index) {
            return {
              ...currentNote, notes: content, dateUpdated: new Date(),
            };
          }
          return note;
        });
      }

      cw.commissionMetadata.notes = newNotesList;
      await commissionContext.modifyCommission(commission.commissionUuid, cw);
    } finally {
      setIsSavingNote(false);
      setCurrentNote(undefined);
    }
  };

  const closeAddNoteDialog = () => {
    setShowAddNoteButton(true);
    setShowAddNoteEntry(false);
    setCurrentNote(undefined);
  };

  const onDiscardClicked = () => {
    closeAddNoteDialog();
  };

  const onSaveClicked = async (content: JSONContent) => {
    await saveNote(content);
    closeAddNoteDialog();
  };

  const onAddNoteClicked = () => {
    setCurrentNote(undefined);
    setShowAddNoteButton(false);
    setShowAddNoteEntry(true);
  };

  const onEditNoteClicked = (note:Note) => {
    setShowAddNoteButton(false);
    setShowAddNoteEntry(false);
    setCurrentNote(note);
  };

  const confirmNoteDeletion = async (note:Note) => {
    const cw: CommissionWritable = commission;
    cw.commissionMetadata.notes = cw.commissionMetadata.notes?.filter((n) => n.index !== note.index);
    await commissionContext.modifyCommission(commission.commissionUuid, cw);
  };

  const renderEditNote = (note:Note | undefined) => (
    <Box
      key={note ? note.index : -1}
    >
      <RichTextEditor
        autoFocus
        alwaysShowControls
        readOnly={false}
        content={note ? note.notes : undefined}
        isSaving={isSavingNote}
        onSave={onSaveClicked}
        onCancel={onDiscardClicked}
      />
    </Box>
  );

  const renderAddNote = () => renderEditNote(undefined);

  const renderReadNote = (note:Note) => (
    <Box key={note.index}>
      <NotesBox>
        <RichTextEditor
          readOnly
          content={note.notes}
          isSaving={isSavingNote}
        />
        <Stack direction="column">
          <IconButton aria-label="edit" color="primary" onClick={() => { onEditNoteClicked(note); }}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="edit"
            // color="disabled"
            onClick={() => {
              setCurrentlyDeletingNote(note);
              setIsDeleteConfirmationDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </NotesBox>
      <Box
        sx={{
          display: 'flex', flexDirection: 'row-reverse',
        }}
      >
        <Typography variant="subtitle2">
          {`${note.dateCreated === note.dateUpdated
            ? ''
            : `Edited ${dateFormatter(note.dateUpdated)} - `}Created ${dateFormatter(note.dateCreated)}`}
        </Typography>
      </Box>
    </Box>
  );

  const sortNotes = (a:Note, b:Note) => {
    if (a.index < b.index) {
      return 1;
    }
    if (a.index > b.index) {
      return -1;
    }
    return 0;
  };

  return (
    <TitlePaper title="Notes">
      <BoxFlexColumn>
        {commission.commissionMetadata.notes !== undefined
                && commission.commissionMetadata.notes
                  .sort(sortNotes)
                  .map((note) => {
                    if (note.index === currentNote?.index) {
                      return renderEditNote(note);
                    }
                    return renderReadNote(note);
                  })}
        {showAddNoteButton && (
        <Box>
          <Button
            variant="outlined"
            onClick={onAddNoteClicked}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add note
          </Button>
        </Box>
        )}
        {(showAddNoteEntry) && renderAddNote()}
      </BoxFlexColumn>
      <ConfirmationDialog
        id="delete-confirmation-dialog"
        open={isDeleteConfirmationDialogOpen}
        confirmButtonText="Delete Note"
        confirmationMessage="Are you sure you want to delete this note?"
        isSubmittingConfirmation={isConfirming}
        onConfirmedClicked={async () => {
          if (currentlyDeletingNote !== undefined) {
            setIsConfirming(true);
            try {
              await confirmNoteDeletion(currentlyDeletingNote);
              setCurrentlyDeletingNote(undefined);
              setIsDeleteConfirmationDialogOpen(false);
            } finally {
              setIsConfirming(false);
            }
          }
        }}
        onClosedOrCancelClicked={() => {
          setCurrentlyDeletingNote(undefined);
          setIsDeleteConfirmationDialogOpen(false);
        }}
      />
    </TitlePaper>
  );
}
