import React, { useRef } from 'react';

import {
  Box, Button, IconButton, Menu, MenuItem, Popover, Typography,
} from '@mui/material';
import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions,
} from 'mui-datatables';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { toast } from 'react-toastify';
import * as TaskApi from '../../../api/TaskApi';
import {
  Task, TaskStatus,
} from '../../../representations/Task';
import Label from '../../Label';
import BoxFlexRow from '../styled-containers/BoxFlexRow';
import AddOrEditNewTaskComponent from './AddOrEditNewTaskComponent';
import LoadingOverlay from '../LoadingOverlay';
import TitlePaper from '../styled-containers/TitlePaper';

const labels = {
  complete: {
    status: 'Complete',
    variant: 'filled',
    color: 'success',
  },
  inProgress: {
    status: 'In Progress',
    variant: 'filled',
    color: 'warning',
  },
  pending: {
    status: 'Pending',
    variant: 'outlined',
    color: 'info',
  },
};

interface RenderedStatusProps {
  status: TaskStatus
  tableMetadata: MUIDataTableMeta
  onStatusChanged: (string: string, status: TaskStatus) => void
}

function RenderedStatus({
  status,
  tableMetadata,
  onStatusChanged,
}: RenderedStatusProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const uuid: string = tableMetadata.rowData[0];

  let theStatus;
  let variant;
  let color;

  switch (status) {
    case TaskStatus.COMPLETE:
      theStatus = labels.complete.status;
      variant = labels.complete.variant;
      color = labels.complete.color;
      break;
    case TaskStatus.IN_PROGRESS:
      theStatus = labels.inProgress.status;
      variant = labels.inProgress.variant;
      color = labels.inProgress.color;
      break;
    case TaskStatus.PENDING:
      // TODO maybe add OVERDUE if date is too late
      theStatus = labels.pending.status;
      variant = labels.pending.variant;
      color = labels.pending.color;
      break;
    default:
      throw new Error('Unknown status');
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (newStatus: TaskStatus) => {
    onStatusChanged(uuid, newStatus);
    setAnchorEl(null);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const getMenuItem = (taskStatus: TaskStatus, label: { color: string; variant: string; status: string }) => (
    <MenuItem
      key={taskStatus}
      onClick={() => { onMenuItemClick(taskStatus); }}
    >
      <Label
        variant={label.variant}
        color={label.color}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        {label.status}
      </Label>
    </MenuItem>
  );

  return (
    <BoxFlexRow>
      <Label
        variant={variant}
        color={color}
        onClick={onClick}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        {theStatus}
      </Label>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {Object.values(TaskStatus).map((s) => {
          switch (s) {
            case TaskStatus.PENDING:
              return getMenuItem(s, labels.pending);
            case TaskStatus.IN_PROGRESS:
              return getMenuItem(s, labels.inProgress);
            case TaskStatus.COMPLETE:
              return getMenuItem(s, labels.complete);
            default:
              throw new Error('unknown status');
          }
        })}
      </Menu>
    </BoxFlexRow>
  );
}

const getRenderedStatus = (
  value: TaskStatus,
  tableMeta: MUIDataTableMeta,
  onStatusChanged: (string: string, status: TaskStatus) => void,
) => (
  <RenderedStatus
    status={value}
    tableMetadata={tableMeta}
    onStatusChanged={onStatusChanged}
  />
);

interface RenderedLabelProps {
  label: string
  tableMetadata: MUIDataTableMeta
  onEditClicked: (ref: any, taskUuid: string) => void
  onDeleteClicked: (ref: any, taskUuid: string) => void
}

function RenderedLabel({
  label,
  tableMetadata,
  onEditClicked,
  onDeleteClicked,
}: RenderedLabelProps) {
  const labelRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const uuid: string = tableMetadata.rowData[0];

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuEditClicked = () => {
    onEditClicked(labelRef.current, uuid);
    setAnchorEl(null);
  };

  const onMenuDeleteClicked = () => {
    onDeleteClicked(labelRef.current, uuid);
    setAnchorEl(null);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Typography
        onClick={onClick}
        ref={labelRef}
      >
        {label}
      </Typography>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem onClick={onMenuEditClicked}>
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={onMenuDeleteClicked}>
          <Typography>Delete</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

const getRenderedLabel = (
  label:string,
  tableMeta: MUIDataTableMeta,
  onEditClicked: (ref: any, taskUuid: string)=>void,
  onDeleteClicked: (ref: any, taskUuid: string)=>void,
) => (
  <RenderedLabel
    label={label}
    tableMetadata={tableMeta}
    onEditClicked={onEditClicked}
    onDeleteClicked={onDeleteClicked}
  />
);

function CustomToolBar({ onClick }:{ onClick: (event: React.MouseEvent<HTMLButtonElement>)=>void }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      startIcon={<AddCircleOutlineIcon />}
    >
      Add Task
    </Button>
  );
}

const getCustomToolBar = (onClick: (event: React.MouseEvent<HTMLButtonElement>)=>void) => (
  <CustomToolBar onClick={onClick} />
);

interface Props {
  dateFormatter: (date: Date) => string;
  commissionUuid: string
}

export default function CommissionTasks({
  commissionUuid,
  dateFormatter,
}: Props) {
  const [addNewTaskPopoverAnchor, setAddNewTaskPopoverAnchor] = React.useState<null | HTMLElement>(null);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [isLoadingTasks, setIsLoadingTasks] = React.useState(true);
  const [editingTask, setEditingTask] = React.useState<Task | undefined>(undefined);

  const onStatusChanged = async (uuid: string, status: TaskStatus) => {
    const originalTask = tasks.find((t) => t.uuid === uuid);
    if (!originalTask) {
      throw new Error('Undefined task');
    }

    try {
      setTasks((oldTasks) => oldTasks.map((t) => (t.uuid === uuid
        ? {
          ...t,
          status,
        }
        : t)));
      await TaskApi.setTaskStatus(uuid, status);
    } catch (e) {
      toast.error('Error changing task status');
      console.error(e);
      setTasks((oldTasks) => oldTasks.map((t) => (t.uuid === uuid
        ? {
          ...t,
          status: originalTask.status,
        }
        : t)));
    }
  };

  const loadTasks = async (theCommissionUuid: string) => {
    setIsLoadingTasks(true);
    try {
      setTasks(await TaskApi.getCommissionTasks(theCommissionUuid));
    } finally {
      setIsLoadingTasks(false);
    }
  };

  const onNewTaskClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditingTask(undefined);
    setAddNewTaskPopoverAnchor(event.currentTarget);
  };

  const onEditClicked = (ref: any, taskUuid: string) => {
    setEditingTask(tasks.find((t) => t.uuid === taskUuid));
    setAddNewTaskPopoverAnchor(ref);
  };

  const onDeleteClicked = async (ref: any, taskUuid: string) => {
    try {
      await TaskApi.deleteTask(taskUuid);
      toast.info('Deleted task');
      loadTasks(commissionUuid).then(() => {});
    } catch (e) {
      toast.error('Error deleting task');
      console.error('Failed deleting task', e);
    }
  };

  React.useEffect(() => {
    loadTasks(commissionUuid).then(() => {});
  }, [commissionUuid]);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'uuid',
      label: 'uuid',
      options: { display: 'excluded' },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (
          value: TaskStatus,
          tableMeta: MUIDataTableMeta,
        ) => getRenderedStatus(value, tableMeta, onStatusChanged),
      },
    },
    {
      name: 'label',
      label: 'Task',
      options: {
        customBodyRender: (
          value: string,
          tableMeta: MUIDataTableMeta,
        ) => getRenderedLabel(value, tableMeta, onEditClicked, onDeleteClicked),
      },
    },
    {
      name: 'deadlineDate',
      label: 'Deadline Date',
      options: { customBodyRender: (value) => (value ? dateFormatter(value) : '') },
    },
  ];

  const options: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRows: 'none',
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    customFooter: () => null,
    customToolbar: () => getCustomToolBar(onNewTaskClicked),
    elevation: 0,
    // onRowClick,
    textLabels: { body: { noMatch: <Typography>No tasks yet, create one using the add icon above!</Typography> } },
  };

  const mappedTasks = tasks.map((task) => ({
    ...task,
    deadlineDate: task.date,
  }));

  return (
    <LoadingOverlay
      active={isLoadingTasks}
      text="Loading"
    >
      <TitlePaper title="Tasks / Reminders" color="secondary">
        <Popover
          open={Boolean(addNewTaskPopoverAnchor)}
          onClose={() => { setAddNewTaskPopoverAnchor(null); }}
          anchorEl={addNewTaskPopoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{ sx: { padding: 0 } }}
        >
          <AddOrEditNewTaskComponent
            existingTask={editingTask}
            commissionUuid={commissionUuid}
            onTaskCreated={() => {
              toast.info('Created new task');
              loadTasks(commissionUuid).then(() => {});
              setAddNewTaskPopoverAnchor(null);
            }}
            onTaskModified={() => {
              toast.info('Modified task');
              loadTasks(commissionUuid).then(() => {});
              setAddNewTaskPopoverAnchor(null);
            }}
          />
        </Popover>
        <MUIDataTable
          title=""
          columns={columns}
          data={mappedTasks}
          options={options}
        />
        {/*  {scheduledGoals.length > 0 */}
        {/* ? scheduledGoals.map((goal) => (<Box key={goal.uuid}>{goal.label}</Box>)) */}
        {/*: <Box>Nothing planned</Box>} */}
      </TitlePaper>
    </LoadingOverlay>
  );
}
