import {
  Box, Button, IconButton, Popover, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import { Commission } from '../../representations/Commission';
import BoxFlexRow from './styled-containers/BoxFlexRow';
import { UploadedFile } from '../../representations/UploadedFile';
import * as CommissionApi from '../../api/CommissionApi';
import PreviewImageContainer from './PreviewImageContainer';
import BoxFlexColumn from './styled-containers/BoxFlexColumn';
import SchedulerCalendarDialogEditorButton from './calendar/SchedulerCalendarDialogEditorButton';

const PopoverPaper = styled(Box)(() => ({}));

interface Props {
  commission: Commission | null
  anchorEl: Element | null
  onPopoverClosed: ()=>void
}

export default function CommissionPreviewPopover({
  commission,
  anchorEl,
  onPopoverClosed,
}:Props) {
  const shouldBeOpen: boolean = Boolean(commission) && Boolean(anchorEl);
  const [previewImage, setPreviewImage] = React.useState<UploadedFile | undefined>(undefined);

  const getCommissionPreview = async (theCommission: Commission) => {
    const preview = await CommissionApi.getCommissionPreview(theCommission.commissionUuid);
    if (preview.previewImage) {
      setPreviewImage(preview.previewImage);
    }
  };

  React.useEffect(() => {
    if (commission) {
      getCommissionPreview(commission).then(() => {});
    } else {
      // unset the preview image when no commission is loaded
      setPreviewImage(undefined);
    }
  }, [commission]);

  if (!commission) {
    return null;
  }

  return (
    <Popover
      open={shouldBeOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => { onPopoverClosed(); }}
      PaperProps={{
        sx: {
          padding: 2,
          width: 400,
          overflow: 'hidden',
        },
      }}
    >
      <PopoverPaper>
        <BoxFlexRow>
          <PreviewImageContainer image={previewImage} />
          <BoxFlexColumn sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h3">{commission.label}</Typography>
            <BoxFlexRow sx={{
              flexDirection: 'row-reverse', alignItems: 'flex-end',
            }}
            >
              <Stack direction="row" spacing={1}>
                <SchedulerCalendarDialogEditorButton
                  commission={commission}
                  customButton={(
                    <Button
                      variant="contained"
                      color="secondary"
                      endIcon={<EventIcon />}
                      aria-label="reschedule"
                    >
                      Reschedule
                    </Button>
                  )}
                  onDialogClose={() => {
                    setTimeout(() => { onPopoverClosed(); }, 150);
                  }}
                />
                <RouterLink to={`/app/commissions/details/${commission.commissionUuid}`}>
                  <Button variant="contained" endIcon={<ChevronRightIcon />} aria-label="Go">Go</Button>
                </RouterLink>
              </Stack>
            </BoxFlexRow>
          </BoxFlexColumn>
        </BoxFlexRow>
      </PopoverPaper>
    </Popover>
  );
}
