import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  minWidth: 237,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  //  transform: 'scale(50%)',
}));

export default function MyocLogo() {
  return (
    <StyledBox>
      <img
        src="/static/donut.svg"
        alt="Donut mascot logo"
        height="120px"
        width="135px"
        style={{ transform: 'translate(-4%, 0)' }}
      />
      <img
        src="/static/myoc.svg"
        alt="myoc"
        height="73px"
        width="237px"
        style={{ transform: 'translate(0, 0)' }}
      />
    </StyledBox>
  );
}
