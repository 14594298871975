import {
  Box, Grid, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import RequestInboxComponent from 'src/components/myoc-components/requests/RequestInboxComponent';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import Page from '../../components/Page';
import { useUserContext } from '../../components/myoc-components/context/user-context/UserContext';
import * as PublicRequestFormApi from '../../api/PublicRequestFormApi';
import { RequestFormClientSubmissionWithFiles } from '../../representations/RequestFormClientSubmission';
import RequestReviewComponent from '../../components/myoc-components/requests/RequestReviewComponent';
import LoadingOverlay from '../../components/myoc-components/LoadingOverlay';

export default function RequestInboxPage() {
  const userContext = useUserContext();

  const [searchParams] = useSearchParams();
  const sss = searchParams.get('submissionUuid');
  const submissionUuid = sss !== null ? sss : undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [
    submissionObject,
    setSubmissionObject,
  ] = React.useState <RequestFormClientSubmissionWithFiles | undefined | null>(
    submissionUuid === undefined ? null : undefined,
  );
  const [isLoadingSubmission, setIsLoadingSubmission] = React.useState<boolean>(false);

  const loadSubmission = async () => {
    if (submissionUuid !== undefined) {
      if (!userContext.user) {
        throw new Error('idk');
      }

      setIsLoadingSubmission(true);
      const requestFormSubmission = await PublicRequestFormApi.getFormSubmission(
        submissionUuid,
        userContext.user.userUuid,
      );
      setSubmissionObject(requestFormSubmission);
      setIsLoadingSubmission(false);
    } else {
      setIsLoadingSubmission(false);
    }
  };

  useEffect(() => {
    if (!userContext.isFirstLoaded) {
      return;
    }
    loadSubmission().then(() => {});
  }, [submissionUuid, userContext]);

  const onSubmissionSelected = (s: string) => {
    navigate(`${location.pathname}?submissionUuid=${s}`, { replace: true });
  };

  if (!userContext.isFirstLoaded) {
    return (
      <Page title="Request Inbox">
        <Typography variant="h3">Loading...</Typography>
      </Page>
    );
  }

  return (
    <Page
      title="Request Inbox"
      sx={{}}
    >
      <Grid container spacing={3}>
        <Grid item id="left-col" sm={5}>
          <Box>
            <RequestInboxComponent
              currentlySelectedRequestSubmission={submissionObject}
              dateDisplayFormatter={userContext.formatDate}
              onSubmissionSelected={onSubmissionSelected}
              refreshCounter={refreshCounter}
            />
          </Box>
        </Grid>
        <Grid item id="right-col" sm={7}>
          <LoadingOverlay
            active={isLoadingSubmission}
            text="Loading..."
          >
            <RequestReviewComponent
              submissionObject={submissionObject}
              dateFormatter={userContext.formatDate}
              submissionUpdated={async () => {
                await loadSubmission();
                setRefreshCounter(refreshCounter + 1);
              }}
            />
          </LoadingOverlay>
        </Grid>
      </Grid>
    </Page>
  );
}
