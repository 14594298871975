import React from 'react';
import {
  Box, List, ListItem, ListItemButton, ListItemText, Stack, Typography,
} from '@mui/material';
import TopAppBar from './TopAppBar';
import MyocLogoHorizontal from '../../pages/MyocLogoHorizontal';

interface PublicNavItems {
  title: string
  path: string
}

const PUBLIC_LINKS: PublicNavItems[] = [
  {
    title: 'FAQ',
    path: '/faq',
  },
  {
    title: 'About',
    path: '/about',
  },
];

export default function PublicTopBar() {
  return (
    <TopAppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <MyocLogoHorizontal />
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: (theme) => (theme.palette.grey[600]),
          }}
        >
          {PUBLIC_LINKS.map((link) => (
            <ListItem key={link.title} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={(<Typography typography="h3">{link.title}</Typography>)} />
              </ListItemButton>
            </ListItem>
          //    TODO add divider between items
          ))}
        </List>
      </Box>
    </TopAppBar>
  );
}
