import {
  Grid, Paper, Tab, Tabs,
} from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import UndecidedRequestsTableComponent from './UndecidedRequestsTableComponent';
import * as RequestsApi from '../../../api/RequestsApi';
import { RequestFormClientSubmissionNoAnswers } from '../../../representations/RequestFormClientSubmission';
import DecidedRequestsTableComponent from './DecidedRequestsTableComponent';
import LoadingOverlay from '../LoadingOverlay';

const UNDECIDED_TAB = 'Undecided';
const DECIDED_TAB = 'Accepted/Rejected';

interface Props {
  currentlySelectedRequestSubmission?: RequestFormClientSubmissionNoAnswers | null,
  dateDisplayFormatter: (date: Date) => string;
  onSubmissionSelected: (submissionUuid: string) => void;
  refreshCounter: number;
}

export default function RequestInboxComponent({
  currentlySelectedRequestSubmission,
  dateDisplayFormatter,
  onSubmissionSelected,
  refreshCounter,
}: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [loadedTab, setLoadedTab] = React.useState(UNDECIDED_TAB);
  const [selectedTab, setSelectedTab] = React.useState(UNDECIDED_TAB);
  const [
    undecidedRequestSubmissions,
    setUndecidedRequestSubmissions,
  ] = React.useState<RequestFormClientSubmissionNoAnswers[]>([]);
  const [
    decidedRequestSubmissions,
    setDecidedRequestSubmissions,
  ] = React.useState<RequestFormClientSubmissionNoAnswers[]>([]);

  const loadUndecidedRequests = async () => {
    try {
      setIsLoading(true);
      setUndecidedRequestSubmissions(await RequestsApi.getUndecidedRequests());
    } catch (e) {
      toast.error('Error loading undecided requests');
    } finally {
      setLoadedTab(UNDECIDED_TAB);
      setIsLoading(false);
    }
  };

  const loadDecidedRequests = async () => {
    try {
      setIsLoading(true);
      setDecidedRequestSubmissions(await RequestsApi.getDecidedRequests());
    } catch (e) {
      toast.error('Error loading decided requests');
    } finally {
      setLoadedTab(DECIDED_TAB);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadRequests = async () => {
      if (selectedTab === UNDECIDED_TAB) {
        await loadUndecidedRequests();
      } else {
        await loadDecidedRequests();
      }
    };

    loadRequests().then(() => {});
  }, [selectedTab, refreshCounter]);

  useEffect(() => {
    if (currentlySelectedRequestSubmission === undefined) {
      // Loading submission or not...
      return;
    }

    let tab = UNDECIDED_TAB;
    if (currentlySelectedRequestSubmission !== null) {
      const isUndecided = currentlySelectedRequestSubmission.decision === null;
      tab = isUndecided ? UNDECIDED_TAB : DECIDED_TAB;
    }

    setSelectedTab(tab);
    setLoadedTab(tab);
    setIsLoading(false);
  }, [currentlySelectedRequestSubmission]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  // Can only be undefined if still loading, otherwise will be null or something
  if (currentlySelectedRequestSubmission === undefined) {
    return (
      <Paper>
        Loading
      </Paper>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          aria-label="tabs undecided, accepted or rejected"
        >
          <Tab label={UNDECIDED_TAB} value={UNDECIDED_TAB} />
          <Tab label={DECIDED_TAB} value={DECIDED_TAB} />
        </Tabs>
        <LoadingOverlay
          active={isLoading}
          text="Loading"
        >
          { loadedTab === UNDECIDED_TAB
            ? (
              <UndecidedRequestsTableComponent
                requestSubmissions={undecidedRequestSubmissions}
                dateDisplayFormatter={dateDisplayFormatter}
                onSubmissionSelected={(submissionUuid:string) => { onSubmissionSelected(submissionUuid); }}
              />
            )
            : (
              <DecidedRequestsTableComponent
                requestSubmissions={decidedRequestSubmissions}
                dateDisplayFormatter={dateDisplayFormatter}
                onSubmissionSelected={(submissionUuid:string) => { onSubmissionSelected(submissionUuid); }}
              />
            )}
        </LoadingOverlay>
      </Grid>
    </Grid>
  );
}
