import axios, { AxiosResponse } from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sha1 from 'sha1-file-web';
import { MyocFileObject } from 'src/representations/MyocFileObject';
import ApiError, {
  ERROR_GETTING_PRESIGNED_URL_FOR_S3, ERROR_UPLOADING_TO_S3,
} from './ApiError';
import {
  AuthorizedFileForUpload,
  BaseFileInformation,
  FileUploadAuthorization,
} from '../representations/FileRepresentation';
import { SERVER_URL } from './SharedApiStuff';

export type PostUploadRequestAuthorizationResponse = {
  fileUploadAuthorizations: FileUploadAuthorization[];
};

export type PostUploadRequestAuthorizationRequest = {
  artistUserUuid: string;
  files: BaseFileInformation[];
  type: 'request' | 'commission';
};

export async function getUploadAuthorizations(artistUserUuid: string, files: File[], type: 'request' | 'commission') {
  try {
    const url = `${SERVER_URL}/api/v1/public/file/upload-request-authorization`;
    const data: PostUploadRequestAuthorizationRequest = {
      artistUserUuid,
      files: files.map((f) => {
        const bfi : BaseFileInformation = {
          fileName: f.name,
          fileType: f.type,
        };
        return bfi;
      }),
      type,
    };

    const response: PostUploadRequestAuthorizationResponse = (await axios.post(url, data, { })).data;

    return response.fileUploadAuthorizations.map((f) => {
      const theFile = files.find(((file) => file.name === f.fileName));
      if (theFile === undefined) {
        throw new Error("File couldn't be re-referenced for some reason");
      }

      const uploadableFile: AuthorizedFileForUpload = {
        ...f,
        file: theFile,
      };

      return uploadableFile;
    });
  } catch (e) {
    throw new ApiError('Error getting presigned url for s3 post', ERROR_GETTING_PRESIGNED_URL_FOR_S3, e);
  }
}

export interface PostRegisterUploadedFilesRequest {
  files: MyocFileObject[]
  fileIds: string[]
  artistUserUuid: string
}

export async function uploadFiles(
  uploadableFiles: AuthorizedFileForUpload[],
  artistUserUuid: string,
  onProgressUpdated: (filename: string, current:number, total:number)=> void = () => {},
) {
  try {
    const promises: Promise<AxiosResponse>[] = [];

    const filesWithHeadersPromises = uploadableFiles.map(async (uf) => ({
      ...uf,
      headers: {
        Authorization: uf.authorisationToken,
        'Content-Type': uf.fileType,
        'X-Bz-File-Name': uf.prefix + uf.key,
        'X-Bz-Content-Sha1': await sha1(uf.file) as string,
      },
    }));

    const filesWithHeaders = await Promise.all(filesWithHeadersPromises);

    filesWithHeaders.forEach((uf) => {
      promises.push(axios.post(uf.uploadUrl, uf.file, {
        headers: uf.headers,
        onUploadProgress: (data) => {
          onProgressUpdated(uf.fileName, data.loaded, data.total);
        },
      }));
    });

    const response = await Promise.all(promises);
    const fileIds: string[] = response.map((r) => r.data.fileId);

    // Now tell our server what files were uploaded, where they were uploaded, and the information for each.
    const uploadedFiles:MyocFileObject[] = uploadableFiles.map((f) => ({
      objectKey: f.key,
      prefix: f.prefix,
      originalFileName: f.fileName,
      fileType: f.fileType,
      dateCreated: new Date(), // This will be overridden in server lol
    }));

    const requestData : PostRegisterUploadedFilesRequest = {
      files: uploadedFiles,
      fileIds,
      artistUserUuid,
    };
    await axios.post(`${SERVER_URL}/api/v1/public/file`, requestData, {});

    return uploadedFiles;
  } catch (e) {
    throw new ApiError('Error uploading file to s3', ERROR_UPLOADING_TO_S3, e);
  }
}
