declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadius:string,
      borderRadiusSm:string,
      borderRadiusMd:string,
    },
  }
}

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
};

export default shape;
