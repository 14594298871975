import axios, { AxiosError } from 'axios';

const getExceptionMessage = (e: AxiosError | Error) => {
  if (axios.isAxiosError(e)) {
    if (e.response === undefined) {
      throw new Error('undefined error response in AxiosError');
    }
    const { error } = e.response.data as any;
    return error;
  }
  return e.message;
};

export default class ApiError extends Error {
  code: string;

  originalException?: AxiosError | Error | unknown;

  constructor(message: string, code: string, exception?: AxiosError | Error | unknown) {
    super(`${code}: ${message}${
      (exception !== undefined && exception instanceof Error)
        ? ` : ${getExceptionMessage(exception)}`
        : ''
    }`);
    this.code = code;
    this.originalException = exception;
  }
}

const ERROR_UNAUTHORISED = 'ERROR_UNAUTHORISED';
const ERROR_NETWORK_ERROR = 'ERROR_NETWORK_ERROR';
const ERROR_GET_ACTIVE_CLIENTS = 'ERROR_GET_ACTIVE_CLIENTS';
const ERROR_ARCHIVE_CLIENT = 'ERROR_ARCHIVE_CLIENT';
const ERROR_UNARCHIVE_CLIENT = 'ERROR_UNARCHIVE_CLIENT';
const ERROR_GET_ARCHIVED_CLIENTS = 'ERROR_GET_ARCHIVED_CLIENTS';
const ERROR_GET_COMMISSION = 'ERROR_GET_COMMISSION';
const ERROR_ARCHIVE_COMMISSION = 'ERROR_ARCHIVE_COMMISSION';
const ERROR_UNARCHIVE_COMMISSION = 'ERROR_UNARCHIVE_COMMISSION';
const ERROR_CREATE_COMMISSION = 'ERROR_CREATE_COMMISSION';
const ERROR_MODIFY_COMMISSION = 'ERROR_MODIFY_COMMISSION';
const ERROR_MODIFY_COMMISSION_STATUS = 'ERROR_MODIFY_COMMISSION_STATUS';
const ERROR_MODIFY_CLIENT = 'ERROR_MODIFY_CLIENT';
const ERROR_GET_ALL_USER_CLIENTS = 'ERROR_GET_ALL_USER_CLIENTS';
const ERROR_CREATE_CLIENT = 'ERROR_CREATE_CLIENT';
const ERROR_GET_USER = 'ERROR_GET_USER';
const ERROR_GET_STATUSES = 'ERROR_GET_STATUSES';
const ERROR_CREATE_FORM = 'ERROR_CREATE_FORM';
const ERROR_GET_FORM = 'ERROR_GET_FORM';
const ERROR_GET_UNDECIDED_REQUESTS = 'ERROR_GET_UNDECIDED_REQUESTS';
const ERROR_GET_DECIDED_REQUESTS = 'ERROR_GET_DECIDED_REQUESTS';
const ERROR_ACCEPT_REQUEST = 'ERROR_ACCEPT_REQUEST';
const ERROR_REJECT_REQUEST = 'ERROR_REJECT_REQUEST';
const ERROR_GET_REQUEST_FORM_SUBMISSION = 'ERROR_GET_REQUEST_FORM_SUBMISSION';
const ERROR_GETTING_PRESIGNED_URL_FOR_S3 = 'ERROR_GETTING_PRESIGNED_URL_FOR_S3';
const ERROR_UPLOADING_TO_S3 = 'ERROR_UPLOADING_TO_S3';
const ERROR_EDIT_REQUEST_URL = 'ERROR_EDIT_REQUEST_URL';

export {
  ERROR_UNAUTHORISED,
  ERROR_NETWORK_ERROR,
  ERROR_GET_ACTIVE_CLIENTS,
  ERROR_ARCHIVE_CLIENT,
  ERROR_UNARCHIVE_CLIENT,
  ERROR_GET_ARCHIVED_CLIENTS,
  ERROR_GET_COMMISSION,
  ERROR_ARCHIVE_COMMISSION,
  ERROR_UNARCHIVE_COMMISSION,
  ERROR_CREATE_COMMISSION,
  ERROR_MODIFY_COMMISSION,
  ERROR_MODIFY_COMMISSION_STATUS,
  ERROR_MODIFY_CLIENT,
  ERROR_GET_ALL_USER_CLIENTS,
  ERROR_CREATE_CLIENT,
  ERROR_GET_USER,
  ERROR_GET_STATUSES,
  ERROR_CREATE_FORM,
  ERROR_GET_FORM,
  ERROR_GET_UNDECIDED_REQUESTS,
  ERROR_GET_DECIDED_REQUESTS,
  ERROR_GET_REQUEST_FORM_SUBMISSION,
  ERROR_GETTING_PRESIGNED_URL_FOR_S3,
  ERROR_UPLOADING_TO_S3,
  ERROR_REJECT_REQUEST,
  ERROR_ACCEPT_REQUEST,
  ERROR_EDIT_REQUEST_URL,
};
