import axios from 'axios';
import { SERVER_URL } from './SharedApiStuff';
import ApiError from './ApiError';
import {
  CommissionSchedule, ScheduleWritable,
} from '../representations/CommissionSchedule';

const transformResponseToJsObject = (schedule: CommissionSchedule) => ({
  ...schedule,
  startDate: schedule.startDate ? new Date(schedule.startDate) : null,
  endDate: new Date(schedule.endDate),
});

interface GetCommissionScheduleResponse {
  schedule: CommissionSchedule | undefined
}

export async function getCommissionSchedule(commissionUuid: string): Promise<CommissionSchedule | undefined> {
  try {
    const response: GetCommissionScheduleResponse = (await axios.get(
      `${SERVER_URL}/api/v1/commission/schedule/${commissionUuid}`,
      { withCredentials: true },
    )).data;

    const { schedule } = response;
    if (!schedule) {
      return undefined;
    }

    return transformResponseToJsObject(schedule);
  } catch (e) {
    throw new ApiError('Error getting commission schedule', 'ERROR_GET_COMMISSION_SCHEDULE', e);
  }
}

interface PutCommissionScheduleRequest {
  commissionSchedule: ScheduleWritable
}

interface PutCommissionScheduleResponse {
  commissionSchedule: CommissionSchedule
}

export async function putCommissionSchedule(
  commissionUuid: string,
  commissionSchedule: ScheduleWritable,
): Promise<CommissionSchedule> {
  try {
    const data: PutCommissionScheduleRequest = { commissionSchedule };

    const response: PutCommissionScheduleResponse = (await axios.put(
      `${SERVER_URL}/api/v1/commission/schedule/${commissionUuid}`,
      data,
      { withCredentials: true },
    )).data;

    return transformResponseToJsObject(response.commissionSchedule);
  } catch (e) {
    throw new ApiError('Error modifying commission schedule', 'ERROR_MODIFY_COMMISSION_SCHEDULE', e);
  }
}
